import React, { useState } from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Cotacoes from "./pages/Cotacoes/header";
import TabelaPersonalizada from "./pages/TabelasPersonalizadas/header";
import Home from "./pages/Corpo"
import { Login } from "./components/HomeThree/login";
import Material from "./pages/Material/header";
import Plano from "./pages/Plano/header";
import TabelaPrecos from "./pages/TabelaPrecos/header";
import FerramentaVendas from "./pages/FerramentaVendas/header";
import Ramos from "./pages/Ramos/header";
import VendaOnline from "./pages/VendaOnline/header";
//Rota com problema
import Blog from "./pages/Blog/header";
import Operadoras from "./pages/Operadoras/header";
import PageCotacoes from "./pages/PageCotacoes";
import PageTemplate from "./pages/PageTemplate/header";
import FourZeroFour from "./components/FourZeroFour";
import CotacoesPrecos from "./pages/CotacoesPrecos/header";

export default function Routers() {
  const getAuthSite = localStorage.getItem("authSite");
  const objSite = getAuthSite == "undefined" || getAuthSite == null || getAuthSite == "[object Object]" ? "" : JSON.parse(getAuthSite ? getAuthSite : "");
  const getAuthUser = localStorage.getItem("authUser");

  const [obj, setObjs] = useState()
  useEffect(() => {
    const objs = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
    setObjs(objs)
  }, []);
  return (
    <Routes>

      <Route exact path="/" element={<Home />} />
      <Route exact path="/login/:tokenUrl" element={<Home />} />



      <Route exact path="/plano/:plano" element={<Plano />} />

      {/* <Route exact path="/:estado/:plano/:pessoa/:operadora"  element={<Fornecedor /> } /> */}
      <Route exact path="/ramos" element={<Ramos />} />
      <Route exact path="/ramos/:id" element={<Ramos />} />

      <Route exact path="/operadora/:ramo" element={<Operadoras />} />
      <Route exact path="/operadoras" element={<Operadoras />} />
      <Route exact path="/operadoras/:id" element={<PageTemplate />} />


      <Route exact path="/comunicados" element={<Blog />} />
      <Route exact path="/404" element={<FourZeroFour />} />
      {/* Rotas Privadas */}
      <Route exact path="/tabelaprecos/:id" element={obj?.id ? <TabelaPrecos /> : <FourZeroFour />} />
      <Route exact path="/tabelacotacoes/:id/:idOperadora" element={obj?.id ? <PageCotacoes /> : <FourZeroFour />} />
      <Route exact path="/tabelacotacoes/:id/" element={obj?.id ? <PageCotacoes /> : <FourZeroFour />} />
      <Route exact path="/ferramentas-de-venda" element={obj?.id ? <FerramentaVendas /> : <FourZeroFour />} />
      <Route exact path="/cotacoes" element={obj?.id ? <Cotacoes /> : <FourZeroFour />} />
      <Route exact path="/cotacoesprecos/:id" element={obj?.id ? <CotacoesPrecos /> : <FourZeroFour />} />
      <Route exact path="/cotacoes/:plano" element={obj?.id ? <Cotacoes /> : <FourZeroFour />} />
      <Route exact path="/cotacoes/minhascotacoes" element={obj?.id ? <Cotacoes /> : <FourZeroFour />} />
      <Route exact path="/tabela" element={obj?.id ? <TabelaPersonalizada /> : <FourZeroFour />} />
      <Route exact path="/vendaOline/:tipo" element={obj?.id ? <VendaOnline /> : <FourZeroFour />} />
      <Route exact path="/vendaOnline" element={obj?.id ? <VendaOnline /> : <FourZeroFour />} />
      <Route exact path="/materiais" element={obj?.id ? <Material /> : <FourZeroFour />} />
      <Route exact path="/materiais/:id" element={obj?.id ? <Material /> : <FourZeroFour />} />






      {/* <Route exact path="/home-two" element={<HomeTwo />} />
       <Route exact path="/home-three" element={<HomeThree />} />
       <Route exact path="/home-four" element={<HomeFour />} />
       <Route exact path="/home-five" element={<HomeFive />} />
       <Route exact path="/home-six" element={<HomeSix />} />
       <Route exact path="/home-seven" element={<HomeSeven />} />
       <Route exact path="/home-eight" element={<HomeEight />} />
       <Route exact path="/home-nine" element={<HomeNine />} />
       <Route exact path="/about-one" element={<AboutOne />} />
       <Route exact path="/about-two" element={<AboutTwo />} />
       <Route exact path="/about-Three" element={<AboutThree />} />
       <Route exact path="/about-four" element={<AboutFour />} />
       <Route exact path="/about-five" element={<AboutFive />} />
       <Route exact path="/about-six" element={<AboutSix />} />
       <Route exact path="/about-seven" element={<AboutSeven />} />
       <Route exact path="/service-one" element={<ServiceOne />} />
       <Route exact path="/service-two" element={<ServiceTwo />} />
       <Route exact path="/service-three" element={<ServiceThree />} />
       <Route exact path="/service-four" element={<ServiceFour />} />
       <Route exact path="/service-five" element={<ServiceFive />} />
       <Route exact path="/service-six" element={<ServiceSix />} />
       <Route exact path="/service-seven" element={<ServiceSeven />} />
       <Route exact path="/service-details" element={<ServiceDetails />} />
       <Route exact path="/portfolio-one" element={<PortfolioOne />} />
       <Route exact path="/portfolio-two" element={<PortfolioTwo />} />
       <Route exact path="/portfolio-three" element={<PortfolioThree />} />
       <Route exact path="/portfolio-four" element={<PortfolioFour />} />
       <Route exact path="/portfolio-details" element={<ProtfolioDetails />} />
       <Route exact path="/team-one" element={<TeamOne />} />
       <Route exact path="/team-two" element={<TeamTwo />} />
       <Route exact path="/team-three" element={<TeamThree />} />
       <Route exact path="/team-four" element={<TeamFour />} />
       <Route exact path="/team-single" element={<TeamSingle />} />
       <Route exact path="/testimonial-one" element={<TestimonialOne />} />
       <Route exact path="/testimonial-two" element={<TestimonialTwo />} />
       <Route exact path="/testimonial-three" element={<TestimonialThree />} />
       <Route exact path="/testimonial-four" element={<TestimonialFour />} />
       <Route exact path="/pricing" element={<PricingOne />} />
       <Route exact path="/pricing-two" element={<PricingTwo />} />
       <Route exact path="/pricing-three" element={<PricingThree />} />
       <Route exact path="/pricing-four" element={<PricingFour />} />
       <Route exact path="/pricing-five" element={<PricingFive />} />
       <Route exact path="/pricing-six" element={<PricingSix />} />
       <Route exact path="/shop" element={<Shop />} />
       <Route exact path="/shop-details" element={<ShopDetails />} />
       <Route exact path="/blog" element={<Blog />} />
       <Route exact path="/blog/blog-details" element={<BlogDetails />} />
       <Route exact path="/404" element={<FourZeroFour />} />
       <Route exact path="/contact" element={<Contact />} />
       <Route exact path="/counters" element={<CounterPage />} /> */}
    </Routes>
  );
}
