import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../api";
import SliderCom from "../../../components/helpers/SliderCom";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

export default function GallerySection() {
  const [list, setList] = useState();
  const handleDados = async () => {
    let list = await Api.areas();


    if (list?.list?.length > 0) {

      setList(list.list);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 50000,
    arrows: false,
    speed: 1000,
    slidesToShow: list?.length < 3 ? list.length : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  const prevHandler = () => sliderRef.current.slickPrev();
  const nextHandler = () => sliderRef.current.slickNext();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [list]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredVerMais, setIsHoveredVerMais] = useState(false);
  const handleMouse = () => {
    setIsHoveredVerMais(!isHoveredVerMais);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
    
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const textStyle = {
    textDecoration: isHovered ? 'underline' : 'none',
    cursor: 'pointer'
  };

  const textStyleVerMais = {
    textDecoration: isHoveredVerMais ? 'underline' : 'none',
    cursor: 'pointer'
  };
  return (
    <section className="pb-70" >

      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: "center", marginTop: 25 }}  >


        <div className="" style={{ width: '90%' }}>
          <div >
            <a href={"/ramos"} >
              <span  onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave} style={{ ...textStyle,color: '#000', textAlign: 'center', fontSize: 22, padding: 8, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>Ramos de  <span style={{ color: '#2278F3' }} >Atuação</span></span>
            </a>
          </div>


          <div style={{ display: 'flex' }}>
            <div style={{ width: '10%' }}>
              <MdArrowBackIos onClick={prevHandler} color="#ccc" size={20} style={{ marginTop: 120 }} />
            </div>
            <div style={{ width: '90%' }}>
              <SliderCom selector={sliderRef} settings={settings} >
                {list &&
                  list.map((item, index) => (
                    <div key={item?.id} >
                      <a href={item.pai ? `/ramos/${item.id}` : `/operadora/${item.id}`} style={{ margin: '25px 15px 10px', boxShadow: ' 1px 4px 4px 1px #00000040', borderRadius: 10, display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: 230, border: '0.01px solid #ccc' }} >


                        <div style={{ height: 160 }}>
                          <img
                            src={`https://server.corpar.com.br/imagens/${item?.imagem ? item?.imagem : item?.icone}`}
                            alt="Gallery"
                            style={{ height: '100%' }}
                          />
                        </div>

                        <p style={{ textAlign: 'center', marginTop: 5, padding: 5, fontSize: 16, color: '#000', width: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          {item?.nome}
                        </p>
                      </a>

                    </div>

                  ))}

              </SliderCom>
            </div>
            <div style={{ width: '10%' }}>
              <MdArrowForwardIos onClick={nextHandler} color="#ccc" size={20} style={{ marginTop: 120 }} />
            </div>
          </div>
          <div style={{marginTop:20}}>
            <a href={"/ramos"} >
              <span onMouseEnter={handleMouse}
          onMouseLeave={handleMouse} style={{ ...textStyleVerMais,color: '#2278F3', textAlign: 'center', fontSize: 16, padding: 8, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>Ver mais</span>
            </a>
          </div>


        </div>




      </div>
    </section>
  );
}
