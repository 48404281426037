import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
export const GestorModal = ({  dados }) => {

    const [modal,setModal] = useState(false)
    useEffect(()=>{
        setModal(dados?.id?true:false)
    },[dados])

    return (
        <Modal
            id="modal-center3"
            size="md"
            isOpen={modal}
            centered={true}
            style={{ maxWidth: 600 }}
        >

            <div className="modal-body">
                <Row >
                    <div className="col-12" >
                        <button type="button"
                            onClick={() => {
                                setModal(false);
                            }} className="close" data-dismiss="modal" aria-label="Close" style={{ float: 'right', marginRight: 10 }} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </Row>

                <Row style={{ display: 'flex', flexDirection: 'column', marginTop: 25 }}>
                    {dados?.video ?
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <video width={'92%'} height={'400px'} controls>
                                    <source src={dados?.video} type="video/mp4" />
                                </video>
                            </div>
                        </>
                        :
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                <img src={dados?.imagem} width={'100%'} />
                            </div>
                        </>
                    }
                    {dados?.descricao ?
                        <p style={{ textAlign: 'justify', padding: '10px 25px 10px 25px', marginTop: 20 }}>
                            {dados?.descricao}
                        </p>
                    : ""}

                </Row>




            </div>

        </Modal>
    )
}