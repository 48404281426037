import React, { useEffect, useState } from "react";
import {DropdownItem,DropdownMenu,DropdownToggle,Modal,UncontrolledDropdown} from "reactstrap";
import { Api } from "../../../api/apiCotacoes";
import Agendar from "./agendar";
import { ImBin } from "react-icons/im";
export const Agendamentos = ({modalAgendamento,setModalAgendamento,setid,id,handleLimpar,setModalAgendar,modalAgendar}) => {
  const [list, setList] = useState([]);
  const handleList = async () => {
   
    let list = await Api.agendamentos({ idcotacao: id });
    setList(list.list);
  };
  const handleExcluir = async (idAgendamento) => {
    let list = await Api.excluirAgendamentos({
      idcotacao: id,
      id: idAgendamento
    });
    setList(list.list.list);
  };
  useEffect(() => {
 
    handleList();
  }, [modalAgendamento,modalAgendar]);

  return (
    <Modal id="modal-center3"  size="md"  isOpen={modalAgendamento} centered={true} >
      <div className="modal-header">
        <p className="modal-title mt-0">Agendamentos</p>
        <button type="button"  className="close"  data-dismiss="modal" aria-label="Close" 
          onClick={() => { 
            setModalAgendamento(false);
            handleLimpar(true)}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 ,marginTop:25}}>
        <div>
          <button className="btn btn-primary" onClick={()=>    setModalAgendar(true)}  style={{float:"right",marginBottom:20}}>+</button>
        </div>
        <div className="table-responsive" style={{ marginTop: 20, minHeight: 250 }} >
          <table className="table">
            <thead>
              <tr>
                <th>Observação</th>
                <th>Data</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item, index) => (
                  <tr key={index}>
                    <td>{item.obs} </td>
                    <td>{item.data} </td>

                    <td>
                    <ImBin onClick={() => { handleExcluir(item.id);  }}  style={{ color: "red",cursor:'pointer' }}/>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Agendar handleLimpar={handleLimpar} modalAgendar={modalAgendar} setModalAgendar={setModalAgendar} id={id} />
    </Modal>
  );
};
