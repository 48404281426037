import React from "react";
import { Modal } from "reactstrap";
export const Excluir = ({handleExcluir,modalExcluir,setModalExcluir}) =>{
    return(
        <Modal
        isOpen={modalExcluir}
        centered={true}
      >
        <div className="modal-header" style={{border:'none'}}>
          <h5 className="modal-title mt-0">Excluir</h5>
          <button
            type="button"
            onClick={() => {
              setModalExcluir(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',paddingTop:20}}>
          <p style={{fontWeight:'bold',fontSize:15,display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}} >
           Tem certeze que deseja excluir essa informação!
          </p>
       
        </div>
        <div className="modal-footer" style={{border:'none'}}>
            <button className="btn btn-danger" onClick={handleExcluir} >Excluir</button>
        </div>
      </Modal>
    )
}