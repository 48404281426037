import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Col, Modal, Row } from "reactstrap";
import logo from "../../../assets/images/logos/logo3.png";
import { RiDownloadCloudLine as Baixar } from "react-icons/ri";
const getAuthUser = localStorage.getItem("authUser");
const obj =
  getAuthUser == "undefined" ||
    getAuthUser == null ||
    getAuthUser == "[object Object]"
    ? ""
    : JSON.parse(getAuthUser ? getAuthUser : "");

export const VisualizarSelecionado = ({ faixas, info, modalVisualizarDetalhe, setModalVisualizarDetalhe, infoDependente, calculoDetalhe, selecteds, handleClick, gerarTabelaSelecionada, handleCarregamento }) => {
  const divRef = useRef(null);
  const [display, setDisplay] = useState('block')

  const dataAtual = () => {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const data = new Date();
    const mes1 = meses[data.getMonth()];
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // o valor do mês começa em 0, então é necessário adicionar 1
    const ano = data.getFullYear();
    return { data1: `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano}`, data2: `${mes1}/${ano}` }
  }
  useEffect(() => {
    gerarTabelaSelecionada(selecteds, false)
  }, [selecteds])
  const handleOrdenarMenor = () => {
    let selecionado = document.querySelector('button[id="1"]')
    let deselecionado = document.querySelector('button[id="2"]')
    if (selecionado && deselecionado) {
      selecionado.className = 'btn btn-primary'
      selecionado.style.backgroundColor = '#1b2949'
      deselecionado.className = 'btn btn-default'
      deselecionado.style.backgroundColor = '#fff'
    }
    if (document.querySelector('tbody[id="tabela"]')) {
      const tabela = document.querySelector('tbody[id="tabela"]');
      const linhas = tabela.querySelectorAll('.tr');
      const linhasOrdenadas = {};
      for (let i = 0; i < linhas.length; i++) {
        if (document.querySelectorAll('.valorTotal')[i]) {
          let dados = document.querySelectorAll('.valorTotal')[i].textContent
          dados = dados.replace('R$', '')
          dados = dados.replace('&nbsp;', '')
          dados = dados.replace('.', '')
          dados = dados.replace(',', '.')
          const valor = parseInt(dados);
          linhasOrdenadas[i] = valor;
        }
      }

      const linhasOrdenadasArray = Object.entries(linhasOrdenadas).sort((a, b) => a[1] - b[1]);

      for (let i = 0; i < linhasOrdenadasArray.length; i++) {
        const indice = linhasOrdenadasArray[i][0];
        tabela.appendChild(linhas[indice]);
      }
    }
    handleCarregamento(false)
  }
  const handleOrdenarMaior = () => {
    let selecionado = document.querySelector('button[id="2"]')
    let deselecionado = document.querySelector('button[id="1"]')
    if (selecionado && deselecionado) {
      selecionado.className = 'btn btn-primary'
      selecionado.style.backgroundColor = '#1b2949'
      deselecionado.className = 'btn btn-default'
      deselecionado.style.backgroundColor = '#fff'
    }
    if (document.querySelector('tbody[id="tabela"]')) {
      const tabela = document.querySelector('tbody[id="tabela"]');
      const linhas = tabela.querySelectorAll('.tr');
      const linhasOrdenadas = {};
      for (let i = 0; i < linhas.length; i++) {
        if (document.querySelectorAll('.valorTotal')[i]) {
          let dados = document.querySelectorAll('.valorTotal')[i].textContent
          dados = dados.replace('R$', '')
          dados = dados.replace('&nbsp;', '')
          dados = dados.replace('.', '')
          dados = dados.replace(',', '.')
          const valor = parseInt(dados);
          linhasOrdenadas[i] = valor;
        }
      }

      const linhasOrdenadasArray = Object.entries(linhasOrdenadas).sort((a, b) => b[1] - a[1]);

      for (let i = 0; i < linhasOrdenadasArray.length; i++) {
        const indice = linhasOrdenadasArray[i][0];
        tabela.appendChild(linhas[indice]);
      }
    }
    handleCarregamento(false)
  }

  return (
    <Modal
      id="modal-center3"
      size="xl"
      isOpen={modalVisualizarDetalhe}
      centered={true}
      style={{ maxWidth: 1200 }}
    >
      <div className="">
        <button type="button"
          onClick={() => {
            setModalVisualizarDetalhe(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          style={{marginRight:10,marginTop:10}}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <Row>
          <Col col={12} >
            <div style={{ float: 'right', marginTop: 30 }}>
              <button id='1' className="btn btn-default" onClick={() => {
                handleCarregamento(true)
                handleOrdenarMenor()
              }} style={{ marginRight: 10 }} >Menor Preço</button>
              <button id='2' className="btn btn-default" onClick={() => {
                handleCarregamento(true)
                handleOrdenarMaior()
              }} style={{ marginRight: 10 }} >Maior Preço</button>
              <ReactToPrint
                trigger={() => <button className="btn btn-primary" onMouseOut={() => { setDisplay('block') }} onMouseOver={() => { setDisplay('none') }} style={{ marginRight: 10, backgroundColor: '#1b2949' }} ><Baixar size={20} /></button>}
                content={() => divRef.current} />
            </div>
          </Col>
        </Row>



      </div>

      {info?.id &&
        <div className="modal-body" id='minha-div' ref={divRef} >

          <Col lg={12}>

            <div style={{ backgroundColor: '#4549A2', fontSize: 12, textAlign: 'center', color: '#fff', fontWeight: 'bold' }} ></div>
          </Col>



          <table className="table" >
            <tbody>

              <tr>
                <td>
                  {obj.foto &&
                    <img src={`https://server.corpar.com.br/imagens/${obj.foto}`} alt="" height="100%" width='150' style={{ paddingTop: 10 }} />
                   
                  }

                </td>
                <td>
                  <p style={{ marginBottom: 2, fontSize: 14 }}>Nome: {obj.nome} </p>
                  <p style={{ marginBottom: 2, fontSize: 14 }}>Celular: {obj.dados.celular}</p>
                  <p style={{ marginBottom: 2, fontSize: 14 }}>Email:{obj.email}</p>
                </td>
                <td>
                  <p style={{ marginBottom: 2, fontSize: 14 }}>Cálculo Nº: {info?.id}</p>
                  <p style={{ marginBottom: 2, fontSize: 14 }}>{info?.estado ? `Estado: ${info.estado}` : ''}</p>
                  <p style={{ marginBottom: 2, fontSize: 14 }}>Gerado em {dataAtual().data1}</p>
                  <p style={{ marginBottom: 2, fontSize: 14 }}>Vigência: {dataAtual().data2}</p>
               

                </td>
              </tr>



            </tbody>
          </table>


          <table className="table" >
            <thead>

              <tr>
                <table className="table" >
                  <tbody >
                    <tr style={{ padding: 10, backgroundColor: '#ccc' }}>

                      {info?.produto && info?.produto[0]?.faixas?.map((item, index) => (
                        <td key={item.id} style={{ textAlign: 'center', borderWidth: 1 }} >
                          <p style={{ fontWeight: 'bold', fontSize: 14, height: 6 }} >{item.faixa.nome.replace('-', ' à ')} : {parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor.replace(',', '.') :( infoDependente.length>0 && infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) )? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1)} vida(s)</p>
                          <p style={{ fontSize: 10 }} ></p>
                        </td>

                      ))}

                      <td style={{ textAlign: 'center', borderWidth: 1, verticalAlign: 'middle' }} >
                        <p style={{ fontWeight: 'bold', fontSize: 14, height: 0 }} >Total : {calculoDetalhe.vidas} vida(s)</p>

                      </td>
                    </tr>
                  </tbody>
                </table>

              </tr>
            </thead>
            <tbody id='tabela'>

              {info && info?.produto?.map((item, index) => (
                <>

                  <tr className="tr" style={{ textAlign: 'center' }}   >

                    <td style={{ textAlign: 'center' }} >
                      <button style={{ float: 'right', display: display, color: '#D75350' }} className='btn btn-default' onClick={() => { handleClick(item.id, item?.faixas.length > 0 ? item?.faixas.map((item3) => { return item3.id }).join('&') : item.precovida, item, { imagem: info?.imagem, operadora: info?.operadora }) }} >x</button>
                      <p style={{ fontWeight: 'bold', fontSize: 14, height: 30 }} >  <img src={`https://server.corpar.com.br/imagens/${item?.imagem}`} width='10%' style={{ margin: 'auto', marginRight: 10 }} /> {item.operadora} - {item.nome} {item.cobertura ? ` - ${item.cobertura}` : ''}  </p>
                      <p style={{ fontSize: 10, height: 10 }} > {item.vidas} {item.acomodacao ? `- ${item.acomodacao} - ` : ''} {item.coparticipacao == 'Sim' ? `Com coparticipacão ${item.percentual ? 'de' + item.percentual + '%' : ''}` : 'Sem coparticipacão'}</p>

                      <table className="table" style={{ cursor: 'pointer' ,marginBottom:0}}>
                        <tbody>

                          <tr id={item?.faixas.map((item3) => { return item3.id }).join('&')}   >
                            {item.faixas?.length ?
                              <>

                                {item?.faixas?.map((item2, index) => (
                                  <td key={`${item2.id}-${index}`} style={{ textAlign: 'center', borderWidth: 1,padding:5,width:'50%'  }} >

                                    <p style={{ fontWeight: 'bold', fontSize: 14, height: 0 }} >{(parseFloat(item2.valor?.replace(',', '.')) * parseFloat(item2?.cotacoesfaixa?.valor ? item2?.cotacoesfaixa?.valor.replace(',', '.') : (infoDependente.length>0 && infoDependente?.map((elemento) => (elemento <= item2?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item2?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                    <span style={{ fontSize: 10, height: 0 }} >R$ {(parseFloat(item2.valor.replace(',', '.')) + parseFloat(item2.odonto ? item2.odonto.replace(',', '.') : '0'))} p/ vida</span>

                                  </td>
                                ))}
                                <td style={{ textAlign: 'center', borderWidth: 1, verticalAlign: 'middle' ,padding:5,width:'50%' }} >
                                  <p style={{ fontWeight: 'bold', fontSize: 14, height: 0 }} className='valorTotal' >{(item?.faixas?.reduce((acumulador, item) => { return acumulador + (parseFloat(item.valor.replace(',', '.')) * parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor.replace(',', '.') : (infoDependente.length>0 && infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1)) }, 0)* Number(calculoDetalhe.vidas) )?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                  <span style={{ fontSize: 10, height: 0 }} >Valor Total</span>
                                </td>
                              </>
                              :
                              <>
                                <td style={{ textAlign: 'center', borderWidth: 1 }} >
                                  <p style={{ fontSize: 14, height: 0 }} >R$ {item.precovida} p/ vida</p>
                                </td>
                                <td style={{ textAlign: 'center', borderWidth: 1 }} >
                                  <p style={{ fontWeight: 'bold', fontSize: 14, height: 0 }} className='valorTotal'  >{(parseFloat(item.precovida.replace(',', '.')) * parseFloat(calculoDetalhe.vidas))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                </td>

                              </>
                            }


                          </tr>


                        </tbody>
                      </table>
                    </td>
                  </tr>


                </>



              ))}



            </tbody>
          </table>

        
          {info?.observacoes?.length > 0 &&
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '210px'}}>Observações Importantes</p>
            <p style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>"Esta simulação poderá variar de acordo com o perfil do cliente e a critério da operadora.<br/> Preços, condições e critérios de aceitação da proposta estão sujeitos a analise e confirmação no momento da implantação do contrato."</p>
            <Col lg={12}>
              <div className='form-control' style={{ border: 'none', borderWidth: 1,lineHeight:1.5, fontSize: 16,  height: '100%' }} >
                {info && info?.observacoes?.map((item, index) => (
                  <>
                    <p style={{ fontWeight: 'bold' }} >
                   {item?.produto.join(' / ')}
                    </p>
                    <p  style={{fontSize:16}}>
                      <div dangerouslySetInnerHTML={{ __html: item.item.obs.replace(/font-family:[^;]*;/g, '')  }}  ></div>
                    </p>
                  </>
                ))}
              </div>
            </Col>
          </div>}

        {info?.rede?.length > 0 &&
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '180px'}}>Rede de Atendimento</p>
            <Col lg={12}>
              <div className='form-control' style={{ border: 'none', borderWidth: 1,lineHeight:1.5, fontSize: 16, height: '100%' }}  >
                {info && info?.rede?.map((item) => (
                  <>
                    <p style={{ fontWeight: 'bold' }} >
                   {item?.produto.join(' / ')}
                    </p>
                    <p style={{fontSize:16}}>
                      <div dangerouslySetInnerHTML={{ __html: item.item.obs.replace(/font-family:[^;]*;/g, '')  }}  ></div>
                    </p>
                  </>
                ))}
              </div>
            </Col>
          </div>}


        {info?.reembolso?.length > 0 &&
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '250px'}}>Diferenciais e Reembolsos</p>
            <Col lg={12}>
              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1,lineHeight:1.5, fontSize: 16, height: '100%' }}  >
                {info && info?.reembolso?.map((item) => (
                  <>
                    <p style={{ fontWeight: 'bold' }} >
                   {item?.produto.join(' / ')}
                    </p>
                    <p style={{fontSize:16}}>
                      <div dangerouslySetInnerHTML={{ __html: item.item.obs.replace(/font-family:[^;]*;/g, '') }}  ></div>
                    </p>
                  </>
                ))}
              </div>
            </Col>
          </div>}
        {info?.carencia?.length > 0 &&
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '350px'}}>Carências</p>
            <Col lg={12}>
              <div className='form-control' style={{ border: "none", borderColor: '#E9E9EF', borderWidth: 1,lineHeight:1.5, fontSize: 16, height: '100%' }}  >
                {info && info?.carencia?.map((item) => (
                  <>
                    <p style={{ fontWeight: 'bold' }} >
                   {item?.produto.join(' / ')}
                    </p>
                    <p style={{fontSize:16}}>
                      <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                    </p>
                  </>

                ))}

              </div>
            </Col>
          </div>
        }



        {info?.dependente?.length > 0 &&
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '140px'}}>Dependentes</p>
            <Col lg={12}>
              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1,lineHeight:1.5, fontSize: 16, height: '100%' }}   >
                {info && info?.dependente?.map((item) => (
                  <>
                    <p style={{ fontWeight: 'bold' }} >
                   {item?.produto.join(' / ')}
                    </p>
                    <p style={{fontSize:16}}>

                      <div dangerouslySetInnerHTML={{ __html: item.item.obs.replace(/font-family:[^;]*;/g, '') }}  ></div>
                    </p>
                  </>

                ))}

              </div>
            </Col>
          </div>
        }


        {info?.documento?.length > 0 &&
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '230px'}}>Documentos Necessários</p>
            <Col lg={12}>
              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1,lineHeight:1.5, fontSize: 16, height: '100%' }}  >
                {info && info?.documento?.map((item) => (
                  <>
                    <p style={{ fontWeight: 'bold' }} >
                   {item?.produto.join(' / ')}
                    </p>
                    <p style={{fontSize:16}}>
                      <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                    </p>
                  </>


                ))}

              </div>
            </Col>
          </div>
        }

{info?.pagamento?.length > 0 &&
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '230px'}}>Formas de Pagamentos</p>
            <Col lg={12}>
              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1,lineHeight:1.5, fontSize: 16, height: '100%' }}  >
                {info && info?.pagamento?.map((item) => (
                  <>
                    <p style={{ fontWeight: 'bold' }} >
                   {item?.produto.join(' / ')}
                    </p>
                    <p style={{fontSize:16}}>
                      <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                    </p>
                  </>

                ))}

              </div>
            </Col>
          </div>
        }

        {info?.entidades && info.entidades.length > 0 ?
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '240px'}}>Entidades e Profissões</p>
            <Col lg={12}>
              <div className='form-control' style={{ border: "none", borderColor: '#E9E9EF', borderWidth: 1,lineHeight:1.5, fontSize: 16, fontFamily: '"Open Sans", Open' }}  >
                {info && info?.nome?.map((item, index) => (
                  <span key={`Entidades-${index}`} style={{fontSize:16}}>
                    {item.entidades && item.entidades.map((item2) => (
                      <> - {item2.nome} -</>
                    ))}
                  </span>

                ))}

              </div>
            </Col>
          </div>
          : ''}










        {info?.areacomercializacao?.length > 0 &&
          <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
            <p style={{ textAlign: 'center', fontSize: 15,textTransform:'uppercase', marginTop: -16, backgroundColor: '#fff', width: '350px'}}>Áreas de Comercialização e Atendimento  </p>
            <Col lg={12}>
              <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1,lineHeight:1.5, fontSize: 16, height: '100%' }}  >
                {info && info?.areacomercializacao?.map((item) => (
                  <  >
                    <p style={{ fontWeight: 'bold' }} >
                   {item?.produto.join(' / ')}
                    </p>

                    {item?.municipios.map((item3, index) => (
                      <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.municipio?.nome)} -` }}  ></span>
                    ))}

                  </>

                ))}

              </div>
            </Col>
          </div>
        }
        </div>
      }
    </Modal>
  )
};
