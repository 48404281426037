import React, { useEffect, useState } from "react";

import Info from "./info";
export default function MainSection({ datas }) {
  const [dados, setDados] = useState([]);
  const [item2, setItem] = useState("");
  const handleFiltro = (val) => {
    let fisica = datas.valores
    setDados(fisica);
  };
  const handleDados = () => {
    handleFiltro();
  };
  useEffect(() => {

    handleDados();
  }, [datas]);
  const handler = (e, value) => {
    e.preventDefault();
    const getItems = document.querySelectorAll(`#${value}`).length;
    if (getItems > 0) {
      if (item2 !== value) {
        setItem(value);
      } else {
        setItem("");
      }
    }
  };
  return (
    <section className="portfolio-section   ">
      <div className="container">

        <div className="row align-items-center  portfolio-wrap">
          {
            dados.length > 0 &&
            dados.map((item) => (
              <a key={item.id} className={`col-lg-12 col-sm-12 item `} style={{ cursor: "pointer" }} >
                <div className="feature-item   style-three " style={{ marginBottom: 10, padding: 10 }}>
                  <div className="icon"></div>

                  <div className="feature-content style-three" style={{ marginBottom: 5 }}>
                 
                    <Info id={item.id} estado={datas?.estado} />
                  </div>
                </div>
              </a>
            ))}
        </div>
      </div>
    </section>
  );
}
