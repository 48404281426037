import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { Api } from "../../api/apiTabelaPrecos";
import { Api as ApiSite } from "../../api";
import { Cadastrar } from "./utils/cadastrar";
import { Excluir } from "./utils/excluir";
import { ListagemTabPre } from "./utils/listagem";
import { Visualizar } from "./utils/visualizar";
import { GestorModal } from "../components/modal";
function Ferramentas() {
  const [resposta, setResposta] = useState(false)
  const [error, setError] = useState(false)
  const [msg, setMsg] = useState('')
  const [dados, setDados] = useState()
  const [info, setInfo] = useState()
  const [estados, setEstados] = useState([])
  const [estadosSelect, setEstadosSelect] = useState()

  const [profissoes, setProfissoes] = useState()
  const [profissoesSelect, setProfissoesSelect] = useState()
  const [statusProfissoes, setStatusProfissoes] = useState(false)

  const [statusCoparticipacao, setStatusCoparticipacao] = useState(false)
  const [statusContratacao, setStatusContratacao] = useState(false)
  const [statusNumeros, setStatusNumeros] = useState(false)
  const [statusArea, setStatusArea] = useState(false)
  const [numeroVidas, setNumeroVidas] = useState([])

  const [numeroVidasSelect, setNumeroVidasSelect] = useState([])

  const [areas, setAreas] = useState([])
  const [areasSelect, setAreasSelect] = useState([])
  const [produtos, setProdutos] = useState([])
  const [produtosfiltro, setProdutosfiltro] = useState([])
  const [produtosSelect, setProdutosSelect] = useState([])
  const [operadoras, setOperadoras] = useState([])
  const [operadorasSelect, setOperadorasSelect] = useState([])
  const [tiposOperadoras, setTiposOperadoras] = useState([])
  const [tiposOperadorasSelect, setTiposOperadorasSelect] = useState([])
  const [loading, setLoading] = useState(false)
  const [id, setid] = useState('');
  const [modalExcluir, setModalExcluir] = useState(false)
  const [modalVisualizar, setModalVisualizar] = useState(false)
  const [buscar, setBuscar] = useState('');
  const [list, setList] = useState([])
  const getAuthUser = localStorage.getItem('authUser')
  const obj2 = getAuthUser ? JSON.parse(getAuthUser ? getAuthUser : '') : ''
  const permissoes = obj2.permissoes.find((val) => val.modulo == 21)
  const [coparticipacoes, setCoparticipacoes] = useState([])
  const [coberturas, setCoberturas] = useState([])
  const [contratacoes, setContratacoes] = useState([])
  const [numerodevidas, setNumerodeVidas] = useState([])
  const [municipios, setMunicipios] = useState([])
  const [obj, setObj] = useState('');
  useEffect(() => {
    Listagem()
    handleTipoOperadoras()
    handleProfissoes()

  }, [])
  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    const obj = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
    setObj(obj)
  }, [])



  const gerarTabela = (dados) => {

    let info = dados.dados?.map((item) => ({
      ...item,
      tabelafaixas: item.tabelafaixas.slice().sort((a, b) => a.idfaixa - b.idfaixa)
    }));
    info = info.slice().sort((a, b) => parseFloat(a.tabelafaixas[0]?.valor ? a.tabelafaixas[0]?.valor.replace(',', '.') : 0) - parseFloat(b.tabelafaixas[0]?.valor ? b.tabelafaixas[0]?.valor.replace(',', '.') : 0));
    let estado = estados.find((p) => p.id == validation.values.estados)

    let array = { id: dados.info && dados.info[0]?.idtabelaprecos ? dados.info[0]?.idtabelaprecos : 0, estado: dados.info && dados?.info[0]?.tabelaprecos[0]?.estado.nome ? dados?.info[0]?.tabelaprecos[0]?.estado.nome : estado.nome, nome: [], cobertura: [], acomodacao: [], coparticipacao: [], observacoes: [], coparticipacoes: [], areacomercializacao: [], dependente: [], documento: [], pagamento: [], rede: [], reembolso: [], carencia: [], faixa: [], precovida: [] }


    info.map((item) => {
      if (item.nome) {
        array.nome.push(item.nome)
      }
      array.cobertura.push(item.cobertura)
      array.acomodacao.push(item.acomodacao)

      if (item.coparticipacao) {
        array.coparticipacao.push({ nome: item.coparticipacao, status: item.coparticipacao == "Não" ? false : true, percentual: item.percentual })
      }
      if (item.precovida && item.cobertura.includes('Odontológico')) {
        array.precovida.push(item.precovida)
      }

      if (item.coparticipaco?.id) {
        if (item.coparticipaco?.id && !array.coparticipacoes.some((arr) => arr.id == item.coparticipaco?.id)) {
          array.coparticipacoes.push({ id: item.coparticipaco.id, item: item.coparticipaco, produto: [item.nome] })
        } else {
          array.coparticipacoes.some((arr) => arr.id == item.coparticipaco?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.observaco?.id) {
        if (item.observaco?.id && !array.observacoes.some((arr) => arr.id == item.observaco?.id)) {
          array.observacoes.push({ id: item.observaco.id, item: item.observaco, produto: [item.nome] })
        } else {
          array.observacoes.some((arr) => arr.id == item.observaco?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.areacomercializacao?.id) {
        if (item.areacomercializacao?.id && !array.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id)) {
          array.areacomercializacao.push({ id: item.areacomercializacao.id, produto: [item.nome], municipios: item.areacomercializacao.areacomercializacaoestadosmunicipios })
        } else {
          array.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.dependente?.id) {
        if (item.dependente?.id && !array.dependente.some((arr) => arr.id == item.dependente?.id)) {
          array.dependente.push({ id: item.dependente.id, item: item.dependente, produto: [item.nome] })
        } else {
          array.dependente.some((arr) => arr.id == item.dependente?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.documento?.id) {
        if (item.documento?.id && !array.documento.some((arr) => arr.id == item.documento?.id)) {
          array.documento.push({ id: item.documento.id, item: item.documento, produto: [item.nome] })
        } else {
          array.documento.some((arr) => arr.id == item.documento?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.pagamento?.id) {
        if (item.pagamento?.id && !array.pagamento.some((arr) => arr.id == item.pagamento?.id)) {
          array.pagamento.push({ id: item.pagamento.id, item: item.pagamento, produto: [item.nome] })
        } else {
          array.pagamento.some((arr) => arr.id == item.pagamento?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.carencia?.id) {
        if (item.carencia?.id && !array.carencia.some((arr) => arr.id == item.carencia?.id)) {
          array.carencia.push({ id: item.carencia.id, item: item.carencia, produto: [item.nome] })
        } else {
          array.carencia.some((arr) => arr.id == item.carencia?.id ? arr.produto.push(item.nome) : '')
        }
      }

      if (item.rede?.id) {
        if (!array.rede.some((arr) => arr.id == item.rede?.id)) {
          array.rede.push({ id: item?.rede.id, item: item?.rede, produto: [item.nome] })
        } else {
          array.rede.some((arr) => arr.id == item.rede?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.reembolso?.id) {
        if (!array.reembolso.some((arr) => arr.id == item.reembolso?.id)) {
          array.reembolso.push({ id: item.reembolso.id, item: item.reembolso, produto: [item.nome] })
        } else {
          array.reembolso.some((arr) => arr.id == item.reembolso?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.tabelafaixas?.length > 0) {
        let faixas = []
        item?.tabelafaixas.map((item) => {
          if (!faixas.some((item2) => item2.nome == item.faixa.nome)) {

            let id = item.faixa.nome.split('-')
            faixas.push({ id: parseInt(id[0]), nome: item.faixa.nome, valor: [(parseFloat(item.valor?.replace(',', '.')) + parseFloat(item.odonto ? item.odonto?.replace(',', '.') : '0'))] })
          } else {
            let index = faixas.findIndex((item2) => item2.nome.includes(item.faixa.nome))
            faixas[index].valor.push((parseFloat(item.valor.replace(',', '.')) + parseFloat(item.odonto ? item.odonto.replace(',', '.') : '0')))
          }

        })
        array.faixa.push(faixas)
      }

    })
    setInfo(array)
    let faixas = []
    for (let index = 0; index < info.length; index++) {
      if (info[index]?.tabelafaixas) {
        info[index]?.tabelafaixas.map((item) => {
          if (!faixas.some((item2) => item2.nome == item.faixa.nome)) {

            let id = item.faixa.nome.split('-')
            faixas.push({
              id: parseInt(id[0]), nome: item.faixa.nome, valor: [

                (parseFloat((item?.valor.replace(',', '.'))) * (item?.coeficiente && item?.statuscoeficiente ? (parseFloat(item.coeficiente) / 100) : 1)) + parseFloat(item.odonto ? item.odonto.replace(',', '.') : 0)]
            })
          } else {
            let index = faixas.findIndex((item2) => item2.nome.includes(item.faixa.nome))
            faixas[index].valor.push(
              (parseFloat((item?.valor.replace(',', '.'))) * (item.coeficiente && item.statuscoeficiente ? (parseFloat(item.coeficiente) / 100) : 1)) + parseFloat(item.odonto ? item.odonto.replace(',', '.') : 0))
          }

        })
      }

    }
    faixas.sort((item, item2) => item.id - item2.id)
    setFaixas(faixas)
    setModalVisualizar(true)
  }
  const Listagem = async () => {
    let list = await Api.listagem()
    setList(list)
  }
  const handleExcluir = async () => {
    let list = await Api.delete({ id: id })
    setList(list)
    setModalExcluir(false);
    handleLimpar(true)
  }
  const handleDadosBuscar = async (id) => {
    let dados = await Api.visualizarBuscar({ id: id })
    let val = validation.values.operadora
    val = val?.split('-')
    let nome = `${dados.info[0].tabelaprecos[0].operadora.nome} - ${dados.info[0]?.tabelaprecos[0]?.numerovidas?.replace('-', ' A ')} Vidas`
    setNomeProduto(nome)

    await gerarTabela(dados)

  }
  const filtrar = async (nome) => {
    setLoading(true)
    let list = await Api.filtar({ nome: nome })
    setList(list.list)
    setLoading(false)
  }

  const handleEstados = async (tipoOperadora) => {
    setLoading(true)
    let option = await Api.estados({ tipoOperadoras: tipoOperadora })

    setEstados(option)
    setEstadosSelect([])
    setLoading(false)
  }


  const handleTipoOperadoras = async () => {
    let option = await Api.tiposOperadoras()
    let list = option.sort((a, b) => b.nome.localeCompare(a.nome));
    setTiposOperadoras(list)
  }

  const handleOperadorasValue = async (val) => {
    setLoading(true)

    setProdutos([])
    setProdutosfiltro([])
    setProdutosSelect([])
    let option = await Api.operadoras({ id: val.tipoOperadora, estado: val.estado, municipio: val.municipio })

    setOperadoras(option)
    setLoading(false)
  }
  const handleTiposOperadorasValue = async (val) => {
    setTiposOperadorasSelect(val)

  }

  const handleProfissoesValue = async (val) => {
    setProfissoesSelect(val)

  }
  const handleAreas = async (dados) => {

    if (dados.estado && dados.operadora && dados.tipoOperadora) {
      let option = await Api.areas({ idEstado: dados.estado, idOperadora: dados.operadora, idTipoOperadora: dados.tipoOperadora })
      let val = option?.map((item) => { return { value: item.id, label: item.nome } })
      setAreas(val)
      setAreasSelect([])
    }
  }
  const handleAreasValue = async (val) => {
    setAreasSelect(val)
    let valOperadora = validation.values.operadora
    let coparticipacoes = validation?.values?.coparticipacao?.map((item) => { return item.value })
    valOperadora = valOperadora.split('-')
    if (valOperadora[0] && validation.values.tipoOperadora && val.length > 0) {
      let ids = val.map((item) => { return item.value })
      let option = await Api.produtos({ statusProfissoes, idAreaComercializacao: ids, idOperadora: valOperadora[0], idTipoOperadora: validation.values.tipoOperadora, idEntidade: validation.values.profissao, coparticipacao: coparticipacoes, contratacao: validation.values.contratacao, numeroVidas: numeroVidasSelect, cobertura: validation.values?.cobertura?.map((item) => { return item.value }) })
      let val1 = option?.map((item) => { return { label: item.nome, value: item.id } })
      setProdutos(val1)
      setProdutosSelect([])
    }
  }
  const handleNumero = async (dados) => {
    let valOperadora = dados.operadora
    valOperadora = valOperadora.split('-')
    if (valOperadora[0] && dados.tipoOperadora) {
      let coparticipacoes = dados.coparticipacao?.map((item) => { return item.value })
      let numero = await Api.numeroVidas({ operadora: valOperadora[0], tipoOperadora: dados.tipoOperadora, coparticipacao: coparticipacoes, contratacao: dados.contratacao ? dados.contratacao : '', cobertura: (validation.values.tipoOperadora == '1' || validation.values.tipoOperadora == '4') ? validation.values?.cobertura?.map((item) => { return item.value }) : '' })
      let val = numero.map((item) => { return { value: `${item.minvidas}-${item.maxvidas}`, label: `${item.minvidas}-${item.maxvidas}` } })
      setNumeroVidas(val)
    }
  }
  const handleProdutosValue = async (val) => {
    setProdutosSelect(val)
  }
  const [salvarVisualizar, setSalvarVisualizar] = useState(false);
  const [faixas, setFaixas] = useState([])
  const handleVisualizar = async () => {
    let ids = []
    produtosSelect.map(async (item) => {
      ids.push(item.value)
    })
    let dados = await Api.visualizar({ id: ids, estado: validation.values.estados })
    await gerarTabela({ dados })
    return true
  }


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: '' || id,
      descricao: dados?.descricao ? dados.descricao : '',
      coparticipacao: dados?.coparticipacao ? dados.coparticipacao : '',
      contratacao: dados?.contratacao ? dados.contratacao : '',
      numero: dados?.numero ? dados.numero : '',
      estados: dados?.estados ? dados.estados : '',
      entidades: null,
      operadora: dados?.operadora ? dados.operadora : '',
      tipoOperadora: dados?.tipoOperadora ? dados.tipoOperadora : '',
      numerovidas: dados?.numerovidas ? dados.numerovidas : '',
      municipios: dados?.municipios ? dados.municipios : '',
      areaComercializacao: null,
      produtos: null,
      profissao: dados?.profissao ? dados.profissao : '',
      cobertura: dados?.cobertura ? dados.cobertura : '',
    },

    onSubmit: async (values) => {
      values.entidades = profissoesSelect
      values.areaComercializacao = areasSelect
      values.produtos = produtosSelect



      if (id) {

        let json = await Api.editar(values)

        if (json.error) {
          setError(true)
          setResposta(true)
          setMsg(json.msg)
        } else {
          Listagem()
          setBuscar('')
          handleLimpar(true)
        }

      } else {
        let json = await Api.cadastro(values)
        if (json.error) {
          setError(true)
          setResposta(true)
          setMsg(json.msg)
        } else {
          
          setError(false)
          setResposta(true)
          setMsg(json.msg)
          handleLimpar(false)
          setProdutosfiltro([])
          setProdutos([])
          setProdutosSelect([])
          Listagem()
          window.open(`/tabelaprecos/${json.id}`, '_blank');

        }
      }

    }
  });

  const handleLimpar = (status = false) => {
    if (status) {
      setResposta(false)
    }
    setOperadorasSelect([])
    setEstadosSelect([])
    setAreasSelect([])
    setProdutosSelect([])
    setTiposOperadoras([])
    setTiposOperadorasSelect([])
    setTiposOperadoras([])
    setProfissoesSelect([])
    validation.values.descricao = ''
    validation.values.estados = ''
    validation.values.entidades = null
    validation.values.operadora = ''
    validation.values.tipoOperadora = ''
    validation.values.areaComercializacao = null
    validation.values.produtos = null
    validation.values.coparticipacao = ''
    validation.values.contratacao = ''
    validation.values.numero = ''
    setid('')
    setBuscar('')
    setDados({ nome: '', operadora: '', tipoOperadora: '', descricao: '', estados: '', municipios: '', coparticipacao: '', contratacao: '', numero: '', profissao: '', cobertura: '' })
  }
  const [nomeProduto, setNomeProduto] = useState('')
  const handleProdutos = () => {
    let val = validation.values.operadora
    val = val?.split('-')
    let nomeOperadora = operadoras.find((item) => item.id == val[0])
    let nome = `${nomeOperadora?.nome} - ${validation.values.numero.replace('-', ' A ')} Vidas`
    setNomeProduto(nome)
  }

  useEffect(() => {
    handleProdutos();
  }, [validation.values.operadora, validation.values.numero]);
  const [area, setArea] = useState(1);

  const handleCarregamento = (status) => {
    let body = document.querySelector("body");
    if (status) {
      if (body) {
        body.style.cursor = "wait";
      }
    } else {
      if (body) {
        body.style.cursor = "";
      }
    }
  };


  useEffect(() => {
    if (validation.values.estados) {
      handleMunicipios({ estado: validation.values.estados })
    }
    if (validation.values.estados && validation.values.tipoOperadora) {
      handleOperadorasValue({ estado: validation.values.estados, tipoOperadora: validation.values.tipoOperadora, municipio: validation.values.municipios })
    }
    validation.values.municipios = ''
    setProdutos([])
    setProdutosSelect([])
    setProdutosfiltro([])
  }, [validation.values.estados])
  useEffect(() => {
    if (validation.values.tipoOperadora) {
      handleEstados(validation.values.tipoOperadora)
    }
    if (validation.values.estados && validation.values.tipoOperadora) {
      handleOperadorasValue({ estado: validation.values.estados, tipoOperadora: validation.values.tipoOperadora, municipio: validation.values.municipios })
    }
    if (validation.values.estados) {
      handleMunicipios({ estado: validation.values.estados })
    }
   
    setProdutos([])
    setProdutosSelect([])
    setProdutosfiltro([])
    validation.values.operadora = ''
    validation.values.coparticipacao = ''
    validation.values.cobertura = ''
    validation.values.profissao = ''
    validation.values.contratacao = ''
    validation.values.municipios = ''
  }, [validation.values.tipoOperadora])

  useEffect(() => {


    if (validation.values.estados && validation.values.tipoOperadora) {
      handleOperadorasValue({ estado: validation.values.estados, tipoOperadora: validation.values.tipoOperadora, municipio: validation.values.municipios })
    }
    setProdutos([])
    setProdutosfiltro([])
    setProdutosSelect([])
    validation.values.operadora = ''
    validation.values.coparticipacao = ''
    validation.values.cobertura = ''
    validation.values.profissao = ''
    validation.values.contratacao = ''
  }, [validation.values.municipios])

  const handleMunicipios = (dados) => {
    setMunicipios([])
    let list = [...estados]
    let listagem = list.find((item) => item.id == dados.estado)?.areacomercializacaoestadosmunicipios
    listagem = listagem?.map((item) => { return { id: item?.municipio.id, nome: item?.municipio?.nome } })
    const idsUnicos = new Set();
    listagem = listagem?.filter((item) => {
      if (idsUnicos.has(item.id)) {
        return false;
      } else {
        idsUnicos.add(item.id);
        return true;
      }
    });
    
    listagem = listagem?.sort((a, b) => {
      return a.nome.localeCompare(b.nome);
    });
    setMunicipios(listagem)

  }
  const handleProdutosvalores = async (dados) => {
    setLoading(true)
    setProdutosfiltro([])
    setProdutosSelect([])
   // alert(1)
    let option = await Api.produtos({ statusProfissoes, idOperadora: validation.values.operadora, idTipoOperadora: validation.values.tipoOperadora, idEntidade: validation.values.profissao?.value, coparticipacao: validation.values.coparticipacao, contratacao: validation.values.contratacao, cobertura: validation.values?.cobertura, estado: validation.values.estados })
    let val1 = option?.map((item) => { return { ...item,gruposentidades:item.gruposentidades, label: item.nome, value: item.id } })
    let coparticipacoesarray = []
    let coberturasarray = []
    let contratacaoarray = []
    let numerovidasarray = []
    let profissaoarray = []

    option.map((item) => {

      if (item.coparticipacao && !coparticipacoesarray.includes(item.coparticipacao)) {
        if( (item.coparticipacao == 'Sim' || item.coparticipacao == 'Total')){

        
          if(!coparticipacoesarray.some((item)=>(item =='Sim' || item ==  'Total' ))){
            coparticipacoesarray.push(item.coparticipacao)
          }
        
        }else{
          coparticipacoesarray.push(item.coparticipacao)
        }
      }

      if (item.cobertura && !coberturasarray.includes(item.cobertura)) {
        coberturasarray.push(item.cobertura)
      }
      if (item.contratacao && !contratacaoarray.includes(item.contratacao)) {
       
        contratacaoarray.push(item.contratacao)
      }
      if (!numerovidasarray.includes(`${item.minvidas} a ${item.maxvidas}`)) {
        numerovidasarray.push(`${item.minvidas} a ${item.maxvidas}`)
      }
    
      item.gruposentidades?.map((item2)=>{
        if (!profissaoarray.some((item3)=>item3.id == item2?.entidadesprofisso?.profisso?.id)) {
          profissaoarray.push({id:item2?.entidadesprofisso?.profisso?.id,nome:item2?.entidadesprofisso?.profisso?.nome})
        }
      })
  
    })
    numerovidasarray = numerovidasarray?.sort((a, b) => {
      const numA = parseInt(a.split(' ')[0], 10);
      const numB = parseInt(b.split(' ')[0], 10);
      return numA - numB;
    });
    setNumerodeVidas(numerovidasarray)
    setCoparticipacoes(coparticipacoesarray)
    setProfissoes(profissaoarray)
    setCoberturas(coberturasarray)
    setContratacoes(contratacaoarray)
    if (validation.values.tipoOperadora != 4) {
      setProdutosfiltro(val1)
      setProdutosSelect(val1)
    }
    setProdutos(val1)
    setLoading(false)
  }
  const handleProdutosvaloresFiltros = async (numeroVidas,coparticipacao,cobertura,contratacao,profissao) => {
    let list = [...produtos]
    // let listagem = list.filter((item) => ((validation.values.coparticipacao ? item.coparticipacao == validation.values.coparticipacao : true) && (validation.values.cobertura ? item.cobertura == validation.values.cobertura : true) && (validation.values.contratacao ? item.contratacao == validation.values.contratacao : true) && (validation.values.profissao ? item.gruposentidades?.some((item) => item.identidade == validation.values.profissao) : true) && (validation.values.numeroVidas ? `${item.minvidas} a ${item.maxvidas}` == validation.values.numeroVidas : true)))

     let listagem = list.filter((item) => (  (numeroVidas ? `${item.minvidas} a ${item.maxvidas}` ==numeroVidas : true) &&  (coparticipacao ? item.coparticipacao == coparticipacao : true) && (cobertura ? item.cobertura == cobertura: true) && (contratacao ? item.contratacao == contratacao : true) && (profissao ? item.gruposentidades?.some((item2) => item2?.entidadesprofisso?.profisso?.id == profissao) : true) ))
   
 
    let coparticipacoesarray = []
    let coberturasarray = []
    let contratacaoarray = []

    let profissaoarray = []

    listagem.map((item) => {
    
      if(!coparticipacao){

       
        
        if (!coparticipacoesarray.includes(item.coparticipacao)) {
  
      
        if( (item.coparticipacao == 'Sim' || item.coparticipacao == 'Total')){

        
          if(!coparticipacoesarray.some((item)=>(item =='Sim' || item ==  'Total' ))){
            coparticipacoesarray.push(item.coparticipacao)
          }
        
        }else{
          coparticipacoesarray.push(item.coparticipacao)
        }
    
        
        }
      }
      if(!cobertura){
        if (item.cobertura && !coberturasarray.includes(item.cobertura)) {
          coberturasarray.push(item.cobertura)
        }
      }

      if(!contratacao){
  
        if (item.contratacao && !contratacaoarray.includes(item.contratacao)) {
          contratacaoarray.push(item.contratacao)
        }
      }
   
      if(!profissao){
        item.gruposentidades?.map((item2)=>{
          if (!profissaoarray.some((item3)=>item3.id == item2?.entidadesprofisso?.profisso?.id)) {
            profissaoarray.push({id:item2?.entidadesprofisso?.profisso?.id,nome:item2?.entidadesprofisso?.profisso?.nome})
          }
        })
      }
    
    
    
    
  
    })

   
    if(!coparticipacao){
      setCoparticipacoes(coparticipacoesarray)
    }
    if(!cobertura){
      setCoberturas(coberturasarray)
    }
    if(!profissao){
      setProfissoes(profissaoarray)
    }
    
    if(!contratacao){
     
      setContratacoes(contratacaoarray)
    }

 
   

 
    setLoading(false)

    
    setProdutosfiltro(listagem)
    setProdutosSelect(listagem)
  }
  useEffect(() => {
    handleProdutosvalores({ estado: validation.values.estados, tipoOperadora: validation.values.tipoOperadora })
  }, [validation.values.operadora])

  useEffect(() => {
    validation.values.coparticipacao = ''
    validation.values.cobertura = ''
    validation.values.contratacao = ''
    validation.values.profissao = ''
    handleProdutosvaloresFiltros(validation.values.numerovidas)
  }, [ validation.values.numerovidas])

  useEffect(()=>{

    validation.values.cobertura = ''
    validation.values.contratacao = ''
    validation.values.profissao = ''

    handleProdutosvaloresFiltros(validation.values.numerovidas,validation.values.coparticipacao)
  },[validation.values.coparticipacao])
 
  useEffect(()=>{

    validation.values.contratacao = ''
    validation.values.profissao = ''

    handleProdutosvaloresFiltros(validation.values.numerovidas,validation.values.coparticipacao,validation.values.cobertura)
  },[validation.values.cobertura])
  useEffect(()=>{


    validation.values.profissao = ''
    handleProdutosvaloresFiltros(validation.values.numerovidas,validation.values.coparticipacao,validation.values.cobertura,validation.values.contratacao)

  },[validation.values.contratacao])
  useEffect(()=>{

    handleProdutosvaloresFiltros(validation.values.numerovidas,validation.values.coparticipacao,validation.values.cobertura,validation.values.contratacao, validation.values.profissao?.value)

  },[validation.values.profissao?.value])
  const handleProfissoes = async () => {
    // let option = await Api.entidades()
    // let val = option
    // val?.unshift({ nome: 'Sem profissão', id: '' })
    // setProfissoes(val)
    // setProfissoesSelect([])
  }
  const [gestorModal,setGestorModal] = useState()
  const handleModal = async () => {
    let list = await ApiSite.gestorModal('Tabela');
    setGestorModal(list.dados)
  
  };
  useEffect(()=>{
    handleModal()
  },[])
  return (
    <section className="about-two  rpt-50 pb-240 rpb-100">
      <div className="container" >
        <div className="row" style={{ marginTop: -50 }}>
          <div className="col-lg-12 ">
            <div
              className="about-content pt-100 rpt-20 pr-70 rpr-0 wow fadeInRight delay-0-2s"
              style={{ paddingLeft: 15 }}
            >
              <div className="section-title mb-25">
                <h2 style={{ fontSize: 20, fontWeight: 100, textAlign: 'center' }}>
                  Crie tabelas de preços personalizadas
                </h2>
              </div>
              <div style={{ display: 'flex',marginBottom:25,marginTop:25, justifyItems: 'center', alignItems: 'center', flexDirection: 'column' }} >

                <div >

                  <button style={{ border: "none" }}
                    className={area == 1 ? "btn btn-primary" : "btn"}
                    onClick={() => {
                      setArea(area == 1 ? 2 : 1);
                    }}
                  >
                    {"Gerar Tabela"}
                  </button>
                  <button
                    style={{

                      border: "none"
                    }}
                    className={area == 2 ? "btn btn-primary" : "btn"}
                    onClick={() => {
                      setArea(area == 1 ? 2 : 1);
                    }}
                  >
                    {"Histórico"}
                  </button>
                </div>


              </div>
              {area == 1 && (
                <Form className="custom-form" encType="multipart/form-data"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Cadastrar
                    areas={areas} areasSelect={areasSelect} error={error} estados={estados} handleAreas={handleAreas} handleAreasValue={handleAreasValue} handleNumero={handleNumero} handleOperadorasValue={handleOperadorasValue} handleProdutosValue={handleProdutosValue} handleProfissoesValue={handleProfissoesValue} handleTiposOperadorasValue={handleTiposOperadorasValue} handleVisualizar={handleVisualizar} msg={msg} numeroVidas={numeroVidas} setNumeroVidasSelect={setNumeroVidasSelect} numeroVidasSelect={numeroVidasSelect} operadoras={operadoras} produtos={produtosfiltro} produtosSelect={produtosSelect} profissoes={profissoes} resposta={resposta} setStatusArea={setStatusArea} setStatusContratacao={setStatusContratacao} setStatusCoparticipacao={setStatusCoparticipacao} setStatusNumeros={setStatusNumeros} statusArea={statusArea} statusContratacao={statusContratacao} statusCoparticipacao={statusCoparticipacao} statusNumeros={statusNumeros} statusProfissoes={statusProfissoes} tiposOperadoras={tiposOperadoras} validation={validation} loading={loading} setSalvarVisualizar={setSalvarVisualizar} coparticipacoes={coparticipacoes} coberturas={coberturas} contratacoes={contratacoes} numerodevidas={numerodevidas} municipios={municipios}
                  />
                </Form>
              )}
              {area == 2 && (
                <>
                  <ListagemTabPre buscar={buscar} filtrar={filtrar} handleDadosBuscar={handleDadosBuscar} list={list} permissoes={permissoes} setBuscar={setBuscar} setModalExcluir={setModalExcluir} setModalVisualizar={setModalVisualizar} setid={setid} handleCarregamento={handleCarregamento}
                  />
                </>
              )}
              <Excluir handleExcluir={handleExcluir} modalExcluir={modalExcluir} setModalExcluir={setModalExcluir} />
              <Visualizar faixas={faixas} info={info} modalVisualizar={modalVisualizar} nomeProduto={nomeProduto} setModalVisualizar={setModalVisualizar} handleCarregamento={handleCarregamento} />
            </div>
          </div>
        </div>
      </div>
      <GestorModal dados={gestorModal} />
    </section>
  );
}

export default Ferramentas;
