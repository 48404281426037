import React, { useEffect, useState } from "react";
import {Col,Form,Input,Label,Modal,Row, UncontrolledAlert} from "reactstrap";

const Editar = ({ dados,resposta, error, msg,validation,estados,profissoes,loading,faixas,handleFaixasValue,handleAdd,
  handleExit,itens,handleTipoValue,setResposta,areaComercializacao,setSalvarVisualizar,modalEditar,setModalEditar,handleLimpar}) => {
  const [tipo, setTipo] = useState("1");
  const [ramo, setRamo] = useState("");
  useEffect(() => {
    setTipo(dados?.tipo);
    setRamo(dados?.ramo);
  }, [dados]);

  return (
    <Modal id="modal-center3" size="xl" isOpen={modalEditar} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Editar</h5>
        <button
          type="button"
          onClick={() => {
            setModalEditar(false);
            handleLimpar(true);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          className="custom-form mt-4 pt-2"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            {resposta && (
              <UncontrolledAlert
                toggle={() => {
                  setResposta(false);
                }}
                color={error ? "danger" : "success"}
              >
                {error ? (
                  <>
                    <h5 className="text-danger">
                      <i className="mdi mdi-block-helper    text-danger"></i>{" "}
                      Error
                    </h5>
                  </>
                ) : (
                  <>
                    <h5 className="text-success">
                      <i className="mdi mdi-check-all text-success"></i> Sucesso
                    </h5>
                  </>
                )}
                {msg}
              </UncontrolledAlert>
            )}
            <Col lg={6}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">
                    Tipo de Pessoa
                  </Label>
                  <select
                    name="tipo"
                    value={validation.values.tipo}
                    className="form-control"
                    onChange={(e) => {
                      validation.handleChange(e);
                      setTipo(e.target.value);
                    }}
                  >
                    <option value="">Selecione</option>
                    <option value="1">Saúde - Pessoa Física</option>
                    <option value="2">Saúde - PME</option>
                  </select>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">
                    Ramo
                  </Label>
                  <select
                    name="ramo"
                    value={validation.values.ramo}
                    className="form-control"
                    onChange={(e) => {
                      validation.handleChange(e);
                      setRamo(e.target.value);
                    }}
                  >
                    <option value="">Selecione</option>
                    <option value="1">Odontológico</option>
                    <option value="2">Saúde</option>
                  </select>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">
                    Descrição <small>(Opcional)</small>
                  </Label>
                  <Input
                    name="descricao"
                    placeholder="Opcional"
                    value={validation.values.descricao}
                    onChange={validation.handleChange}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
            </Col>
            {tipo == "2" && (
              <Col lg={3}>
                <div>
                  <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">
                      Nome da Empresa <small>(Opcional)</small>
                    </Label>
                    <Input
                      name="nomeempresa"
                      placeholder="Opcional"
                      value={validation.values.nomeempresa}
                      onChange={validation.handleChange}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
              </Col>
            )}

            <Col lg={tipo == "2" ? 3 : 4}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">
                    Pessoa de Contato <small>(Opcional)</small>
                  </Label>
                  <Input
                    name="pessoacontato"
                    placeholder="Opcional"
                    value={validation.values.pessoacontato}
                    onChange={validation.handleChange}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
            </Col>
            <Col lg={tipo == "2" ? 3 : 4}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">
                    Email <small>(Opcional)</small>
                  </Label>
                  <Input
                    name="email"
                    placeholder="Opcional"
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
            </Col>
            <Col lg={tipo == "2" ? 3 : 4}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">
                    Telefone <small>(Opcional)</small>
                  </Label>
                  <Input name="telefone" placeholder="Opcional" value={validation.values.telefone} onChange={validation.handleChange} className="form-control" type="text"
                  />
                </div>
              </div>
            </Col>
            {ramo == "2" ? (
              <>
                <Col lg={tipo == "2" ? 6 : 12}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" className="form-Label" >
                        Estados
                      </Label>
                      <select name="estados" value={validation.values.estados} className="form-control"
                        onChange={(e) => {
                          validation.handleChange(e);
                        }} >
                        <option>Selecione</option>
                        {estados &&
                          estados.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.nome}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </Col>
                {tipo == "2" && (
                  <Col lg={6}>
                    <div>
                      <div className="mb-3">
                        <Label htmlFor="example-text-input" className="form-Label"  >
                          Tipo de CNPJ{" "}
                        </Label>
                        <select name="tipocnpj"  value={validation.values.tipocnpj} className="form-control"
                          onChange={(e) => {
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Selecione</option>
                          <option value="MEI">MEI</option>
                          <option value="Padrão">Padrão</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                )}

                {tipo == "1" && (
                  <>
                    <Col lg={6}>
                      <div>
                        <div className="mb-3">
                          <Label htmlFor="example-text-input" className="form-Label" >
                            Profissão
                          </Label>
                          <select name="profissao" value={validation.values.profissao} className="form-control"
                            onChange={(e) => {
                              validation.handleChange(e);
                            }}
                          >
                            <option value="0"></option>
                            {profissoes && profissoes.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.nome}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <div className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            className="form-Label"
                          >
                            Titular
                          </Label>
                          <Input
                            name="titular"
                            value={validation.values.titular}
                            onChange={validation.handleChange}
                            className="form-control"
                            type="number"
                            placeholder="Idade"
                          />
                        </div>
                      </div>
                    </Col>
                  </>
                )}

                {tipo == "1" ? (
                  <>
                    <Row>
                      {itens.map((item, index ) => (
                        <>
                          <Col>
                            <div>
                              <div className="mb-3">
                                <Label
                                  htmlFor="example-text-input"
                                  className="form-Label"
                                >
                                  Dependente
                                </Label>
                                <button
                                  style={{
                                    float: "right",
                                    alignItems: "center"
                                  }}
                                  type="button"
                                  onClick={() => {
                                    index == itens.length - 1
                                      ? handleAdd()
                                      : handleExit(index);
                                  }}
                                  className={
                                    index == itens.length - 1
                                      ? "btn btn-success "
                                      : "btn btn-danger "
                                  }
                                >
                                  {index == itens.length - 1 ? "+" : "-"}
                                </button>
                                <Input
                                  name="dependentearray"
                                  value={item}
                                  onChange={(e) => {
                                    handleTipoValue({
                                      item: index,
                                      val: e.target.value
                                    });
                                  }}
                                  className="form-control"
                                  type="number"
                                  placeholder="Idade"
                                />
                              </div>
                            </div>
                          </Col>
                        </>
                      ))}
                    </Row>
                  </>
                ) : tipo == "2" ? (
                  <>
                    <Col>
                      <div>
                        <div className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            className="form-Label"
                          >
                            Faixas{" "}
                          </Label>
                          <Row>
                            {faixas &&
                              faixas.map((item , index ) => (
                                <Col key={index}>
                                  <div>
                                    <div className="mb-3">
                                      <Label
                                        htmlFor="example-text-input"
                                        className="form-Label"
                                      >
                                        {item.nome}
                                      </Label>
                                      <Input
                                        name="faixas"
                                        value={item.value}
                                        onChange={(e) => {
                                          handleFaixasValue(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        className="form-control"
                                        type="number"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              ))}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : ramo == "1" ? (
              <>
              
                <Col lg={6}>
                  <div>
                    <div className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Titular
                      </Label>
                      <Input
                        name="titular"
                        disabled={tipo == "1" ? false : true}
                        value={validation.values.titular}
                        onChange={validation.handleChange}
                        className="form-control"
                        type="number"
                        placeholder={tipo == "1" ? "" : "1"}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <div className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Dependentes
                      </Label>
                      <Input
                        name="dependentes"
                        value={validation.values.dependentes}
                        onChange={validation.handleChange}
                        placeholder="Quantidade"
                        className="form-control"
                        type="number"
                      />
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              ""
            )}

            <Col lg={12}>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loading}
                style={{ margin: 20,float:'right',backgroundColor:'#1b2949'}}
              >
                Salvar
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loading}
                onClick={() => {
                  setSalvarVisualizar(true);
                }}
                style={{ margin: 20,float:'right',backgroundColor:'#1b2949' }}
              >
                Salvar e Visualizar
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
export default Editar;
