import React from "react";
import Layouts from "../Corpo/Layouts";
import Dados from "./index";

export default function Blog() {
  return (
    <Layouts
      pageTitle="Comunicados"
      breadcrumbs={[
        { name: "Inicio", path: "/" },
        { name: "Comunicados", path: "/comunicados" }
      ]}
    >
      <Dados />
    </Layouts>
  );
}
