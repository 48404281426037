import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import MainSection from "./utils/listagem";
import Icone from "../../assets/icones/icone.png"
function Operadoras({ramo}) {
  const [datas, setDatas] = useState([]);
 
  const handleOperadora = async () => {
    setDatas([]);
    let list = await Api.operadoraSeguro({estado:5});
    if (list?.list?.length > 0) {
      let listagem = []
      let operadoras = []
      list.list.map((item) => {
        if (!listagem.includes(item.arquivo)) {
          listagem.push(item.arquivo)
          operadoras.push(item)
        }
      })
      setDatas(operadoras)
    }
  
    // setDatas(list.list);

  };
  useEffect(() => {
    handleOperadora();
   
  }, []);

  return (
    <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{minHeight:700}}>
      <div className="container" style={{maxWidth:'1502px'}}>
     
        {datas.length>0 && <MainSection datas={datas} ramo={ramo} />}
        {!datas.length>0 && (
           <div  className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299,display:'flex',justifyContent:'center',alignItems:'center' }}
           >
              <img src={Icone} className="rotating-image" />
           </div>
        )}

        <div className="feature-btn text-center mt-20"></div>
      </div>
    </section>
  );
}

export default Operadoras;
