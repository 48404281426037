import React, { useEffect, useState } from "react";
import { Api } from "../../api";

export default function MainSection() {
  const [listOriginal, setListOriginal] = useState();
  const [list, setList] = useState();
  const [ultimas, setUltimas] = useState();
  const [escolha, setEscolha] = useState();
  const [categorias, setCategorias] = useState();
  const getAuthUser = localStorage.getItem("authUser");
  const obj =
  getAuthUser == "undefined" ||
  getAuthUser == null ||
  getAuthUser == "[object Object]"
    ? ""
    : JSON.parse(getAuthUser ? getAuthUser : "");
  useEffect(()=>{
    const obj =
    getAuthUser == "undefined" ||
    getAuthUser == null ||
    getAuthUser == "[object Object]"
      ? ""
      : JSON.parse(getAuthUser ? getAuthUser : "");

  },[obj])
  const handleDados = async () => {
    let json = await Api.blogs();
    let list = json?.list.list
  
    setListOriginal(list)
    let categorias =json?.list.categorias
    list = list?.filter((item)=> !obj.id?item.tipo==1:1==1)
    if (list?.length > 0) {
      setList(list);
      setUltimas(list.filter((item,index)=>index<=2))
    }
    if (categorias.length > 0) {
      setCategorias(categorias);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  useEffect(() => {
    handleVisualizar();
  }, [list]);
  const handleVisualizar = async () =>{
    let id = localStorage.getItem("id");
    if(id){
      id = JSON.parse(id)
    }
    list?.map( async (item)=>{
      await Api.Visualizacao({iduser:id,idblog:item.id})
    })
  }
  const handleBuscar = async (buscar) =>{
    let listagem = listOriginal
    listagem = listagem.filter((item)=>item.categoria.id==escolha && item.titulo.includes(buscar))
  
    setList(listagem)

  }
  const handleCategoria = async (id) =>{
    let list = listOriginal
    setEscolha(id)
    list = list.filter((item)=>item.categoria.id==id)
    setList(list)
  }
  const handleZerar = async () =>{
    let list = listOriginal
    setEscolha('')
    setList(list)
  }

  
  return (
    <section className="blog-page-area bg-lighter">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="blog-standard-content pt-120 rpt-100 pb-110 rpb-90 pr-15 rpr-0">
              {list &&
                list.map((item) => (
               <>
                  <div className="blog-standard-item wow fadeInUp delay-0-2s">
                    <div className="image">
                      {item?.imagem && (
                        <img
                          src={`https://server.corpar.com.br/imagem/${item?.imagem}`}
                        
                          width="1000" 
                        />
                      )}
                      {item?.video && (
                        <video width="280" height="220" controls>
                          <source
                            src={`https://server.corpar.com.br/video/${item?.video}`}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </div>

                    <div className="blog-header">
                      <ul className="post-meta-item mr-15">
                        <li>
                          <i className="fas fa-calendar-alt"></i>
                          <a href="#">{item?.datainicio}</a>
                        </li>
                      </ul>
                    </div>
                    <h3>
                      <a href="/blog/blog-details">{item?.titulo}</a>
                    </h3>
                    <p>{item?.descricao}</p>
                  </div>
               </>
                ))}
            </div>
          </div>
          <div className="col-lg-3 col-sm-9">
            <div className="blog-sidebar mt-120 rmy-100">
              <div className="widget widget-search wow fadeInUp delay-0-2s">
                <form action="#">
                  <input
                    type="text"
                    placeholder="Buscar..."
                    className="searchbox"
                    required
                    onChange={(e)=>{handleBuscar(e.target.value)}}
                  />
                  <button
                    type="submit"
                    className="searchbutton fa fa-search"
                  ></button>
                </form>
              </div>
              <div className="widget widget-recent-post wow fadeInUp delay-0-4s">
                <h3 className="widget-title">Ultimas noticias</h3>
                <div className="widget-news-wrap">
                  {ultimas &&
                    ultimas.map((item) => (
                   <>
                      <div className="widget-news-item">
                        {item?.imagem && (
                          <img
                            src={`https://server.corpar.com.br/imagem/${item?.imagem}`}
                            alt="Blog Standard"
                          />
                        )}
                        {item?.video && (
                          <video width="320" height="240" controls>
                            <source
                              src={`https://server.corpar.com.br/imagem/${item?.video}`}
                              type="video/mp4"
                            />
                          </video>
                        )}
                        <div className="widget-news-content">
                          <h5>
                            <a href="/blog/blog-details">{item.resumo}</a>
                          </h5>
                          <span className="date">
                            <a href="#"> {item.datainicio}</a>
                          </span>
                        </div>
                      </div>
                   </>
                    ))}
                </div>
              </div>

              <div className="widget widget-tag-cloud wow fadeInUp delay-0-2s">
                <h3 className="widget-title">Categorias</h3>
                <div className="tags">
                  {categorias &&
                    categorias.map((item) => <><a href="#" style={ {...(escolha==item.id ? {backgroundColor:'#1B2949',color:'#fff'}:{backgroundColor:'#eef3f9',color:'#ccc'})}} onClick={()=>{escolha==item.id?handleZerar():handleCategoria(item.id)}}>{item.nome}</a></>)}
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
