export const baseUrlApi = `${process.env.REACT_APP_ENVIROMENT == "DEV" ? `${process.env.REACT_APP_URL_DEV}` : `${process.env.REACT_APP_URL}`}`
export const baseUrlApp = `${process.env.REACT_APP_ENVIROMENT == "DEV" ? `${process.env.REACT_APP_SITE_DEV}` : adicionarSubdominio(`${process.env.REACT_APP_SITE}`) }`
export const baseUrlSite = `${process.env.REACT_APP_ENVIROMENT == "DEV" ? `${process.env.REACT_APP_SITEWEB_DEV}` : adicionarSubdominio(`${process.env.REACT_APP_SITEWEB}`)}`

function adicionarSubdominio(subdominio) {
    // Pegar a URL atual
    const urlAtual = window.location.href;

    // Criar uma URL object para fácil manipulação
    const urlObj = new URL(urlAtual);

    // Pegar o hostname (domínio)
    const hostnameAtual = urlObj.hostname;

    // Dividir o hostname por pontos para tratar diferentes partes do domínio
    const partesDomino = hostnameAtual.split('.');

    // Verificar se já existe um subdomínio, caso exista, removê-lo
    if (partesDomino.length > 2) {
        partesDomino.shift(); // Remove o subdomínio existente
    }
    // console.log({hostnameAtual})
    // Adicionar o novo subdomínio
    const novoHostname = hostnameAtual.includes("localhost")?'http://localhost:3000': `https://${subdominio?subdominio+'.':''}${hostnameAtual}`;

    // Atualizar o hostname da URL
    urlObj.hostname = novoHostname;

    // Redirecionar para a nova URL com o subdomínio adicionado
    return novoHostname

}