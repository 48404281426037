import React from "react";
import Dados from "./index";
import Layouts from "../Corpo/Layouts";

export default function AboutThree() {
  return (
    <>
      <Layouts pageTitle="Cotações"   breadcrumbs={[
      { name: "Home", path: "../" },
      {name:'Ferramentas de Vendas',path:'/ferramentas-de-venda'},
      { name: "Cotações", path: "/cotacoes" },
    ]}>
        <Dados />
      </Layouts>
    </>
  );
}
