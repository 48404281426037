import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineFile, AiOutlineEye } from 'react-icons/ai'; // Importe os ícones desejados
import { FaRegTrashCan } from "react-icons/fa6";

const FileInputComponent = ({ handleChange, name, handleExit, setName }) => {
    const fileInputRef = useRef(null);
    const [arquivo, setArquivo] = useState('Selecione')
    useEffect(() => {
        setArquivo("Selecione")
    }, [name])
    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile?.name) {
            handleExit(false)
            setArquivo("Selecionado")
            handleChange(event)
        } else {
            setArquivo("Selecione")
            handleChange({})
        }
    };
    const handleViewFile = () => {
        handleExit(true)
        if (fileInputRef) {
            if(fileInputRef?.value){
                fileInputRef.value = null
            }
        }
        setArquivo("Selecione")
        setName('')
        //fileInputRef.current=null
    };
    const handleBlur = (event) => {
        handleFileChange(event)
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'inline-flex', alignItems: 'center', padding: '10px', backgroundColor: 'transparent', color: 'black', borderRadius: (name) ? '5px 0px 0px 5px' : '5px', width: '100%', cursor: 'pointer', border: '1px solid #E5E5E5' }}
                onClick={handleClick} >
                <div style={{ borderRight: '1px solid #333', paddingRight: '8px' }} >
                    <AiOutlineFile size={20} />
                </div>
                <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', paddingLeft: 10 }} >{(name) ? "Arquivo" : arquivo ? arquivo : "Selecione"} </span>
                <input type="file" onBlur={handleBlur} ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            </div>

            {(name != '' || arquivo == 'Selecionado' || arquivo == 'Arquivo' ) &&
                <div style={{ display: 'inline-flex', alignItems: 'center', padding: '10px', backgroundColor: 'transparent', color: 'black', borderRadius: '0px 5px 5px 0px ', cursor: 'pointer', border: '1px solid #E5E5E5' }} onClick={handleViewFile} >
                    <div style={{ paddingRight: '8px', padding: 1 }} >
                        <FaRegTrashCan size={20} />
                    </div>
                </div>
            }



        </div>
    );
};

export default FileInputComponent;
