import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Modal, Row } from "reactstrap";
import Info from "./info";
export default function MainSection({ datas }) {
  const [isotope, setIsotope] = useState(null);
  const [filterKey, setFilterKey] = useState("fisica");
  const [escolha, setEscolha] = useState("");
  const [dados, setDados] = useState([]);
  const { estado, plano } = useParams();
  const [modalAviso, setModalAviso] = useState(false);
  const handleFiltro = (val) => {
    if (val == "PF") {
      let fisica = datas.valores.filter((item) => item.filter == "PF");
      setDados(fisica);
      setEscolha("PF");
    }
    if (val == "PJ") {
      let juridico = datas.valores.filter((item) => item.filter == "PJ");
      setDados(juridico);
      setEscolha("PJ");
    }
  };
  const handleDados = () => {
    handleFiltro();
  };
  useEffect(() => {

    handleDados();
  }, [datas]);
  const [link, setLink] = useState("");
  const handleAviso = (item) => {
    setModalAviso(true);
    setLink(item.link);
  };
  const [modalMaterial, setModalMaterial] = useState(false);
  const [id, setId] = useState("");
  const handleMaterial = (id) => {
    setModalMaterial(true);
    setId(id);
  };
  return (
    <section className="portfolio-section pt-10 rpt-95 pb-90 rpb-70">
      <div className="container">
        <ul className="portfolio-filter mb-45" style={{listStyleType:'none'}}>
          <li
            onClick={() => handleFiltro("PF")}
            className={escolha === "PF" ? "current" : ""}
            style={{ fontFamily: "Open Sans, sans-serif",  border:'1px solid #1b2949' }}
          >
            Pessoa Física
          </li>
          <li
            onClick={() => handleFiltro("PJ")}
            className={escolha === "PJ" ? "current" : ""}
            style={{ fontFamily: "Open Sans, sans-serif",  border:'1px solid #1b2949' }}
          >
            Pessoa Jurídica
          </li>
        </ul>
        <div className="row align-items-center  portfolio-wrap">
          {dados &&
            dados.length > 0 &&
            dados.map((item) => (
              <a
                key={item.id}
                className={`col-lg-3 col-sm-6 item `}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="feature-item   style-three "
                  style={{ cursor: "pointer",height:280 }}
                >
                  <div
                    className="feature-content style-three"
                    style={{
                      textAlign: "center",
                      marginTop: "auto",
                      height:130
                    }}
                    onClick={() => {
                      setLink("");
                      handleAviso(item);
                    }}
                  >
                    <img
                      src={`https://server.corpar.com.br/imagens/${item.image}`}
                      alt=""
                  
                      height={100}
                    />
                  </div>
                  <div
                    className="row"
                    style={{
                      borderTop: "1px solid #ccc",
                   
                    }}
                  >
                    <div className="col-md-12" style={{ textAlign: "center",   marginTop:10,height:50 }}>
                      <p>
                        <span style={{ marginLeft: 10,fontSize:12 }}> {item.title}</span>
                      </p>
                    </div>
                    <div className="col-md-12" style={{ textAlign: "center",fontSize:12  }}>
                      <a
                      className="btn btn-default"
                        onClick={() => {
                          handleMaterial(item.id);
                        }}
                        style={{ color: "#fff",backgroundColor:'#1B2949',fontSize:12,marginTop:10 }}
                      >
                        Material de Vendas
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          <Modal
            id="modal-center3"
            size="md"
            centered={true}
            isOpen={modalAviso}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Aviso</h5>
              <button
                type="button"
                onClick={() => {
                  setModalAviso(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 30 }}>
              <Row>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span style={{ fontSize: 18 }}>
                    É necessário baixar o App, ter usuário, senha e receber
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span style={{ fontSize: 18 }}>as orientações&nbsp;</span>
                  <span style={{ fontSize: 18 }}>
                    para utilizar este sistema de vendas online.
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span style={{ fontSize: 18 }}>
                    <br />
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span style={{ fontSize: 18 }}>
                    Precisa de orientação ou não possui senha?
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    textAlign: "center",
                    lineHeight: 1
                  }}
                >
                  <span
                    style={{
                      color: "rgb(206, 0, 0)",
                      fontFamily: "inherit",
                      fontSize: 18
                    }}
                  >
                    Clique no botão abaixo&nbsp;
                  </span>
                  <span
                    style={{
                      color: "rgb(206, 0, 0)",
                      fontFamily: "inherit",
                      fontSize: 18
                    }}
                  >
                    que entraremos em contato.
                  </span>
                </p>
              </Row>
              <Row style={{ float: "right", marginTop: 20 }}>
                <a
                  href={link}
                  id="link"
                  className="btn btn-success"
                  style={{ float: "right" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Continuar
                </a>
              </Row>
              <Row style={{ float: "left", marginTop: 20 }}>
                <button className="btn btn-warning" style={{ float: "left" }}>
                  Solicitar contato
                </button>
              </Row>
            </div>
          </Modal>
          <Modal
            id="modal-center3"
            size="md"
            centered={true}
            isOpen={modalMaterial}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Material de Venda</h5>
              <button
                type="button"
                onClick={() => {
                  setModalMaterial(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 30 ,backgroundColor:'#f5f5f5'}}>
              <Info id={id} estado={datas.estado} />
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
}
