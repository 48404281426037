import React from "react";
import FooterHomeTwo from "../footer";
import HeaderHomeOne from "../Header";
import Hero from "./Hero";

export default function Layouts({ children, pageTitle, breadcrumbs = [] }) {
  return (
    <>
      <HeaderHomeOne />

      <Hero pageTitle={pageTitle} breadcrumbs={breadcrumbs} />
      {children && children}

      <FooterHomeTwo />
    </>
  );
}
