import React, { useState } from "react";
import { UncontrolledAlert, Col, Row, Label, Input, Modal } from "reactstrap";
import { Api } from "../../../api/apiCotacoes";

const Agendar = ({ modalAgendar, setModalAgendar, handleLimpar, id }) => {
    const [obs, setObs] = useState('')
    const [data, setData] = useState('')
    const [resposta, setResposta] = useState(false)
    const [error, setError] = useState(false)
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const handleAgendar = async () => {
        setLoading(true)
        let cadastrar = await Api.agendar({ obs, data, idcotacao: id })
        if (cadastrar?.error) {
            alert(cadastrar.msg)
        } else {
            alert('Agendamento Realizado')
            setData('')
            setObs('')
        }
        setLoading(false)
    }

    return (
        <Modal id="modal-center3" size="md" isOpen={modalAgendar} centered={true} >
            <div className="modal-header">
                <p className="modal-title mt-0">Agendar Contato</p>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={() => {
                        setModalAgendar(false);
                       
                    }} >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20, marginTop: 25 }}>
                <Row>

                    <Col lg={12}>
                        {resposta &&
                            <UncontrolledAlert toggle={() => { setResposta(false) }} color={error ? 'danger' : 'success'}>{error ? <>
                                <h5 className="text-danger"><i className="mdi mdi-block-helper    text-danger"></i> Error</h5></> : <>
                                <h5 className="text-success"><i className="mdi mdi-check-all text-success"></i> Sucesso</h5></>}{msg}</UncontrolledAlert>
                        }
                    </Col>
                    <Col lg={12}>
                        <div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">Observação <small>(Opcional)</small></Label>
                                <Input name="descricao" placeholder="Opcional" value={obs} onChange={(e) => { setObs(e.target.value) }} className="form-control" type="text" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">Data </Label>
                                <Input name="data" value={data} onChange={(e) => { setData(e.target.value) }} className="form-control" type="date" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <button className="btn btn-primary" type="button" onClick={() => { handleAgendar() }} disabled={loading} >Salvar</button>
                    </Col>
                </Row>
            </div>

        </Modal>
    )
}
export default Agendar