import React, { useEffect, useState } from "react";
import BackToTop from "../../lib/BackToTop";
import { FaWhatsapp } from "react-icons/fa";
import bgImage from '../../assets/images/bg-whatsapp.jpg';
import logo from '../../assets/images/logos/logo3.png';

export const AreaModal = ({ title,setNotificacao,notificacao }) => {
  return (
    <div style={{ position: 'absolute', borderRadius: 6, width: 120, top: '-50px', left: -80, backgroundColor: 'white', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', color: '#000', height: 50, verticalAlign: 'middle', display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >
      
       
      <p style={{ color: '#000', opacity: '1' }} dangerouslySetInnerHTML={{ __html: title }}></p>
      <span aria-hidden="true" onClick={() => { setNotificacao(!notificacao) }} style={{ color: '#000', fontSize: 20,position:'absolute',right:0,top:0 ,marginRight:5}} >&times;</span>
    </div>
  )
}
function BacktoTopCom({ className }) {

  useEffect(() => {
    BackToTop(".scroll-top");
  });

  let minutosPassados = 0;

  // Função que será chamada a cada 10 minutos
  const [notificacao, setNotificacao] = useState(false);

  useEffect(() => {
    // Função para exibir o alerta
    const mostrarAlerta = () => {
      setNotificacao(true);
    };

    // Mostra a notificação imediatamente ao entrar na página
    mostrarAlerta();

    // Após a primeira notificação, configurar para repetir a cada 2 minutos
    const intervalId = setInterval(mostrarAlerta, 120000);

    // Limpeza do intervalo quando o componente desmontar
    return () => clearInterval(intervalId);
  }, []);

  const [card, setCard] = useState(false)
  return (
    <>

      {card &&


        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: "fixed", bottom: "80px", right: '30px', Zindex: "99", width: '400px', height: '300px', color: 'white', cursor: 'pointer', boxShadow: "0px 0px 20px #8e8e8e", borderRadius: 8 }}>
          <div style={{ flex: 1, backgroundColor: '#295f54', display: 'flex', borderTopLeftRadius: 8, borderTopRightRadius: 8 }} >
            <div style={{ flex: 1, marginLeft: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <img src={logo} />
            </div>
            <div style={{ flex: 2, marginLeft: 20, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
              <span style={{ fontWeight: '600', fontSize: 20 }} >Corretor Parceiro</span>
              <span style={{ fontSize: 12 }} > Responde em alguns minutos</span>
            </div>
            <span aria-hidden="true" onClick={() => { setCard(!card) }} style={{ color: '#fff', fontSize: 25, marginRight: 10 }} >&times;</span>
          </div>
          <div style={{ flex: 1, background: `url(${bgImage}) center top no-repeat` }}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', backgroundColor: '#fff', borderRadius: 10, padding: '1px 10px', margin: "30px 70px 10px 0", fontSize: 15, fontWeight: 600, wordWrap: 'break-word', boxShadow: "rgba(0, 0, 0, 0.13) 0px 1px 0.5px", textAlign: 'left', marginLeft: 10 }}>
              <span style={{ color: '#777', fontSize: 14, display: 'block' }}></span>
              <span style={{ fontSize: 14, display: 'block', color: '#777' }}>
                Corretor Parceiro
              </span>
              <span style={{ fontSize: 14, display: 'block', color: '#444' }}>
                Olá! 👋 <br />
                Tem dúvidas? Fale conosco no WhatsApp.
              </span>
              <span style={{ fontSize: 14, color: '#888', textAlign: 'right', width: '100%' }}>
                agora
              </span>

            </div>

          </div>
          <div style={{ backgroundColor: '#fff', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>

            <button type="button" className={` ${className || ""}`}
              style={{ width: '90%', height: '40px', color: 'white', cursor: 'pointer', borderRadius: '20px', backgroundColor: '#25D366', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FaWhatsapp size={30} />
              <span style={{ marginLeft: 10 }}>
                Chamar no Whatsapp
              </span>

            </button>

          </div>
        </div>
      }
      <div style={{width:'100%'}}>
        <button type="button"
          style={{ position: "fixed", bottom: "30px", right: '30px', Zindex: "99", width: '50px', height: '50px', color: 'white', cursor: 'pointer', borderRadius: '50%', backgroundColor: '#25D366', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {card ?
            <span aria-hidden="true" style={{ color: '#fff', fontSize: 25 }} >&times;</span>
            :
            <FaWhatsapp size={30}  onClick={() => { setCard(!card) }} className={` ${className || ""}`} />
          }
          {notificacao &&   <AreaModal title={"Quer ajuda?"} notificacao={notificacao} setNotificacao={setNotificacao} /> }
          
        </button>
     
      </div>



    </>

  );
}

export default BacktoTopCom;
