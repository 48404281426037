import React, { useEffect, useState } from "react";
import { Modal, Row } from "reactstrap";
import { Api } from "../../../api";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Info from "./info";
export default function MainSection({  id,setDetalhes }) {
  const [dados, setDados] = useState([]);
  const [fornecedor, setFornecedor] = useState([])

  useEffect(() => {
    handleRamoPai()
    setItem(id)
  }, []);
  const [modalRamo, setModalRamo] = useState(false);
  const [ramo, setRamo] = useState(false);
  const [dadosNew, setDadosNew] = useState(false);
  const [pai, setPai] = useState();
  const [escolha, setEscolha] = useState(1);
  const [carregado, setCarregado] = useState(false);

  const handleRamoPai = async () => {
    let response = await Api.ramosPaiAtuacao()
    setPai(response.list);
  }
  const [item2, setItem] = useState("");
  const handler = (id) => {
    if(Number(id)==Number(item2)){
      setItem('')
    }else{
      setItem(id)
    }
  };
  useEffect(()=>{
    let dados = pai?.find((item)=>Number(item.id)==Number(item2))
    setDetalhes(dados)
    setTimeout(()=>{
      let compoment =  document.querySelector(`a[id="${id}"] .sub-menu .feature-item`)
      if(compoment){ 
        if(compoment.style.display!="none"){
          let focar = document.querySelector(`a[id="${id}"]`)
          if(focar){
            focar.focus()
            focar.scrollIntoView({ behavior: 'smooth', block: "center" });
          }
        }
      }
    }, 2500);

    
  
 
  },[item2,pai])
  return (
    <section className="portfolio-section pt-50 rpt-95 pb-90 rpb-70">
      <div className="container" >

        <div className=" align-items-center  portfolio-wrap" style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

          {pai?.length > 0 && pai.map((item,index) => (
            <a
            tabIndex={-1}
              id={item.id}
              key={item.id}
              className={`col-lg-6 col-sm-6 item `}
              style={{ cursor: "pointer",marginBottom:0 }}
            >
              <div
                className="feature-item   style-three "
                style={{ padding: 0 }}
              >
                <div className="feature-content style-three" >
                  <Link className="menu-item-has-children active" style={{ fontFamily: "Open Sans, sans-serif", fontSize: 15 ,display:'flex',justifyContent:'space-between',alignItems:'center'}} onClick={(e) => handler(item.id)} id={"ramos" + item.id} >
                    <div style={{   overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <img
                        
                        style={{
                          width: "70px",
                          marginRight: "5px",
                          textAlign: 'left'
                        }}
                        src={`https://server.corpar.com.br/imagens/${item.imagem}`}
                        alt="Testimonial"
                      />
                      <a href="#" >
                        {item.nome}
                      </a>
                    </div>
                    <div style={{ marginRight:20}}>
                    {item.id == item2 ?
                            <FaAngleRight />
                            :
                            <FaAngleDown />
                          }
                    
                    </div>
                  </Link>
                  <div
                    className="sub-menu"
                    style={{
                      display:
                      item.id == item2 ? "block" : "none"
                    }}
                  >
                  
                  <Info id={item.id} /> 
                  </div>
                </div>
              </div>
            </a>
       
          ))}


          


        </div>
      </div>
      <Modal
        id="modal-center3"
        size="md"
        isOpen={modalRamo}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Ramos de Atuação</h5>
          <button
            type="button"
            onClick={() => {
              setModalRamo(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">

          <Row style={{ padding: 5, height: '100%' }}>

            {dadosNew?.operadoraslinks && dadosNew?.operadoraslinks.length > 0 && dadosNew?.operadoraslinks.map((item2, index) => (
              <>
                <div className="col-6" style={{ textAlign: "center", fontSize: 12 }}>
                  <a href={item2.link} rel="noreferrer" target="_blank" >
                    <img
                      src={`https://server.corpar.com.br/imagens/${item2.tipooperadora?.imagem}`}
                      alt=""

                      width={'80%'}
                    />
                  </a>

                  <a href={item2.link} rel="noreferrer" target="_blank" > <p style={{ marginTop: '-10px' }}>{item2?.tipooperadora?.nome}</p></a>
                </div>
              </>
            ))}

            {!dadosNew?.operadoraslinks || dadosNew?.operadoraslinks.length == 0 && dadosNew?.tiposoperadoras.map((item2, index) => (
              <>
                <div className="col" style={{ textAlign: "center", fontSize: 12 }}>
                  <a href={dadosNew?.linkvenda} rel="noreferrer" target="_blank" >
                    <img
                      src={`https://server.corpar.com.br/imagens/${item2.tipooperadora?.imagem}`}
                      alt=""

                      width={'40%'}
                    />
                  </a>

                  <a href={dadosNew?.linkvenda} rel="noreferrer" target="_blank" ><p style={{ marginTop: '-10px' }}>{item2?.tipooperadora?.nome}</p></a>
                </div>
              </>
            ))}

          </Row>
        </div>
      </Modal>
    </section>
  );
}
