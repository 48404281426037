import React, { useEffect, useRef, useState } from "react";

import {
  Alert,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row
} from "reactstrap";
import { BiBook, BiBookOpen, BiCollapse, BiEdit, BiShow, BiWrench, BiHide } from "react-icons/bi";
//redux
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// Formik Validation
import { useFormik } from "formik";

// import images
import { Api } from "../../../../api/apiLogin";

const Esqueci = ({ modalEsqueci, setModalEsqueci }) => {
  const { tokenUrl } = useParams();
  const [usuario, setUsuario] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [codigo1, setCodigo1] = useState("");
  const [codigo2, setCodigo2] = useState("");
  const [codigo3, setCodigo3] = useState("");
  const [codigo4, setCodigo4] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [modalSenha, setModalSenha] = useState(false);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const [isValid, setIsValid] = useState(false);
  const [id,setId] = useState(0)
  const [tokenEnviado, setTokenEnviado] = useState(false)
  function handleInputChange(e) {
    const maxLength = e.target.maxLength;
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength) {
      e.preventDefault();
      if (e.target === input1Ref.current) {
        input2Ref.current.focus();
      } else if (e.target === input2Ref.current) {
        input3Ref.current.focus();
      } else if (e.target === input3Ref.current) {
        input4Ref.current.focus();
      } else if (e.target === input4Ref.current) {
        // aqui você pode adicionar o código para passar para o próximo campo de texto
      }
    }
  }
  const [handleHide, setHandleHide] = useState([]);
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleEnviarToken = async () => {
    setLoading(true);
    let enviar = await Api.disparoValidacao({ usuario });
    
    if (enviar.error) {
      setMensagem(enviar.msg);
      setError(true);
    } else {
      setTokenEnviado(true)
      setMensagem(enviar.msg);
      setError(false);
    }
    setLoading(false);
  };
  const handleValidar = async () => {
    setLoading(true);
    let enviar = await Api.Validar({ token: `${codigo1}${codigo2}${codigo3}${codigo4}` });
    // console.log(enviar)
    if (enviar.error) {
      setMensagem(enviar.msg);
      setError(true);
    } else {
      setTokenEnviado(false)
      setMensagem(enviar.msg);
      setError(false);
      setModalEsqueci(false);
      setModalSenha(true);
      setId(enviar.id);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (tokenUrl) {
      //  setToken(tokenUrl);
      // handleValidar(tokenUrl);
    }
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: ""
    }
  });

  const validatePassword = () => {

    const regex = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8}$/;
    setIsValid(!regex.test(password));
  };
  const handleShowHide = async (type, status, index) => {
    if (status) {
      document.querySelector(`input[id="password${type}"]`).type = 'text'
    } else {
      document.querySelector(`input[id="password${type}"]`).type = 'password'
    }
    let valores = [...handleHide]
    valores[index] = !valores[index]
    setHandleHide(valores)
  }
  document.title = "Corretor Parceiro";
  const handleValidarSenha = async () => {
    setLoading(true);
    if (password == passwordConfirm && !isValid) {
      let valida = await Api.validarSenha({ id: id, password: password });

      if (valida.error == false) {
        setCodigo1("");
        setCodigo2("");
        setCodigo3("");
        setCodigo4("");
        setModalSenha(false);
        alert("Senha alterada!");
      } else {
        alert(valida.msg);
      }
    } else {
      alert("As senhas precisam ser iguais e obedecerem a regra");
    }

    setLoading(false);
  };
  return (
    <>
    <Modal id="modal-center3" size="md" centered={true} isOpen={modalEsqueci}>
      <div className="modal-header" style={{ border: 'none' }} >
        <button
          type="button"
          onClick={() => {
            setModalEsqueci(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }} >

        <div className="auth-page">
          <div className="text-center">
            <p className="" style={{ color: '#1b2949', fontSize: 20 }}>
              Recuperar Senha
            </p>
          </div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={12} md={12} className="col-xxl-12">
                <div className="auth-full-page-content d-flex p-sm-2 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="auth-content my-auto">
                        {error && mensagem ? (
                          <Alert color="danger" style={{ marginTop: "13px",textAlign:'center' }}>
                            {mensagem}
                          </Alert>
                        ) : null}
                        {mensagem && !error ? (
                          <Alert color="success" style={{ marginTop: "13px",textAlign:'center' }}>
                            {mensagem}
                          </Alert>
                        ) : null}

                        {!tokenEnviado ?
                          <>
                            <div className="mb-1">
                              <Label className="form-label">Usuário</Label>
                              <Input name="email" className="form-control" placeholder="CPF OU CNPJ" type="email" onChange={(e) => { setUsuario(e.target.value); }} onBlur={validation.handleBlur} value={usuario}
                              />

                            </div>

                            <Row className="mb-4" style={{ marginTop: 20 }} >
                              <Col className="text-end">
                                <button className="btn btn-primary w-100 waves-effect waves-light" type="button" onClick={() => { handleEnviarToken(); }} disabled={loading}  >
                                  Recuperar
                                </button>
                              </Col>
                            </Row>
                          </>
                          :
                          <>
                            <p style={{ textAlign: 'center' }}>Digite o código que enviamos por sms ou Whatsapp.</p>
                            <Row style={{display:'flex', justifyContent: 'center', alignItems: 'center' ,flexDirection:'row'}}>
                              <Col lg={2} style={{flex:1}}>
                                <input className="form-control" value={codigo1} ref={input1Ref}
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    setCodigo1(e.target.value);
                                  }}
                                  style={{ textAlign: "center" }}
                                  maxLength={1}
                                      type="number"
                                />
                              </Col>
                              <Col lg={2} style={{flex:1}}>
                                <input className="form-control" value={codigo2} ref={input2Ref}
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    setCodigo2(e.target.value);
                                  }}
                                  style={{ textAlign: "center" }}
                                  maxLength={1}
                                  type="number"
                                />
                              </Col>
                              <Col lg={2} style={{flex:1}}>
                                <input className="form-control" value={codigo3} ref={input3Ref} onChange={(e) => {
                                  handleInputChange(e);
                                  setCodigo3(e.target.value);
                                }}
                                  style={{ textAlign: "center" }}
                                  maxLength={1}
                                      type="number"
                                />
                              </Col>
                              <Col lg={2} style={{flex:1}}>
                                <input className="form-control" value={codigo4} ref={input4Ref} onChange={(e) => {
                                  handleInputChange(e);
                                  setCodigo4(e.target.value);
                                }}
                                  style={{ textAlign: "center" }}
                                  maxLength={1}
                                      type="number"
                                />
                              </Col>


                            </Row>
                            <Row>
                              <Col lg={12} style={{marginTop:20}} >
                                <button className="btn btn-primary w-full" style={{width:'100%'}} disabled={loading} onClick={() => { handleValidar(); }}  >
                                  Validar
                                </button>
                              </Col>
                            </Row>
                          </>
                        }


                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Modal>
     <Modal isOpen={modalSenha} centered={true}>
     <div className="modal-header" style={{ border: 'none' }} >
       <button type="button" onClick={() => { setModalSenha(false); }}
         className="close"
         data-dismiss="modal"
         aria-label="Close"
         style={{ float: 'right' }}
       >
         <span aria-hidden="true">&times;</span>
       </button>
     </div>

     <div className="modal-body" style={{padding:20}}>
     <p className="" style={{ color: '#1b2949',textAlign:'center', fontSize: 20 }}>

         Crie uma nova senha
       </p>

       <Row>
         <Col lg={6}>
           <div className="row"  >
             <div className="col-12" >
               <Label htmlFor="example-text-input" className="form-Label">
                 Nova senha
               </Label>
               <div style={{ paddingRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                 <div style={{ flex: 2 }}>
                   <Input id="password1" name="password" autocomplete="new-password" onBlur={() => { validatePassword(); }} value={password} onChange={handlePasswordChange} className="form-control" type="password" />
                 </div>
                 <div style={{ border: '2px solid #cfdbf1', padding: 4, borderRadius: '5px' }} >
                   {handleHide[1] ? <BiShow size={20} onClick={() => { handleShowHide('1', false, 1) }} /> : <BiHide size={20} onClick={() => { handleShowHide('1', true, 1) }} />}
                 </div>
               </div>
             </div>
           </div>



         </Col>
         <Col lg={6}>
           <div className="row"  >
             <div className="col-12" >
               <Label htmlFor="example-text-input" className="form-Label">
                 Confirmar senha
               </Label>
               <div style={{ paddingRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                 <div style={{ flex: 2 }}>
                   <Input id="password2" name="passwordConfirm" autocomplete="new-password" value={passwordConfirm} onChange={(e) => { setPasswordConfirm(e.target.value); }} className="form-control" type="password" />
                 </div>
                 <div style={{ border: '2px solid #cfdbf1', padding: 4, borderRadius: '5px' }} >
                   {handleHide[2] ? <BiShow size={20} onClick={() => { handleShowHide('2', false, 2) }} /> : <BiHide size={20} onClick={() => { handleShowHide('2', true, 2) }} />}
                 </div>

               </div>
             </div>

           </div>

           <div>

           </div>
         </Col>
         <Col lg='12'>
         <p style={{ color: "red", textAlign: 'center' ,marginTop:20}}>
               Sua senha deve conter 8 dígitos <br/> com números e letras
             </p>
         </Col>

       </Row>
     </div>

     <div className="modal-footer" style={{border:'none',marginTop:'-10px',display:'flex',alignItems:'center',justifyContent:'center'}}>
       <button
         className="btn btn-primary"
         disabled={loading}
         style={{width:160}} 
         onClick={() => {
           handleValidarSenha();
         }}
       >
         Salvar
       </button>
     </div>
   </Modal>
   </>
  );
};

export default Esqueci;
