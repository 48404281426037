import axios from "axios";
import { baseUrlApi } from './baseUrl'
const getAuthUser = localStorage.getItem("authUser");
const obj =
  getAuthUser == "undefined" ||
  getAuthUser == null ||
  getAuthUser == "[object Object]"
    ? ""
    : JSON.parse(getAuthUser ? getAuthUser : "");
const axiosInstance = axios.create({
  baseURL: `${baseUrlApi}/parceiros`,
  headers:{
    'Authorization':`Bearer ${obj.token}`,
    'ContentType':'application/json'
    }
});
export const Api = {
  cadastro: async (dados) => {
    let response = await axiosInstance.post("/cadastro", dados);
    return response.data;
  },
};
