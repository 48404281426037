import axios from "axios";
import { baseUrlApi } from './baseUrl'
const axiosInstance = axios.create({
  baseURL: `${baseUrlApi}/site`,
  headers: {
    "Content-Type": "application/json"
  }
});
export const Api = {
  ramooperadorasDivulgacao:async()=>{
    let response = await axiosInstance.post("/RamooperadorasDivulgacao");
    return response.data;
  },
  estrutura: async () => {
    let response = await axiosInstance.get("/estrutura");
    return response.data;
  },
  Visualizacao: async (dados) => {
    let response = await axiosInstance.post("/blogView",dados);
    return response.data;
  },
  banner: async () => {
    let response = await axiosInstance.post("/banner", { dominio: window.location.host });
    return response.data;
  },
  areas: async () => {
    let response = await axiosInstance.post("/areas", { dominio: window.location.host });
    return response.data;
  },
  operadoras: async (id) => {
  
    let response = await axiosInstance.get(`/operadoras/${id}`);
  
    return response.data;
  },
  operadora: async (id) => {
    let response = await axiosInstance.get(`/operadora/${id}`);
    return response.data;
  },
  ramosAtuacao: async (dados) => {
    let response = await axiosInstance.post("/ramosAtuacao",dados);
    return response.data;
  },
  ramosPaiAtuacao: async (dados) => {
    let response = await axiosInstance.post("/ramosPaiAtuacao",dados);
    return response.data;
  },
  
  sobre: async () => {
    let response = await axiosInstance.post("/sobre", { dominio: window.location.host });
    return response.data;
  },
  depoimentos: async () => {
    let response = await axiosInstance.post("/depoimentos", { dominio: window.location.host });
    return response.data;
  },
  blogs: async () => {
    let response = await axiosInstance.post("/blog", { dominio: window.location.host });
    return response.data;
  },
  estadosSaude: async () => {
    let response = await axiosInstance.get("/estadossaude");
    return response.data;
  },
  estadosOdontologico: async () => {
    let response = await axiosInstance.get("/estadosodontologico");
    return response.data;
  },
  operadoraSaude: async (dados) => {
    let response = await axiosInstance.post("/operadorasaude", dados);
    return response.data;
  },
  operadoraOdontologico: async (dados) => {
    let response = await axiosInstance.post("/operadoraodontologico", dados);
    return response.data;
  },
  operadoraPet: async (dados) => {
    let response = await axiosInstance.post("/operadorapet", dados);
    return response.data;
  },
  operadoraRH: async (dados) => {
    let response = await axiosInstance.post("/operadorarh", dados);
    return response.data;
  },
  operadoraPAH: async (dados) => {
    let response = await axiosInstance.post("/operadorapah", dados);
    return response.data;
  },
  operadoraConsorcio:async (dados) => {
    let response = await axiosInstance.post("/operadoraconsorcio", dados);
    return response.data;
  },
  operadoraMaterial: async (dados) => {
    let response = await axiosInstance.post("/operadoramaterial", dados);
    return response.data;
  },
  operadoraSaudeVenda: async (dados) => {
    let response = await axiosInstance.post("/operadorasaudevenda", dados);
    return response.data;
  },
  operadoraOdontologicoVenda: async (dados) => {
    let response = await axiosInstance.post("/operadoraodontologicovenda", dados);
    return response.data;
  },
  operadoraSeguro: async (dados) => {
    let response = await axiosInstance.post("/operadoraSeguro", dados);
    return response.data;
  },
  materialDivulgacao: async (dados) => {
    let response = await axiosInstance.post("/materialDivulgacao", dados);
    return response.data;
  },
  materialDivulgacaoEstados: async (dados) => {
    let response = await axiosInstance.post("/materialDivulgacaoEstados", dados);
    return response.data;
  },
  loginSocialGoogle: async (dados) => {
    let response = await axiosInstance.post("/loginSocialGoogle", dados);
    if (response.data.error == false) {
      localStorage.setItem("authUser", JSON.stringify(response.data));
      window.location.href = '/ferramentas-de-venda'
    }
    return response.data;
  },
  operadorasDivulgacao: async () => {
    let response = await axiosInstance.get("/operadorasDivulgacao/listagem");
    return response.data;
  },

  DadosBuscarCNPJ: async (cnpj) => {
 
    let response = await axiosInstance.post(`/buscarCNPJ`,{cnpj});
    return response.data;
  },

  gestorModal: async (tela) => {
   
    let response = await axiosInstance.post(`/gestorModal`,{tela});
    return response.data;
  },
};
