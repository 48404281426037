import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../../api";
import { Login } from "../../Corpo/Header/Login";
export default function TestimonialSection({ id, estado }) {
  const [escolha, setEscolha] = useState(0);
  const { plano } = useParams();
  const [listTabela1, setListTabela1] = useState([]);
  const [listTabela2, setListTabela2] = useState([]);
  const [listTabela3, setListTabela3] = useState([]);
  const [listTabela4, setListTabela4] = useState([]);
  const [listTabela5, setListTabela5] = useState([]);
  const [listTabela6, setListTabela6] = useState([]);
  const [listTabela7, setListTabela7] = useState([]);
  const [dadosOperadora, setDadosOperadora] = useState([]);
  const [modalLogin, setModalLogin] = useState(false);
  const getAuthUser = localStorage.getItem("authUser");
  const obj =
    getAuthUser == "undefined" ||
      getAuthUser == null ||
      getAuthUser == "[object Object]"
      ? ""
      : JSON.parse(getAuthUser ? getAuthUser : "");
  useEffect(() => {
    const obj =
      getAuthUser == "undefined" ||
        getAuthUser == null ||
        getAuthUser == "[object Object]"
        ? ""
        : JSON.parse(getAuthUser ? getAuthUser : "");

  }, [obj])
  const handleDetalhes = async () => {
    let dados = await Api.operadoraMaterial({
      id: id,
      estado: estado
    });
    // console.log('handleDetalhes',{dados})
    setDadosOperadora(dados.list[0]);
    let val2 = [];
    val2 = dados.list[0]?.operadorasmaterials.map((item) => {
      return {
        nome: item.nome,
        link: item.idtabelaprecos ? `/tabelaprecos/${item.tabelapreco?.id}` : item.link,
        tipo: item.tipo,
        arquivo: item.arquivo,
        idtabelaprecos: item.idtabelaprecos,
        nometabela: item.nometabela,
        status: true,
        nomearquivo: item.nomearquivo,
        ordem: item.ordem,
        statusVisu:dados?.list[0]?.status
      };
    });

    if (val2?.length > 0) {
      val2.push({ nome: 'Crie sua tabela personalizada', link: obj?.id ? '/tabela' : '/', tipo: 1, arquivo: '', idtabelaprecos: '', nometabela: '', status: false, ordem: -1 });
      val2.push({ nome: "Link da Rede", link: dados.list[0].urlrede, tipo: 4, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1 });
      val2.push({ nome: "Link da Rede", link: dados.list[0].urlrede, tipo: 7, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1 });
      val2.push({
        nome: "Atendimento ao Cliente", detalhe: dados.list[0].atendimentocliente, link: "", tipo: 7, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1
      });
      val2.push({
        nome: "Atendimento ao Corretor", detalhe: dados.list[0].atendimentocorretor, link: "", tipo: 7, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1
      });
      val2.push({ nome: "Site", link: dados.list[0].site, tipo: 7, rquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1 });
      val2.push({ nome: "Segunda via do Boleto", link: dados.list[0].viaboleto, tipo: 7, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1 });
      val2.push({ nome: "Link app android", link: dados.list[0].linkappandroid, tipo: 7, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1 });
      val2.push({
        nome: "Link app ios", link: dados.list[0].linkappios, tipo: 7, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1
      });
      if (dados.list[0].linkvenda) {
        val2.push({ nome: "Link da Venda Online", link: dados.list[0].linkvenda, tipo: 7, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1 });

        val2.push({ nome: "Link da Venda Online", link: dados.list[0].linkvenda, tipo: 2, arquivo: "", idtabelaprecos: "", nometabela: "", status: false, ordem: -1 });
      }
      val2.sort((a, b) => a.ordem - b.ordem);
      let Tabela1 = val2.filter(function (obj) {
        return obj.tipo == 1 && obj.nome != '';
      });
      // Tabela1.sort((a, b) =>  a.nome.localeCompare(b.nome) );
     
      setListTabela1(Tabela1);
      let Tabela2 = val2.filter(function (obj) {
        return obj.tipo == 2 ;
      });
      setListTabela2(Tabela2);

      let Tabela3 = val2.filter(function (obj) {
        return obj.tipo == 3;
      });
      setListTabela3(Tabela3);

      let Tabela4 = val2.filter(function (obj) {
        return obj.tipo == 4;
      });
      setListTabela4(Tabela4);

      let Tabela5 = val2.filter(function (obj) {
        return obj.tipo == 5;
      });
      setListTabela5(Tabela5);

      let Tabela6 = val2.filter(function (obj) {
        return obj.tipo == 6;
      });
      setListTabela6(Tabela6);

      let Tabela7 = val2.filter(function (obj) {
        return obj.tipo == 7;
      });
      setListTabela7(Tabela7);
    }



  };
  useEffect(() => {
    handleDetalhes();
  }, []);

  return (
    <section className={`testimonial-section-three `} style={{ marginTop: 30 }}>
      <div className="container">
        <div className="testimonial-three-wrap ">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div
                className="testimonial-left-image wow fadeInRight delay-0-2s"
                style={{ textAlign: "center" }}
              ></div>
            </div>
            <div className="col-md-12">
              <div className="testimonial-three-content text-center wow fadeInLeft delay-0-2s">



                <div className="row">
                  {listTabela1.length > 0 && (
                    <button
                      className="col-12 feature-item   style-three"
                      style={{ marginBottom: 15, padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545' }}
                      onClick={() => {
                        setEscolha(escolha == 1 ? 0 : 1);
                      }}
                    >
                      <span className="menu-expand" style={{ float: 'left' }}>
                        Tabelas de Preços{" "}
                      </span>

                      <span className="menu-expand" style={{ float: 'right' }}>
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </button>
                  )}

                  {escolha == 1 ? (
                    listTabela1.length > 0 ? (
                      <div style={{ width: "100%", marginBottom: 10 }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: 50 }}>
                          {listTabela1.map((item) => (

                            <>
                           
                              {(true)? (
                                <li style={{ textAlign: "left" }}>
                                  <a

                                    // href={
                                    //   item.nome == 'Crie sua tabela personalizada!' && obj.id ?
                                    //   item.link
                                    //     ? item.link
                                    //     : item.arquivo
                                    //       ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                    //       : ""
                                    //       :''
                                    // }
                                    onClick={() => {
                                      if (item.nome == 'Crie sua tabela personalizada!' && !obj.id) {

                                        setModalLogin(true);
                                      } else if (item.nome == 'Crie sua tabela personalizada!' && obj.id) {
                                        window.open(item.link
                                          ? item.link
                                          : item.arquivo
                                            ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                            : "", '_blank');


                                      } else {
                                        window.open(item.link
                                          ? item.link
                                          : item.arquivo
                                            ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                            : "", '_blank');


                                      }


                                    }}
                                  >
                                    {item.nome}
                                  </a>
                                </li>
                              ) : (item.detalhe   ) ? (
                                <li style={{ textAlign: "left" }}>
                                  <span>
                                    {" "}
                                    {item.nome}
                                    {item.detalhe ? ": " + item.detalhe : ""}
                                  </span>
                                </li>
                              ) :     (
                                <li style={{ textAlign: "left" }}>
                                  <span>
                                    {" "}
                                    {item.nome}

                                  </span>
                                </li>
                              )}

                            </>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <a
                    href={`/materiais/${id}`}
                    className="col-12 feature-item   style-three"
                    style={{ marginBottom: 15, padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545' }}

                  >
                    <span className="menu-expand" style={{ float: 'left' }}>
                      Materiais de Divulgação{" "}
                    </span>


                  </a>

                  {listTabela2.length > 0 && (
                    <button
                      className="col-12 feature-item   style-three"
                      style={{ marginBottom: 15, padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545' }}
                      onClick={() => {
                        setEscolha(escolha == 2 ? 0 : 2);
                      }}
                    >
                      <span className="menu-expand" style={{ float: 'left' }}>
                        <a target="_blank" rel="noreferrer"
                          style={{ fontFamily: "Open Sans, sans-serif" }}

                        >
                          Feche suas Vendas Online{" "}
                        </a>
                      </span>

                      <span className="menu-expand" style={{ float: 'right' }}>
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </button>
                  )}
                  {escolha == 2 ? (
                    listTabela2.length > 0 ? (
                      <div style={{ width: "100%", marginBottom: 10 }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: 50 }}>
                          {listTabela2.map((item) => (

                            <>

                              {item.link || item.arquivo ? (
                                <li style={{ textAlign: "left" }}>
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={
                                      item.link
                                        ? item.link
                                        : item.arquivo
                                          ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                          : ""
                                    }
                                    onClick={() => { item.nome == 'Crie sua tabela personalizada!' && !obj.id && setModalLogin(true) }}
                                  >
                                    {item.nome}
                                  </a>
                                </li>
                              ) : item.detalhe ? (
                                <li style={{ textAlign: "left" }}>
                                  <span>
                                    {" "}
                                    {item.nome}
                                    {item.detalhe ? ": " + item.detalhe : ""}
                                  </span>
                                </li>
                              ) : (
                                ""
                              )}

                            </>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {listTabela3.length > 0 && (
                    <button
                      className="col-12 feature-item   style-three"
                      style={{ marginBottom: 15, padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545' }}
                      onClick={() => {
                        setEscolha(escolha == 3 ? 0 : 3);
                      }}
                    >
                      <span className="menu-expand" style={{ float: 'left' }}>
                        Perfil Planos Empresariais (a partir de 100 vidas){" "}
                      </span>

                      <span className="menu-expand" style={{ float: 'right' }}>
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </button>
                  )}

                  {escolha == 3 ? (
                    listTabela3.length > 0 ? (
                      <div style={{ width: "100%", marginBottom: 10 }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: 50 }}>
                          {listTabela3.map((item) => (
                            <>

                              {item.link || item.arquivo ? (
                                <li style={{ textAlign: "left" }}>
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                    href={
                                      item.link
                                        ? item.link
                                        : item.arquivo
                                          ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                          : ""
                                    }
                                  >
                                    {item.nome}
                                  </a>
                                </li>
                              ) : item.detalhe ? (
                                <li style={{ textAlign: "left" }}>
                                  <span
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {" "}
                                    {item.nome}
                                    {item.detalhe ? ": " + item.detalhe : ""}
                                  </span>
                                </li>
                              ) : (
                                ""
                              )}

                            </>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {listTabela4.length > 0 && (
                    <button
                      className="col-12 feature-item   style-three"
                      style={{ marginBottom: 15, padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545' }}
                      onClick={() => {
                        setEscolha(escolha == 4 ? 0 : 4);
                      }}
                    >
                      <span className="menu-expand" style={{ float: "left" }}>
                        Rede Credenciada{" "}
                      </span>

                      <span className="menu-expand" style={{ float: "right" }}>
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </button>
                  )}

                  {escolha == 4 ? (
                    listTabela4.length > 0 ? (
                      <div style={{ width: "100%", marginBottom: 10 }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: 50 }}>
                          {listTabela4.map((item) => (
                            <>

                              {item.link || item.arquivo ? (
                                <li style={{ textAlign: "left" }} >
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={
                                      item.link
                                        ? item.link
                                        : item.arquivo
                                          ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                          : ""
                                    }
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {item.nome}
                                  </a>
                                </li>
                              ) : item.detalhe ? (
                                <li style={{ textAlign: "left" }} >
                                  <span
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {" "}
                                    {item.nome}
                                    {item.detalhe ? ": " + item.detalhe : ""}
                                  </span>
                                </li>
                              ) : (
                                ""
                              )}

                            </>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {listTabela5.length > 0 && (
                    <button
                      className="col-12 feature-item   style-three"
                      style={{ marginBottom: 15, padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545' }}
                      onClick={() => {
                        setEscolha(escolha == 5 ? 0 : 5);
                      }}
                    >
                      <span className="menu-expand" style={{ float: 'left' }}>
                        Formulários e Aditivos{" "}
                      </span>

                      <span className="menu-expand" style={{ float: 'right' }}>
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </button>
                  )}

                  {escolha == 5 ? (
                    listTabela5.length > 0 ? (
                      <div style={{ width: "100%", marginBottom: 10 }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: 50 }}>
                          {listTabela5.map((item) => (
                            <>

                              {item.link || item.arquivo ? (
                                <li style={{ textAlign: "left" }}>
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={
                                      item.link
                                        ? item.link
                                        : item.arquivo
                                          ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                          : ""
                                    }
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {item.nome}
                                  </a>
                                </li>
                              ) : item.detalhe ? (
                                <li style={{ textAlign: "left" }}>
                                  <span
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {" "}
                                    {item.nome}
                                    {item.detalhe ? ": " + item.detalhe : ""}
                                  </span>
                                </li>
                              ) : (
                                ""
                              )}

                            </>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {listTabela6.length > 0 && (
                    <button
                      className="col-12 feature-item   style-three"
                      style={{ marginBottom: 15, padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545' }}
                      onClick={() => {
                        setEscolha(escolha == 6 ? 0 : 6);
                      }}
                    >
                      <span className="menu-expand" style={{ float: 'left' }}>
                        Manuais e Apostilas{" "}
                      </span>

                      <span className="menu-expand" style={{ float: 'right' }}>
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </button>
                  )}

                  {escolha == 6 ? (
                    listTabela6.length > 0 ? (
                      <div style={{ width: "100%", marginBottom: 10 }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: 50 }}>
                          {listTabela6.map((item) => (
                            <>

                              {item.link || item.arquivo ? (
                                <li style={{ textAlign: "left" }} >
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={
                                      item.link
                                        ? item.link
                                        : item.arquivo
                                          ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                          : ""
                                    }
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {item.nome}
                                  </a>
                                </li>
                              ) : item.detalhe ? (
                                <li style={{ textAlign: "left" }} >
                                  <span
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {" "}
                                    {item.nome}
                                    {item.detalhe ? ": " + item.detalhe : ""}
                                  </span>
                                </li>
                              ) : (
                                ""
                              )}

                            </>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {listTabela7.length > 0 && (
                    <button
                      className="col-12 feature-item   style-three"
                      style={{ marginBottom: 15, padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545' }}
                      onClick={() => {
                        setEscolha(escolha == 7 ? 0 : 7);
                      }}
                    >
                      <span className="menu-expand" style={{ float: 'left' }}>
                        Contatos e Links{" "}
                      </span>

                      <span className="menu-expand" style={{ float: 'right' }}>
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </button>
                  )}

                  {escolha == 7 ? (
                    listTabela7.length > 0 ? (
                      <div style={{ width: "100%", marginBottom: 10 }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: 50 }}>
                          {listTabela7.map((item) => (
                            <>

                              {item.link || item.arquivo ? (
                                <li style={{ textAlign: "left" }}>
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={
                                      item.link
                                        ? item.link
                                        : item.arquivo
                                          ? `https://server.corpar.com.br/pdf/${item.arquivo}`
                                          : ""
                                    }
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {item.nome}
                                  </a>
                                </li>
                              ) : item.detalhe ? (
                                <li style={{ textAlign: "left" }}>
                                  <span
                                    style={{
                                      fontFamily: "Open Sans, sans-serif"
                                    }}
                                  >
                                    {" "}
                                    {item.nome}
                                    {item.detalhe ? ": " + item.detalhe : ""}
                                  </span>
                                </li>
                              ) : (
                                ""
                              )}

                            </>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
    </section>
  );
}
