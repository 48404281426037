import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { Api } from "../../api/apiCotacoes";
import { Agendamentos } from "./utils/agendamentos";
import Agendar from "./utils/agendar";
import { Cadastro } from "./utils/cadastro";
import Editar from "./utils/editar";
import { Excluir } from "./utils/excluir";
import { Filtros } from "./utils/filtros";
import { useParams } from "react-router-dom";
import { Visualizar } from "./utils/visualizar";
import { VisualizarDetalhe } from "./utils/visualizarDetalhes";
import { VisualizarSelecionado } from "./utils/visualizarSelecionado";
import Icone from "../../assets/icones/icone.png"
function CotacoesPrecos() {
  document.title = "Corretor Parceiro";
  const [ramo, setRamo] = useState("");
  useEffect(() => {
    var url = window.location.href;
    url = url.split("/");
    if (url.includes("saude")) {
      setRamo("2");
    } else if (url.includes("odontologico")) {
      setRamo("1");
    }
  }, []);
  const [resposta, setResposta] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [dados, setDados] = useState();
  const [info, setInfo] = useState();
  const [estados, setEstados] = useState([]);
  const [areaComercializacao, setAreaComercializacao] = useState([]);
  const [modalVisualizarDetalhe, setModalVisualizarDetalhe] = useState(false);
  const [profissoes, setProfissoes] = useState();
  const [profissoesSelect, setProfissoesSelect] = useState();
  const [salvarVisualizar, setSalvarVisualizar] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [modalExcluir, setModalExcluir] = useState(false);
  const [modalVisualizarOperadora, setModalVisualizarOperadora] =
    useState(false);
  const [buscar, setBuscar] = useState("");
  const [list, setList] = useState([]);
  const [obj, setObj] = useState();


  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    const obj = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
    setObj(obj)
  }, [])

  const [editar, setEditar] = useState(false);
  const [itens, setItens] = useState([{}]);
  const [faixas, setFaixas] = useState([]);
  const [calculo, setCalculo] = useState([]);
  const [calculoDetalhe, setCalculoDetalhe] = useState([]);
  const [infoDependente, setInfoDependente] = useState([]);
  const [infoSelecionado, setInfoSelecionado] = useState({});
  const [
    modalVisualizarDetalheSelecionado,
    setModalVisualizarDetalheSelecionado
  ] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalFiltro, setModalFiltro] = useState(false);
  const [modalAgendar, setModalAgendar] = useState(false);
  const [modalAgendamento, setModalAgendamento] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [filtro1, setFiltro1] = useState("");
  const [filtro2, setFiltro2] = useState("");
  const [filtro3, setFiltro3] = useState("");
  const [filtro4, setFiltro4] = useState("");
  const [filtro5, setFiltro5] = useState("");
  const [filtro6, setFiltro6] = useState("");
const [loadingDetalheSelecionado, setLoadingDetalheSelecionado] = useState(false)
  useEffect(() => {
    Listagem();

    handleProfissoes();
    handleFaixaValue();
    handleAreaComercializacao();
    handleMunicipios();
  }, []);
  const handleMunicipios = async () => {
    let option = await Api.municipio();
    setMunicipios(option.option);
  };
  const handleCarregamento = (status) => {
    let body = document.querySelector("body");
    if (status) {

      if (body) {
        setLoading(true)
        body.style.cursor = "wait";
      }
    } else {
      if (body) {
        setLoading(false)
        body.style.cursor = "";
      }
    }
  };
  const handleStatus = async (id, status) => {
    let list = await Api.status({ id: id, status });
    setList(list.list);
  };
  const handleAreaComercializacao = async () => {
    let dados = await Api.areacomercializacao();
    setAreaComercializacao(dados.option);
  };
  const handleEstados = async () => {
    let option = await Api.estados({ tipoOperadoras: validation.values.tipoOperadora });
    setEstados(option);
  };

  const handleProfissoes = async () => {
    let dados = await Api.profissoes();
    let val = dados.option;
    val?.unshift({ nome: "Sem profissão", id: "" });
    setProfissoes(val);
    setProfissoesSelect([]);
  };
  const handleModifica = (index, val) => {
    let item = itens;
    if (item[index] || item[index] == "") {
      item[index] = val;
    } else {
      item.push(val);
    }
    setItens(item);
    setEditar(!editar);
  };
  const handleAdd = (val) => {

    let item = [...itens];
    item.push({});
    setItens(item);

  };
  const handleExit = (index) => {
    let item = [...itens];
    item.splice(index, 1);
    setItens(item);
    setEditar(!editar);
  };
  const handleFaixasValue = (value, index) => {
    let faixa = faixas;
    faixa[index].value = value;
    setFaixas(faixa);
    setEditar(!editar);
  };
  const handleFaixaValue = async () => {
    let list = await Api.faixas({ id: 8 });
    let listFaixas = list
    listFaixas = listFaixas.filter((item, index) => index != 0);
    setFaixas(listFaixas);
  };
  const handleTipoValue = async (dados) => {
    handleModifica(dados.item, dados.val);
  };

  useEffect(() => {
    if (id) {
      handleCalculo(id)
    }
  }, [id])
  const [loadingDetalhes,setLoadingDetalhe] = useState(false)
  const handleOperadora = async (idoperadora, produtos,status=true) => {
    setModalVisualizarDetalhe(status);
    setLoadingDetalhe(true);
    let dados = await Api.calculoDetalhe({
      id: idoperadora,
      idcotacao: id,
      produtos: produtos.ids
    });
     //
  
  dados.list.tabelas?.sort((a,b)=> 
    a.tabelafaixas.length >0 ?
    Number(a?.tabelafaixas?.sort((a1,b1)=> Number(a1?.valor?.replace('.','')?.replace(',','.')) - Number(b1?.valor?.replace('.','')?.replace(',','.')) ).reduce((menor, item) => {
      return item.valor < menor.valor ? item : menor;
  })?.valor.replace(',','.')?.replace(',','.'))

 - Number(b?.tabelafaixas?.sort((a1,b1)=> Number(a1?.valor?.replace('.','')?.replace(',','.')) - Number(b1?.valor?.replace('.','')?.replace(',','.')) ).reduce((menor, item) => {
    return item.valor < menor.valor ? item : menor;
})?.valor?.replace(',','.')?.replace(',','.'))
: Number(a.precovida?.replace(',','.')?.replace(',','.')) - Number(b.precovida?.replace(',','.')?.replace(',','.'))
     )

    gerarTabela(dados.list, dados.array);
    setLoadingDetalhe(false);
  
  };
 
  const gerarTabela = (dados, dependentes) => {
 
    let array = {
      produto: [],
      faixas: [],
      observacoes: [],
      areacomercializacao: [],
      dependente: [],
      documento: [],
      pagamento: [],
      rede: [],
      reembolso: [],
      carencia: [],
      entidades: [],
      coparticipacoes: [],
      imagem: dados?.imagem,
      operadora: dados?.nome
    };
    // console.log({dados})
    dados?.tabelas?.map((item) => {

      array.produto.push({ id: item.id, nome: item.nome, cobertura: item.cobertura, acomodacao: item.acomodacao, coparticipacao: item.coparticipacao,
        percentual: item.percentual, vidas: `${item.minvidas} a ${item.maxvidas}`, faixas: item.tabelafaixas.sort( (pessoa1, pessoa2) => pessoa1.id - pessoa2.id ),
        precovida: item.precovida, item, status: item.status  });
      if (item.coparticipaco?.id) {
        if (item.coparticipaco?.id && !array.coparticipacoes.some((arr) => arr.id == item.coparticipaco?.id)) {
          array.coparticipacoes.push({ id: item.coparticipaco.id, item: item.coparticipaco, produto: [item.nome] })
        } else {
          array.coparticipacoes.some((arr) => arr.id == item.coparticipaco?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (
        item.observaco?.id &&
        !array.observacoes.some((arr) => arr.id == item.observaco.id)
      ) {
        array.observacoes.push({
          id: item.observaco?.id,
          item: item.observaco,
          produto: [item.nome]
        });
      } else {
        array.observacoes.some((arr) =>
          arr.id == item.observaco?.id ? arr.produto.push(item.nome) : ""
        );
      }
      if (
        item.areacomercializacao?.id &&
        !array.areacomercializacao.some(
          (arr) => arr.id == item.areacomercializacao?.id
        )
      ) {
        array.areacomercializacao.push({
          id: item.areacomercializacao.id,
          descricoes: item.areacomercializacao?.areacomercializacaodescricaos,
          produto: [item.nome],
          municipios: item.areacomercializacaoestadosmunicipios
        });
      } else {
        array.areacomercializacao.some((arr) =>
          arr.id == item.areacomercializacao?.id
            ? arr.produto.push(item.nome)
            : ""
        );
      }
      if (
        item.dependente?.id &&
        !array.dependente.some((arr) => arr.id == item.dependente?.id)
      ) {
        array.dependente.push({
          id: item.dependente?.id,
          item: item.dependente,
          produto: [item?.nome]
        });
      } else {
        array.dependente.some((arr) =>
          arr.id == item.dependente?.id ? arr.produto.push(item.nome) : ""
        );
      }
      if (
        item.documento?.id &&
        !array.documento.some((arr) => arr.id == item.documento?.id)
      ) {
        array.documento.push({
          id: item.documento.id,
          item: item.documento,
          produto: [item.nome]
        });
      } else {
        array.documento.some((arr) =>
          arr.id == item.documento?.id ? arr.produto.push(item.nome) : ""
        );
      }

      if (
        item.pagamento?.id &&
        !array.pagamento.some((arr) => arr.id == item.pagamento?.id)
      ) {
        array.pagamento.push({
          id: item.pagamento.id,
          item: item.pagamento,
          produto: [item.nome]
        });
      } else {
        array.pagamento.some((arr) =>
          arr.id == item.pagamento?.id ? arr.produto.push(item.nome) : ""
        );
      }

      if (
        item.carencia?.id &&
        !array.carencia.some((arr) => arr.id == item.carencia?.id)
      ) {
        array.carencia.push({
          id: item.carencia.id,
          item: item.carencia,
          produto: [item.nome]
        });
      } else {
        array.carencia.some((arr) =>
          arr.id == item.carencia?.id ? arr.produto.push(item.nome) : ""
        );
      }

      if (
        item.rede?.id &&
        !array.rede.some((arr) => arr.id == item.rede?.id)
      ) {
        array.rede.push({
          id: item.rede.id,
          item: item.rede,
          produto: [item.nome]
        });
      } else {
        array.rede.some((arr) =>
          arr.id == item.rede?.id ? arr.produto.push(item.nome) : ""
        );
      }

      if (
        item.reembolso?.id &&
        !array.reembolso.some((arr) => arr.id == item.reembolso?.id)
      ) {
        array.reembolso.push({
          id: item.reembolso.id,
          item: item.reembolso,
          produto: [item.nome]
        });
      } else {
        array.reembolso.some((arr) =>
          arr.id == item.reembolso?.id ? arr.produto.push(item.nome) : ""
        );
      }
      if (
        item.grupoentidades?.id &&
        !array.entidades.some((arr) => arr.id == item.grupoentidades?.id)
      ) {
        array.entidades.push({
          id: item.grupoentidades.id,
          item: item.grupoentidades
          ,
          produto: [item.nome]
        });
      } else {
        array.entidades.some((arr) =>
          arr.id == item.grupoentidades?.id ? arr.produto.push(item.nome) : ""
        );
      }
    });


    array.produto.map((item)=>{
     
      handleClick(item.id, item?.faixas.length > 0 ? item?.faixas.map((item3) => { return item3.id }).join('&') : `${item.id}-${item.precovida}`, item, { imagem: array?.imagem, operadora: array?.operadora }) 
    })
    
    setInfoDependente(dependentes);
    setInfo(array);
 
    handleCarregamento(false);
  };
  const gerarTabelaSelecionada = (dados, status = false) => {
   
    let array = {
      nome: "",
      id: calculoDetalhe?.id,
      estado: calculoDetalhe?.estado,
      produto: [],
      faixas: [],
      observacoes: [],
      areacomercializacao: [],
      dependente: [],
      documento: [],
      pagamento: [],
      rede: [],
      reembolso: [],
      carencia: [],
      entidades: [],
      coparticipacoes: []
    };
   
    dados.map((item) => {
      if (
        selecteds.some(
          (item3) =>
            item3.tabela === item.tabela && item3.calculo === item.calculo
        )
      ) {
        array.produto.push({
          id: item.info?.item?.id,
          nome: item.info?.item?.nome,
          cobertura: item.info?.item?.cobertura,
          acomodacao: item.info?.item?.acomodacao,
          coparticipacao: item.info?.item?.coparticipacao,
          percentual: item.info?.item?.percentual,
          vidas: `${item.info?.item?.minvidas} a ${item.info?.item?.maxvidas}`,
          faixas: item.info?.item?.tabelafaixas?.sort(
            (pessoa1, pessoa2) => pessoa1.id - pessoa2.id
          ),
          precovida: item.info?.item?.precovida,
          imagem: item?.dados?.imagem,
          operadora: item?.dados?.operadora,
          status: item.status
        });
        if (item.coparticipaco?.id) {
          if (item.coparticipaco?.id && !array.coparticipacoes.some((arr) => arr.id == item.coparticipaco?.id)) {
            array.coparticipacoes.push({ id: item.coparticipaco.id, item: item.coparticipaco, produto: [item.nome] })
          } else {
            array.coparticipacoes.some((arr) => arr.id == item.coparticipaco?.id ? arr.produto.push(item.nome) : '')
          }
        }
        if (
          item.info.item.observaco?.id &&
          !array.observacoes.some(
            (arr) => arr.id == item.info.item.observaco.id
          )
        ) {
          array.observacoes.push({
            id: item.info.item.observaco.id,
            item: item.info.item.observaco,
            produto: [item.info.item.nome],
            operadora: item?.dados?.operadora
          });
        } else {
          array.observacoes.some((arr) =>
            arr.id == item.info.item.observaco?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }
       
        if (
          item.info.item.areacomercializacao?.id &&
          !array.areacomercializacao.some(
            (arr) => arr.id == item.info.item.areacomercializacao?.id
          )
        ) {
          array.areacomercializacao.push({
            id: item.info.item.areacomercializacao.id,
            produto: [item.info.item.nome],
            operadora: item?.dados?.operadora,
            descricoes:item.info.item.areacomercializacao.areacomercializacaodescricaos,
            municipios: item.info.item.areacomercializacaoestadosmunicipios
          });
        } else {
          array.areacomercializacao.some((arr) =>
            arr.id == item.info.item.areacomercializacao?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }
        if (
          item.info.item.dependente?.id &&
          !array.dependente.some(
            (arr) => arr.id == item.info.item.dependente?.id
          )
        ) {
          array.dependente.push({
            id: item.info.item.dependente?.id,
            item: item.info.item.dependente,
            produto: [item.info?.item?.nome],
            operadora: item?.dados?.operadora
          });
        } else {
          array.dependente.some((arr) =>
            arr.id == item.info.item.dependente?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }
        if (
          item.info.item.documento?.id &&
          !array.documento.some(
            (arr) => arr.id == item.info.item.documento?.id
          )
        ) {
          array.documento.push({
            id: item.info.item.documento.id,
            item: item.info.item.documento,
            produto: [item.info?.item?.nome],
            operadora: item?.dados?.operadora
          });
        } else {
          array.documento.some((arr) =>
            arr.id == item.info.item.documento?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }
        if (
          item.info.item.pagamento?.id &&
          !array.pagamento.some(
            (arr) => arr.id == item.info.item.pagamento?.id
          )
        ) {
          array.pagamento.push({
            id: item.info.item.pagamento.id,
            item: item.info.item.pagamento,
            produto: [item.info?.item?.nome],
            operadora: item?.dados?.operadora
          });
        } else {
          array.pagamento.some((arr) =>
            arr.id == item.info.item.pagamento?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }
        if (
          item.info.item.carencia?.id &&
          !array.carencia.some(
            (arr) => arr.id == item.info.item.carencia?.id
          )
        ) {
          array.carencia.push({
            id: item.info.item.carencia.id,
            item: item.info.item.carencia,
            produto: [item.info?.item?.nome],
            operadora: item?.dados?.operadora
          });
        } else {
          array.carencia.some((arr) =>
            arr.id == item.info.item.carencia?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }
        if (
          item.info.item.rede?.id &&
          !array.rede.some((arr) => arr.id == item.info.item.rede?.id)
        ) {
          array.rede.push({
            id: item.info.item.rede.id,
            item: item.info.item.rede,
            produto: [item.info?.item?.nome],
            operadora: item?.dados?.operadora
          });
        } else {
          array.rede.some((arr) =>
            arr.id == item.info.item.rede?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }
        if (
          item.info.item.reembolso?.id &&
          !array.reembolso.some(
            (arr) => arr.id == item.info.item.reembolso?.id
          )
        ) {
          array.reembolso.push({
            id: item.info.item.reembolso.id,
            item: item.info.item.reembolso,
            produto: [item.info?.item?.nome],
            operadora: item?.dados?.operadora
          });
        } else {
          array.reembolso.some((arr) =>
            arr.id == item.info.item.reembolso?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }

       
        if (
          item.info.item.grupoentidades?.id &&
          !array.entidades.some((arr) => arr.id == item.info.item.grupoentidades?.id)
        ) {
          array.entidades.push({
            id: item.info.item.grupoentidades.id,
            item: item.info.item.grupoentidades,
            produto: [item.info?.item?.nome],
            operadora: item?.dados?.operadora
          });
        } else {
          array.entidades.some((arr) =>
            arr.id == item.info.item.grupoentidades?.id
              ? arr.produto.push(item.info.item.nome)
              : ""
          );
        }
      }
    });

    setInfoSelecionado(array);
    if (status) {
      setModalVisualizarDetalheSelecionado(true);
    }
    setLoadingDetalheSelecionado(false);
  };
  const Listagem = async () => {
    handleCarregamento(true);
    let dados = await Api.listagem();
    setList(dados.list);
    handleCarregamento(false);
  };
  const handleExcluir = async () => {
    handleCarregamento(true);
    let list = await Api.delete({ id: id });
    setList(list);
    setModalExcluir(false);
    handleCarregamento(false);
  };
  const handleFiltro = async () => {
    handleCalculo(id);
    setModalFiltro(false);
  };
  const handleCalculo = async (id) => {
    handleCarregamento(true);
    let dados = await Api.calculo({
      id: id,
      filtro1,
      filtro2,
      filtro3,
      filtro4,
      filtro5,
      filtro6
    });
 
    let arrayValorOperadora = []
    if(dados.list?.list?.length>0){
      dados.list?.list?.map((item)=>{
        item.tabelas.map((item2)=>{
          
          item2.tabelafaixas.map((item3)=>{
            
            if( item3.valor && Number(item3.valor?.replace('.','').replace(',','.')) && !arrayValorOperadora.some((filtro)=> filtro.valor== Number(item3.valor?.replace('.','')?.replace(',','.')) &&  filtro.operadora==item.nome)){
              arrayValorOperadora.push({valor:Number(item3.valor?.replace('.','')?.replace(',','.')),operadora:item.nome})
            }
          
          })
    
        })
       
      })
      arrayValorOperadora.sort((a,b)=> a.valor - b.valor)
  
      let menorValor = arrayValorOperadora[0]?.valor
      let menorOperadora = arrayValorOperadora[0]?.operadora
  
      let intermediarioValor= arrayValorOperadora[ Math.ceil((arrayValorOperadora?.length -1)/ 2)]?.valor
      let intermediarioOperadora= arrayValorOperadora[ Math.ceil((arrayValorOperadora?.length -1) / 2)]?.operadora
  
  
      let maiorValor= arrayValorOperadora[arrayValorOperadora?.length-1]?.valor
      let maiorOperadora= arrayValorOperadora[arrayValorOperadora?.length-1]?.operadora
     
  
      setCalculo(dados?.list?.list);
      setModalVisualizarOperadora(true);
     
      setCalculoDetalhe({
        operadoraValorMaximo: maiorOperadora,
        operadoraValorMinimo: menorOperadora,
        operadoraValoIntermediario: intermediarioOperadora,
        valorMaximo: maiorValor,
        valorMinimo: menorValor,
        valorDoMeio: intermediarioValor,
        id: dados.list.cotacao.id,
        vidas: dados.list.cotacao?.vidas,
        nome: dados.list.cotacao?.nome,
        nomeempresa: dados.list.cotacao.empresa,
        estado: dados.list?.cotacao?.info?.estado?.nome
      });
      handleCarregamento(false);
    }
  
  };

  const filtrar = async (nome) => {
    handleCarregamento(true);
    setLoading(true);
    let list = await Api.filtro({ nome: nome });
    setList(list.list);
    setLoading(false);
    handleCarregamento(false);
  };
  const handleDados = async (id) => {
    handleCarregamento(true);
    let dados = await Api.dados({ id: id });
    setDados({
      tipo: dados.list.tipo,
      ramo: dados.list.ramo,
      descricao: dados.list.descricao,
      pessoacontato: dados.list.pessoacontato,
      email: dados.list.email,
      telefone: dados.list.telefone,
      estado: dados.list.idestado,
      profissao: dados.list.idprofissao,
      titular: dados.list.titular,
      nomeempresa: dados.list.nomeempresa,
      tipocnpj: dados.list.tipocnpj,
      dependentes: dados.list.dependentes,
      areacomercializacao: dados.list.idareacomercializacao
    });

    let newFaixa = faixas;
    dados.list.cotacoesfaixas.forEach((elemento2) => {
      const elemento1 = newFaixa.find(
        (elemento1) => elemento1.id === elemento2.idfaixa
      );
      if (elemento1) {
        elemento1.value = elemento2.valor;
      }
    });

    setFaixas(newFaixa);
    let val1 = dados.list.cotacoesdependentes.map((item) => {
      return item.dependente;
    });
    setItens(val1);
    handleCarregamento(false);

  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "" || id,
      tipo: dados?.tipo ? dados.tipo : "1",
      tipoOperadora: dados?.tipoOperadora ? dados.tipoOperadora : "",
      descricao: dados?.descricao ? dados.descricao : "",
      pessoacontato: dados?.pessoacontato ? dados.pessoacontato : "",
      email: dados?.email ? dados.email : "",
      telefone: dados?.telefone ? dados.telefone : "",
      estados: dados?.estado ? dados.estado : "",
      profissao: dados?.profissao ? dados.profissao : "",
      titular: dados?.titular ? dados.titular : "",
      nomeempresa: dados?.nomeempresa ? dados.nomeempresa : "",
      tipocnpj: dados?.tipocnpj ? dados.tipocnpj : "",
      dependentes: dados?.dependentes ? dados.dependentes : "",
      faixas: null,
      dependentesarray: null
    },

    onSubmit: async (values) => {
      handleCarregamento(true);
      setLoading(true);
      values.faixas = faixas;
      values.dependentesarray = itens;
      if (id) {
        let json = await Api.editar(values);

        if (json.error) {
          setError(true);
          setResposta(true);
          setMsg(json.msg);
        } else {
          if (salvarVisualizar) {
            handleCalculo(json.id);
            setSalvarVisualizar(false);
            setRamo(ramo)
          }
          Listagem();
          setBuscar("");
          handleLimpar(true);
          setModalEditar(false);
        }
        setLoading(false);
      } else {
        let json = await Api.cadastro(values);

        if (json.error) {
          setError(true);
          setResposta(true);
          setMsg(json.msg);
        } else {
          if (salvarVisualizar) {
            handleCalculo(json.id);
            setSalvarVisualizar(false);
          }

          setError(false);
          setResposta(true);
          setMsg(json.msg);
          handleLimpar(false);
          Listagem();
        }
        setLoading(false);
      }
      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
      handleCarregamento(false);
    }
  });

  const handleLimpar = (status = false) => {
    if (status) {
      setResposta(false);
    }
    validation.values.descricao = "";
    validation.values.pessoacontato = "";
    validation.values.email = "";
    validation.values.telefone = "";
    validation.values.estados = "";
    validation.values.profissao = "";
    validation.values.titular = "";
    validation.values.nomeempresa = "";
    validation.values.tipocnpj = "";
    validation.values.dependentes = "";
    validation.values.areacomercializacao = "";
    validation.values.faixas = null;
    validation.values.dependentesarray = null;
    setItens([{}]);
    setInfoDependente([]);
    setInfoSelecionado({});
    setSelecteds([]);
    setDados({
      tipo: "1",
      ramo: "",
      descricao: "",
      pessoacontato: "",
      email: "",
      telefone: "",
      estado: "",
      profissao: "",
      titular: "",
      nomeempresa: "",
      tipocnpj: "",
      dependentes: "",
      areacomercializacao: ""
    });
    let newFaixa = faixas;
    newFaixa.forEach((elemento2) => {
      elemento2.value = "";
    });
    setFaixas(newFaixa);
  };
  const [selecteds, setSelecteds] = useState([]);
  const handleMouseOver = (idtabela, id) => {
    let elemento = document.querySelector(`tr[id="${id}"]`);
    if (elemento) {

      elemento.style.backgroundColor = "#fff";
      elemento.style.border = "1px solid #0F4CBA";
      elemento.style.color = "#000";


    }
  };
  const handleMouseOut = (idtabela, id) => {
    let elemento = document.querySelector(`tr[id="${id}"]`);
    if (
      elemento &&
      !selecteds.some(
        (item) => item.tabela === idtabela && item.calculo === id
      )
    ) {
      elemento.style.backgroundColor = "#fff";
      elemento.style.borderColor = "#fff";
      elemento.style.color = "#000";
    }
  };
  const handleClick = (idtabela, id, info, dados) => {
    
    if (  selecteds.some( (item) => item.tabela == idtabela && item.calculo == id  ) ) {
      let item = selecteds;
      let filtro = item.filter( (item1) => item1.tabela !== idtabela && item1.calculo !== id );
      setSelecteds(filtro);
    } else {
      let item = selecteds;
      item.push({ tabela: idtabela, calculo: id, info, dados });
      setSelecteds(item);
    }
   
  };
  const handleClickOperadoraSelect =  async(item) => {
    setLoadingDetalheSelecionado(true);
    handleOperadora(item.id, { ids: item.tabelas.map((item2) => { return item2.id }) },false);
  };
  const handleClickOperadoraDeselect =  async(item) => {
    setLoadingDetalheSelecionado(true);
    let item5 = [...selecteds];
    item.tabelas.map((item2)=>{
    
      if ( item5.some( (item4) => item4.tabela == item2.id  ) ) {
        item5 = item5.filter( (item1) => item1.tabela !== item2.id  );
      } 
    })
    gerarTabelaSelecionada(item5, false)
    setSelecteds(item5);
    setLoadingDetalheSelecionado(false);
  };
  const handleVisualizarCotacoes = () => {
    setLoadingDetalheSelecionado(true);
    gerarTabelaSelecionada(selecteds, true);
    setLoadingDetalheSelecionado(false);
  };
  const [area, setArea] = useState(1);
  useEffect(() => {
    var url = window.location.href;
    url = url.split("/");
    if (url.includes("minhascotacoes")) {
      setArea(2);
    }
    if (obj) {
      if (!obj?.id) {
        window.location.href = '/'
      }
    }
  }, [obj]);
  useEffect(() => {
    handleEstados()
  }, [validation.values.tipoOperadora])
  return (
    <section className="about-two  rpt-80 pb-105 rpb-100">
      <div className="container" >
        <div className="" style={{ width: "100%", marginTop: '-50px' }}>
          <div className="row">
            {(loading || !calculoDetalhe.operadoraValorMaximo) && (
               <div  className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299,display:'flex',justifyContent:'center',alignItems:'center' }}
               >
                  <img src={Icone} className="rotating-image" />
               </div>
            )}
            {(!loading &&   calculoDetalhe.operadoraValorMaximo )&& (
              <div className="col-lg-12 ">
                <div
                  className="about-content pt-100 rpt-20 pr-70 rpr-0 wow fadeInRight delay-0-2s"
                  style={{ paddingLeft: 15 }}
                >


                  <Visualizar handleCarregamento={handleCarregamento} setModalFiltro={setModalFiltro} handleOperadora={handleOperadora} calculoDetalhe={calculoDetalhe} calculo={calculo}
                    modalVisualizarOperadora={true} setModalVisualizarOperadora={setModalVisualizarOperadora} handleVisualizarCotacoes={handleVisualizarCotacoes} ramo={ramo} handleClickOperadoraSelect={handleClickOperadoraSelect} handleClickOperadoraDeselect={handleClickOperadoraDeselect} loadingDetalhes={loadingDetalhes} />

                  <VisualizarDetalhe handleCarregamento={handleCarregamento} handleClick={handleClick} handleMouseOut={handleMouseOut} handleMouseOver={handleMouseOver} selecteds={selecteds}
                    setModalVisualizarDetalhe={setModalVisualizarDetalhe} infoDependente={infoDependente} faixas={faixas} info={info} modalVisualizarDetalhe={modalVisualizarDetalhe} calculoDetalhe={calculoDetalhe} handleVisualizarCotacoes={handleVisualizarCotacoes} loadingDetalhes={loadingDetalhes} />

                  <VisualizarSelecionado handleCarregamento={handleCarregamento} selecteds={selecteds} setModalVisualizarDetalhe={ setModalVisualizarDetalheSelecionado } infoDependente={infoDependente} faixas={faixas} info={infoSelecionado} modalVisualizarDetalhe={modalVisualizarDetalheSelecionado} calculoDetalhe={calculoDetalhe} handleClick={handleClick}
                    gerarTabelaSelecionada={gerarTabelaSelecionada} loadingDetalhes={loadingDetalhes} setLoadingDetalhe={setLoadingDetalhe}/>

                  <Editar handleCarregamento={handleCarregamento} dados={dados} areaComercializacao={areaComercializacao} error={error} estados={estados} faixas={faixas} handleAdd={handleAdd} handleExit={handleExit} handleFaixasValue={handleFaixasValue} handleLimpar={handleLimpar} handleTipoValue={handleTipoValue} itens={itens} loading={loading} modalEditar={modalEditar} msg={msg} profissoes={profissoes} resposta={resposta} setModalEditar={setModalEditar} setResposta={setResposta} setSalvarVisualizar={setSalvarVisualizar} validation={validation} />

                  <Filtros filtro1={filtro1} filtro2={filtro2} filtro3={filtro3} filtro4={filtro4} filtro5={filtro5} filtro6={filtro6} municipios={municipios} handleCarregamento={handleCarregamento} modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} setFiltro1={setFiltro1} setFiltro2={setFiltro2} setFiltro3={setFiltro3} setFiltro4={setFiltro4} setFiltro5={setFiltro5} setFiltro6={setFiltro6} handleFiltro={handleFiltro} ramo={ramo} />
                  
                  <Agendar handleLimpar={handleLimpar} modalAgendar={modalAgendar} setModalAgendar={setModalAgendar} id={id} />
                  <Agendamentos handleLimpar={handleLimpar} modalAgendamento={modalAgendamento} setModalAgendamento={setModalAgendamento} id={id} />
                </div>
              </div>

            )}

          </div>
        </div>
      </div>
    </section>
  );
}

export default CotacoesPrecos;
