import React from "react";
import {BiBook,BiBookOpen,BiCollapse,BiEdit,BiShow,BiWrench} from "react-icons/bi";
import {DropdownItem,DropdownMenu,DropdownToggle,UncontrolledDropdown} from "reactstrap";
import { ImBin } from "react-icons/im";
export const ListagemTabPre = ({ buscar,iltrar,list,permissoes,setBuscar,setModalExcluir,setModalVisualizarOperadora,setid,handleCalculo,handleLimpar,setModalEditar,handleDados,handleCarregamento,handleStatus,setModalAgendamento,setRamo}) => {
  return (
    <div>
      <input
        placeholder="buscar..."
        className="form-control"
        name="buscar"
        value={buscar}
        onChange={(e) => {
          filtrar(e.target.value);
          setBuscar(e.target.value);
        }}
      />
      <div
        className="table-responsive"
        style={{ marginTop: 20, minHeight: 250 }}
      >
        <table className="table">
          <thead>
            <tr>
              <th  style={{textAlign:'center'}}>Id</th>
              <th  style={{textAlign:'center'}}>Visualizar</th>
              <th  style={{textAlign:'center'}}>Ramo</th>
              <th  style={{textAlign:'center'}}>Nome</th>
              <th  style={{textAlign:'center'}}>Telefone</th>
              <th  style={{textAlign:'center'}}>Fase da Negociação</th>
              <th  style={{textAlign:'center'}}>Agendamentos</th>
              <th  style={{textAlign:'center'}}></th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item, index) => (
                <tr key={index}>
                  <td style={{textAlign:'center'}} >{item.id} </td>
                  <td  style={{textAlign:'center'}}>
                  <a href={`/cotacoesprecos/${item.id}`} rel="noreferrer" target="_blank">  <BiShow /></a>
             
                  </td>
                
                  <td  style={{textAlign:'center'}}>{item.tipooperadora?.nome} </td>
                  <td  style={{textAlign:'center'}}>{item.descricao} </td>
                  <td  style={{textAlign:'center'}}>{item.telefone} </td>
                  <td>
                    <select
                      className="form-control"
                      defaultValue={item.status}
                      onChange={(e) => {
                        handleStatus(item.id, e.target.value);
                      }}
                    >
                      <option>Selecione</option>
                      <option value="1">Enviado para o cliente</option>
                      <option value="2">Cliente analisando</option>
                      <option value="3">Cliente interessado</option>
                      <option value="4">Contrato fechado</option>
                      <option value="5">Sem interesse</option>
                    </select>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <button className="btn btn-default">
                      {item?.agendamentos?.length}
                    </button>{" "}
                  </td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn btn-secondary"
                        type="button"
                        tag="a"
                      >
                        <BiWrench />
                      </DropdownToggle>
                      <DropdownMenu style={{padding:0 }}>
                      {/* <DropdownItem
                            style={{ color: "green",display:'flex',alignItems:'center',padding:5  }}
                            onClick={() => {
                              setModalEditar(true);
                              handleLimpar(true);
                              setid(item.id);
                              handleCarregamento(true);
                              handleDados(item.id);
                            }}
                            data-toggle="modal"
                            data-target="#modal-center2"
                            to="#"
                          >
                            <BiEdit /> Editar
                          </DropdownItem> */}


                    
                        <DropdownItem style={{ color: "green" ,display:'flex',alignItems:'center' ,padding:5  }}
                          onClick={() => {
                            setid(item.id);
                            setModalAgendamento(true);
                          }} data-toggle="modal" data-target="#modal-center2" to="#" >
                          <BiBookOpen /> Agendamentos
                        </DropdownItem>

                        <DropdownItem style={{ color: "red",display:'flex',alignItems:'center',padding:5 }}
                          onClick={() => {
                            setid(item.id);
                            setModalExcluir(true);
                          }} data-toggle="modal" data-target="#modal-center2" to="#" >
                      
                         <ImBin />Excluir
                        </DropdownItem>


                       
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
