import React from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Corpo/Layouts";

export default function ServiceOne() {
  const { ramo } = useParams();
  return (
    <Layouts
      pageTitle={`Empresas Parceiras`}
      breadcrumbs={[
        { name: "Home", path: "../" },
        { name: "Empresas Parceiras", path: `/operadoras/${ramo?ramo:''}` },
      ]}
    >
      <Dados ramo={ramo} />
    </Layouts>
  );
}
