import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import Listagem from "./utils/listagem";
import Icone from "../../assets/icones/icone.png"
function FeatureSection(val) {
  const [operadoraSaude, setOperadoraSaude] = useState();
  const [datas, setDatas] = useState([]);
  const {  tipo } = useParams();
  const [estados,setEstados] = useState([])
  const [estadosSelect,setEstadosSelect] = useState('')
  const [planoSelect,setPlanoSelect] = useState('')
  const [obj, setObj] = useState();
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    const obj = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
    setObj(obj)
  }, [])
  useEffect(() => {
    if (obj?.dados?.estado && estados.length>0) {
      let ids = estados?.find((item) => item.nome == obj?.dados?.estado)
      setEstadosSelect(ids?.id)
    }

  }, [obj, estados])
  const handleEstados = async () =>{
    let value = await Api.estadosSaude()
    setEstados(value.list)
  }
  const handleOperadora = async () => {
    setLoading(true)
    if (estadosSelect && planoSelect &&  planoSelect == 1) {
      setDatas([]);
      let list = await Api.operadoraSaude({ estado: estadosSelect});
      let variavel = list.list.filter((item) => item.linkvenda != null);
      let valores = variavel.map((item) => {
        return {
          id: item.id,
          title: item.nome,
          image: item.imagem,
          filter: item.tipopessoa,
          link: item.linkvenda
        };
      });
      setDatas({valores,estado:estadosSelect});
    }

    if (estadosSelect && planoSelect &&  planoSelect == 2) {
      setDatas([]);
      let list = await Api.operadoraOdontologico({ estado:estadosSelect});
      let variavel = list.list.filter(
        (item) => item.linkvenda != null || item.linkvenda != ""
      );
      let valores = variavel.map((item) => {
        return {
          id: item.id,
          title: item.nome,
          image: item.imagem,
          filter: item.tipopessoa,
          link: item.linkvenda
        };
      });
      setDatas({valores,estado:estadosSelect});
    }
    setLoading(false)
  };
  useEffect(() => {
    handleEstados();
    if(tipo=='saude'){
      setPlanoSelect(1)
    }else if(tipo=='odonto'){
      setPlanoSelect(2)
    }
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
   
    handleOperadora();
  
  }, [estadosSelect, planoSelect]);
  return (
    <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{minHeight:700}}>
      <div className="container">
      <div className="row" style={{marginBottom:15,display:'flex',justifyContent:'center',alignItems:'center'}}>
         
      <div className={planoSelect==1 || planoSelect==2?'col-4':'col-4'}  >
           
                <select className="form-control" name="estados" onChange={(e)=>{setPlanoSelect(e.target.value)}} >
                    <option value=''>Qual tipo de produto gostaria?</option>
                    <option selected={tipo=='saude'?true:false} value={1}>Plano de Saúde</option>
                    <option selected={tipo=='odonto'?true:false} value={2}>Plano Odontológico</option>
                </select>
            </div>
            
            {(planoSelect==1 || planoSelect==2 )&& 
             <div className="col-4" >
           
             <select className="form-control" name="estados" value={estadosSelect} onChange={(e)=>{setEstadosSelect(e.target.value)}} >
                 <option value=''>Qual o Estado gostaria?</option>
                 {estados && estados.map((item)=>(
                    <option key={item} value={item.id}>{item.nome}</option>
                 ))}
             </select>
         </div>
        
    
            }
           
         </div>
        {datas.estado && <Listagem datas={datas} />}
        { loading  && (
           <div  className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299,display:'flex',justifyContent:'center',alignItems:'center' }}
           >
              <img src={Icone} className="rotating-image" />
           </div>
        )}

        <div className="feature-btn text-center mt-20"></div>
      </div>
    </section>
  );
}

export default FeatureSection;
