import React, { useEffect, useState } from "react";
import { Col, Modal } from "reactstrap";
export const Visualizar = ({ calculo, modalVisualizarOperadora, setModalVisualizarOperadora, calculoDetalhe, handleOperadora, handleVisualizarCotacoes, setModalFiltro, handleCarregamento, ramo }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [calculo]);
  return (

    <div className="modal-body" style={{ paddingRight: isDesktop ? 100 : 0, paddingLeft: isDesktop ? 100 : 0 }} >

      <div >
        <Col lg={12} style={{ marginBottom: 50, display: 'flex', justifyContent: 'space' }}>
          <button
            className="btn btn-primary"
            onClick={() => {
              setModalFiltro(true);
            }}
            style={{ float: "right", backgroundColor: '#1b2949' }}
          >
            Filtrar
          </button>
          <button
            className="btn btn-primary"
            onClick={handleVisualizarCotacoes}
            style={{ float: "right", marginRight: 10, backgroundColor: '#1b2949' }}
          >
            Visualizar Cotações
          </button>
        </Col>
      </div>
      <table className="table" >
        <thead>
          <tr>

            <th style={{ display: 'flex', justifyContent: 'space-around', border: 'none' }}>
              <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontWeight: 100 }}>Cálculo Nº {calculoDetalhe?.id}</p>
                <p style={{ fontWeight: 100 }}>Total de vidas {calculoDetalhe?.vidas}</p>
                <p style={{ fontWeight: 100 }}>
                  {calculoDetalhe?.nomeempresa
                    ? `Empresa ${calculoDetalhe?.nomeempresa}`
                    : `Nome ${calculoDetalhe?.nome ? calculoDetalhe.nome : ''}`}
                </p>

              </div>
              <div style={{ flex: 2 }}>
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center',flexDirection:'column' }}>
                  <div style={{ fontWeight: 100, width: '100%' }}>
                    {calculoDetalhe?.valorMinimo?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}
                    <span style={{ fontWeight: 100, fontSize: 13 }}> Menor Preço <br />({calculoDetalhe?.operadoraValorMinimo})</span>
                  </div>
                  <div style={{ fontWeight: 100, width: '100%' }}>
                    {calculoDetalhe?.valorDoMeio?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}
                    <span style={{ fontWeight: 100, fontSize: 13 }}> Preço Intermediário <br />( {calculoDetalhe?.operadoraValoIntermediario}) </span>
                  </div>
                  <div style={{ fontWeight: 100, width: '100%' }}>
                    {calculoDetalhe?.valorMaximo?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}
                    <span style={{ fontWeight: 100, fontSize: 13 }}> Maior Preço <br />({calculoDetalhe?.operadoraValorMaximo})</span>
                  </div>
                </div>

              </div>

            </th>

          </tr>
        </thead>
      </table>
      <table className="table" >

        <tbody>
          {calculo.length > 0 &&
            calculo?.map((item) => (
              <tr key={item.id} style={{ textAlign: "center" }}>
                <td style={{ width: 500 }}>
                  {" "}
                  <img
                    src={`https://server.corpar.com.br/imagens/${item.imagem}`}
                    alt=""
                    width={'20%'}

                  />
                  <br />
                  <p style={{ textAlign: "center", fontSize: 10 }}>
                    {item.nome}
                  </p>
                </td>
                <td>
                  <a
                    className="btn btn-link"
                    style={{ fontWeight: 100, color: '#1b2949' }}
                    href={`/operadora/${item.nome.replace(/ /g, "-")}`}
                  >
                    Ver Material de Vendas
                  </a>
                </td>
                <td>
                  {item.status == 3 ? (
                    <>
                      <button className="btn btn-danger">
                        Em atualização
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary"
                        style={{ backgroundColor: '#1b2949' }}
                        onClick={() => {
                          handleCarregamento(true);
                          handleOperadora(item.id, { ids: item.tabelas.map((item2) => { return item2.id }) });
                        }}
                      >
                        Abrir {item.tabelas.length} calculos
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
