import React from "react";
import { Col, Modal } from "reactstrap";
export const Visualizar = ({ calculo, modalVisualizarOperadora, setModalVisualizarOperadora, calculoDetalhe, handleOperadora, handleVisualizarCotacoes, setModalFiltro, handleCarregamento, ramo,handleClickOperadoraSelect,handleClickOperadoraDeselect ,loadingDetalhes}) => {

  return (


    <div className="">



      <table className="table"  >
        <thead>
          <tr>
            <th>
              <p style={{ fontWeight: 100 }}>Cálculo Nº {calculoDetalhe?.id}</p>
              <p style={{ fontWeight: 100 }}>Total de vidas: {calculoDetalhe?.vidas}</p>
              <p style={{ fontWeight: 100 }}>
                {`Nome do Cliente: ${calculoDetalhe?.descricao ? calculoDetalhe.descricao : ''}`}
              </p>
            </th>
            <th>

            </th>
            <th style={{ textAlign: "right" }}>
              <p style={{ fontWeight: 100 }}>
                {calculoDetalhe?.valorMinimo?.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                })}
              </p>
              <p style={{ fontWeight: 100 }}>
                {calculoDetalhe?.valorDoMeio?.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                })}
              </p>
              <p style={{ fontWeight: 100 }}>
                {calculoDetalhe?.valorMaximo?.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                })}
              </p>
            </th>
            <th>
              <p style={{ fontWeight: 100, fontSize: 10 }}>Menor Preço ({calculoDetalhe?.operadoraValorMinimo})</p>
              <p style={{ fontWeight: 100, fontSize: 10 }}>
                Preço Intermediário (
                {calculoDetalhe?.operadoraValoIntermediario})
              </p>
              <p style={{ fontWeight: 100, fontSize: 10 }}>Maior Preço ({calculoDetalhe?.operadoraValorMaximo})</p>
            </th>



          </tr>
          <tr>
                  <th></th>
                
            <th>
              <div style={{ width: '100%', marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>

               

              </div>
            </th>

            <th>
              <div style={{ width: '100%', marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>

              <button
                  className="btn btn-primary"
                  onClick={() => {
                    setModalFiltro(true);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Filtrar Cotações
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleVisualizarCotacoes}

                  disabled={loadingDetalhes}

                >
                  {loadingDetalhes?"Carregando...":"Imprimir Cotações"}
                  
                </button>

              </div>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>

          {calculo.length > 0 &&
            calculo?.map((item) => (
              <tr key={item.id} style={{ textAlign: "center", backgroundColor: "#AAD2F5", }}>
                <td style={{ paddingTop: 60, paddingLeft: 60, justifyContent: 'center', alignItems: 'center', height: "120px", borderTop: '2px solid #fff' }} >
                  {item.status == 3 ? (
                    <>
                      <button className="btn btn-danger">
                        Em atualização
                      </button>
                    </>
                  ) : (
                    <>
                      <input type="checkbox" className="form-control"   onChange={(e)=>{
                     
                        if(e.target.checked){
                          handleClickOperadoraSelect(item)
                        }else{
                          handleClickOperadoraDeselect(item)
                        }
                      }
                      }
                         />
                    </>
                  )}
                </td>
                <td style={{ paddingTop: 40, width: 200, justifyContent: 'center', alignItems: 'center', height: "120px", borderTop: '2px solid #fff' }} >
                  {item.status == 3 ? (
                    <>
                      <button className="btn btn-danger">
                        Em atualização
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary"

                        onClick={() => {

                          handleOperadora(item.id, { ids: item.tabelas.map((item2) => { return item2.id }) });
                        }}
                      >
                        Ver {item.tabelas.length} cálculos
                      </button>
                    </>
                  )}
                </td>
                <td style={{ width: 500, height: '120px', borderTop: '2px solid #fff' }}>
                  {" "}
                  <img
                    src={`https://server.corpar.com.br/imagens/${item.imagem}`}
                    alt=""
                    width={'23%'}

                  />
                  <br />
                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    {item.nome}
                  </p>
                </td>
                <td style={{ paddingTop: 40, width: 250, height: '120px', borderTop: '2px solid #fff' }}>
                  <a
                    className="btn btn-link"
                    style={{ fontWeight: 100, color: '#1b2949' }}
                    href={`/operadoras/${item.id}`}
                  >
                    Ver Material de Vendas
                  </a>
                </td>

              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
