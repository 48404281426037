import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import Icone from "../../../assets/icones/icone.png"
export const VisualizarDetalhe = ({ faixas, info, modalVisualizarDetalhe, setModalVisualizarDetalhe, infoDependente, calculoDetalhe, handleClick, selecteds, handleMouseOut, handleMouseOver, handleCarregamento, handleVisualizarCotacoes, loadingDetalhes }) => {

  const handleOrdenarMenor = () => {
    let selecionado = document.querySelector('button[id="1"]')
    let deselecionado = document.querySelector('button[id="2"]')
    if (selecionado && deselecionado) {
      selecionado.className = 'btn btn-default'
      selecionado.style.border = '1px solid #1b2949'
      deselecionado.className = 'btn btn-default'
      deselecionado.style.border = '1px solid #fff'
    }
    if (document.querySelector('tbody[id="tabela"]')) {
      const tabela = document.querySelector('tbody[id="tabela"]');
      const linhas = tabela.querySelectorAll('.tr');
      const linhasOrdenadas = {};
      for (let i = 0; i < linhas.length; i++) {
        if (document.querySelectorAll('.valorTotal')[i]) {
          let dados = document.querySelectorAll('.valorTotal')[i].textContent

          dados = dados.replace('R$', '')
          dados = dados.replace('&nbsp;', '')
          dados = dados.replace('.', '')
          dados = dados.replace(',', '.')
          const valor = parseInt(dados);

          linhasOrdenadas[i] = valor;
        }
      }

      const linhasOrdenadasArray = Object.entries(linhasOrdenadas).sort((a, b) => a[1] - b[1]);

      for (let i = 0; i < linhasOrdenadasArray.length; i++) {
        const indice = linhasOrdenadasArray[i][0];
        tabela.appendChild(linhas[indice]);
      }
    }
    handleCarregamento(false)
  }
  const handleOrdenarMaior = () => {
    let selecionado = document.querySelector('button[id="2"]')
    let deselecionado = document.querySelector('button[id="1"]')
    if (selecionado && deselecionado) {
      selecionado.className = 'btn btn-default'
      selecionado.style.border = '1px solid #1b2949'
      deselecionado.className = 'btn btn-default'
      deselecionado.style.border = '1px solid #fff'
    }
    if (document.querySelector('tbody[id="tabela"]')) {
      const tabela = document.querySelector('tbody[id="tabela"]');
      const linhas = tabela.querySelectorAll('.tr');
      const linhasOrdenadas = {};
      for (let i = 0; i < linhas.length; i++) {
        if (document.querySelectorAll('.valorTotal')[i]) {
          let dados = document.querySelectorAll('.valorTotal')[i].textContent
          dados = dados.replace('R$', '')
          dados = dados.replace('&nbsp;', '')
          dados = dados.replace('.', '')
          dados = dados.replace(',', '.')
          const valor = parseInt(dados);
          linhasOrdenadas[i] = valor;
        }
      }

      const linhasOrdenadasArray = Object.entries(linhasOrdenadas).sort((a, b) => b[1] - a[1]);

      for (let i = 0; i < linhasOrdenadasArray.length; i++) {
        const indice = linhasOrdenadasArray[i][0];
        tabela.appendChild(linhas[indice]);
      }
    }
    handleCarregamento(false)
  }
  useEffect(() => {

  }, [])
  const [municipios, setMunicipios] = useState([])
  const [modalMunicipios, setModalMunicipios] = useState(false)
  const handleMunicipios = (municipio) => {
    setMunicipios(municipio)
    setModalMunicipios(true)
  }
  return (
    <Modal
      id="modal-center3"
      size="xl"
      isOpen={modalVisualizarDetalhe}
      centered={true}
      style={{ maxWidth: 1200 }}
    >

      <div className="modal-body">
        <Row >
          <div className="col-12" >
            <button
              type="button"
              onClick={() => {
                setModalVisualizarDetalhe(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ float: 'right', marginRight: 10 }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

        </Row>

        {loadingDetalhes ?
          <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src={Icone} className="rotating-image" />
          </div>

          :
          <>

            <Row>
              <img src={`https://server.corpar.com.br/imagens/${info?.imagem}`} width='15%' style={{ margin: 'auto' }} />

              <div style={{ float: 'right', marginTop: 30, marginRight: 10 }}>
                <button id='1' className="btn btn-default" onClick={() => {
                  handleCarregamento(true)
                  handleOrdenarMenor()
                }} style={{ marginRight: 10 }} >Menor Preço</button>
                <button id='2' className="btn btn-default" onClick={() => {
                  handleCarregamento(true)
                  handleOrdenarMaior()
                }} style={{ marginRight: 10 }} >Maior Preço</button>
              
              </div>
            </Row>
            <Col lg={12}>

              <div style={{ backgroundColor: '#4549A2', fontSize: 20, textAlign: 'center', color: '#fff', fontWeight: 'bold' }} ></div>
            </Col>


            <table className="table" >
              <thead>
                <tr>
                  <table className="table" style={{ cursor: 'pointer', marginBottom: 0 }}>
                    <tbody >
                      <tr style={{ padding: 10, backgroundColor: '#ccc' }}>

                        {info && info?.produto[0]?.faixas?.map((item, index) => (
                          <td key={`${item.id}-${index}`} style={{ textAlign: 'center', borderWidth: 1 }} >
                            <p style={{ fontWeight: 'bold', fontSize: 12, height: 6 }} >

                              {item.faixa?.nome?item.faixa.nome?.replace('-', ' à ')+":":''}  {parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor.replace(',', '.') : (infoDependente.length > 0 && infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1)} vida(s)
                            </p>

                          </td>

                        ))}

                        <td style={{ textAlign: 'center', borderWidth: 1, verticalAlign: 'middle' }} >
                          <p style={{ fontWeight: 'bold', fontSize: 12, height: 8 }} >Total : {calculoDetalhe?.vidas>0?calculoDetalhe.vidas:1} vida(s)</p>

                        </td>
                      </tr>
                    </tbody>
                  </table>

                </tr>
              </thead>
              <tbody id='tabela' >


                {info && info.produto?.map((item, index) => (
                  <>
                    {(item.faixas?.length > 0 || item.precovida) &&
                      <tr className="tr" style={{ textAlign: 'center' }}   >
                        <td style={{ textAlign: 'center', padding: 5 }} >
                          <p style={{ fontWeight: 'bold', fontSize: 12, height: 10 }} >{item.nome} {item.cobertura ? ` - ${item.cobertura}` : ''} </p>
                          <p style={{ fontSize: 12, height: 10 }} > {item.vidas} {item.acomodacao ? `- ${item.acomodacao} - ` : ''} {(item.coparticipacao == 'Sim' || item.coparticipacao == "Total" || item.coparticipacao == "Parcial") ? `Com coparticipacão ${item.coparticipacao == "Total" || item.coparticipacao == "Parcial" ? `(${item.coparticipacao})` : ""} ${item.percentual ? 'de  ' + item.percentual + '%' : ''}` : 'Sem coparticipacão'} </p>

                          <table className="table" style={{ cursor: 'pointer', marginBottom: 0 }}>
                            <tbody>

                              {item.status == 2 ?
                                <tr style={{ textAlign: 'center', borderWidth: 1 }}>
                                  Em atualização
                                </tr>
                                :
                                <>
                                  {(item.faixas?.length > 0 || item.precovida) &&
                                    <tr id={`${item?.faixas.length > 0 ? item?.faixas.map((item3) => { return item3.id }).join('&') : `${item.id}-${item.precovida}`}`} onClick={() => { handleClick(item.id, item?.faixas.length > 0 ? item?.faixas.map((item3) => { return item3.id }).join('&') : `${item.id}-${item.precovida}`, item, { imagem: info?.imagem, operadora: info?.operadora }) }} style={selecteds.some((item3) => item3.tabela === item.id && item3.calculo === item?.faixas.map((item3) => { return item3.id }).join('&')) ? { marginTop: 10, backgroundColor: '#fff', borderWidth: 1, borderColor: '#0f4cba' } : { marginTop: 10, textAlign: 'center', borderWidth: 1, borderRadius: 0, display: 'flex' }} onMouseOut={() => { handleMouseOut(item.id, item?.faixas.length > 0 ? item?.faixas.map((item3) => { return item3.id }).join('&') : `${item.id}-${item.precovida}`) }} onMouseMove={() => { handleMouseOver(item.id, item?.faixas.length > 0 ? item?.faixas.map((item3) => { return item3.id }).join('&') : `${item.id}-${item.precovida}`) }} >

                                      {item.faixas?.length ?
                                        <>
                                          {item?.faixas?.map((item2) => (
                                            <>
                                              {item2.status ?
                                                <td style={{ textAlign: 'center', borderWidth: 1, color: '#000', padding: 5, flex: 1 }} >
                                                  <p style={{ fontWeight: 'bold', fontSize: 16, height: 8, color: '#000' }} >
                                                    {(
                                                      ((parseFloat(item2.valor ? item2.valor?.replace(',', '.') : 0) * (item2.statuscoeficiente && item2.coeficiente ? parseFloat(item2.coeficiente / 100) : 1)) + parseFloat(item2.odonto ? item2.odonto?.replace(',', '.') : 0))

                                                      * parseFloat(item2?.cotacoesfaixa?.valor ? item2?.cotacoesfaixa?.valor?.replace(',', '.') : (infoDependente.length > 0 && infoDependente?.map((elemento) => (elemento <= item2?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                  </p>
                                                  <span style={{ fontSize: 12 }} >R$ {((parseFloat(item2.valor ? item2.valor?.replace(',', '.') : 0) * (item2.statuscoeficiente && item2.coeficiente ? parseFloat(item2.coeficiente / 100) : 1)) + parseFloat(item2.odonto ? item2.odonto?.replace(',', '.') : '0'))} p/vida</span>

                                                </td>
                                                :
                                                <td style={{ textAlign: 'center', borderWidth: 1, flex: 1 }} >
                                                  <p style={{ fontWeight: 'bold', color: 'red', fontSize: 14, height: 8 }} >Comercialização suspensa</p>

                                                </td>}
                                            </>

                                          ))}
                                          <td style={{ textAlign: 'center', borderWidth: 1, verticalAlign: 'middle', padding: 5, flex: 1 }} >
                                            <p style={{ fontWeight: 'bold', fontSize: 16, height: 8, textAlign: 'center' }} className='valorTotal' >

                                              {(item?.faixas?.reduce((acumulador, item) => {
                                                return item.status && acumulador + (((parseFloat(item.valor ? item.valor?.replace(',', '.') : 0) * (item?.statuscoeficiente && item?.coeficiente ? parseFloat(item?.coeficiente / 100) : 1)) + parseFloat(item.odonto ? item.odonto?.replace(',', '.') : '0'))
                                                  * parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor?.replace(',', '.') : (infoDependente.length > 0 && infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1))
                                              }, 0) * Number(calculoDetalhe.vidas>0?calculoDetalhe.vidas:1))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </p>
                                            <span style={{ fontSize: 10 }} >Valor Total </span>
                                          </td>
                                        </>
                                        :
                                        <>
                                          {item.precovida &&
                                            <>
                                              <td style={{ textAlign: 'center', borderWidth: 1, padding: 5, flex: 1 }} >
                                                <p style={{ fontSize: 12 }} >R$ {item.precovida} p/vida</p>
                                              </td>
                                              <td style={{ textAlign: 'center', borderWidth: 1, padding: 5, flex: 1 }} >
                                                <p style={{ fontWeight: 'bold', fontSize: 14 }} className='valorTotal' >{(parseFloat(item.precovida.replace(',', '.')) * parseFloat(calculoDetalhe.vidas))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                              </td>
                                            </>

                                          }

                                        </>
                                      }


                                    </tr>
                                  }
                                </>

                              }

                            </tbody>
                          </table>
                        </td>
                      </tr>
                    }
                  </>
                ))}



              </tbody>
            </table>


            {info?.observacoes?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '210px' }}>Observações Importantes</p>
                <p style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>"Esta simulação poderá variar de acordo com o perfil do cliente e a critério da operadora.<br /> Preços, condições e critérios de aceitação da proposta estão sujeitos a analise e confirmação no momento da implantação do contrato."</p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }} >
                    {info && info?.observacoes?.map((item, index) => (
                      <>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                        <p style={{ fontSize: 16 }}>
                          <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                        </p>
                      </>
                    ))}
                  </div>
                </Col>
              </div>}

            {info?.rede?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '180px' }}>Rede de Atendimento</p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                    {info && info?.rede?.map((item) => (
                      <>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                        <p style={{ fontSize: 16, marginBottom: 20 }}>
                          <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                        </p>
                      </>
                    ))}
                  </div>
                </Col>
              </div>}
            {info?.coparticipacoes?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '210px' }}>Coparticipação</p>

                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }} >
                    {info && info?.coparticipacoes?.map((item, index) => (
                      <>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                        <p style={{ fontSize: 16, marginBottom: 20 }}>
                          <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                        </p>
                      </>
                    ))}
                  </div>
                </Col>
              </div>}

            {info?.reembolso?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '250px' }}>Diferenciais e Reembolsos</p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                    {info && info?.reembolso?.map((item) => (
                      <>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                        <p style={{ fontSize: 16, marginBottom: 20 }}>
                          <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                        </p>
                      </>
                    ))}
                  </div>
                </Col>
              </div>}
            {info?.carencia?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '350px' }}>Carências</p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: "none", borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                    {info && info?.carencia?.map((item) => (
                      <>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                        <p style={{ fontSize: 16, marginBottom: 20 }}>
                          <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                        </p>
                      </>

                    ))}

                  </div>
                </Col>
              </div>
            }



            {info?.dependente?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '140px' }}>Dependentes</p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}   >
                    {info && info?.dependente?.map((item) => (
                      <>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                        <p style={{ fontSize: 16, marginBottom: 20 }}>

                          <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                        </p>
                      </>

                    ))}

                  </div>
                </Col>
              </div>
            }


            {info?.documento?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '230px' }}>Documentos Necessários</p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                    {info && info?.documento?.map((item) => (
                      <>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                        <p style={{ fontSize: 16, marginBottom: 20 }}>
                          <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                        </p>
                      </>


                    ))}

                  </div>
                </Col>
              </div>
            }

            {info?.pagamento?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '230px' }}>Formas de Pagamentos</p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                    {info && info?.pagamento?.map((item) => (
                      <>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                        <p style={{ fontSize: 16, marginBottom: 20 }}>
                          <div dangerouslySetInnerHTML={{ __html: item.item.obs }}  ></div>
                        </p>
                      </>

                    ))}

                  </div>
                </Col>
              </div>
            }



            {info?.entidades?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '200px' }}>Entidades e Profissões  </p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                    {info && info?.entidades?.map((item) => (
                      <div key={item} style={{ marginBottom: 20 }}>
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>

                        <span>{item.item.gruposentidades.map((item4) => (

                          <span key={item4}><span style={{ color: '#000', fontWeight: 'bold', fontSize: 16 }}>{item4?.entidade?.nome}:</span>  {item4.entidadesprofisso?.profisso?.nome} - </span>

                        ))}</span>

                      </div>

                    ))}

                  </div>
                </Col>
              </div>
            }






{info?.areacomercializacao?.length > 0 &&
              <div style={{ border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8, marginTop: 25 }}>
                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', marginTop: -16, backgroundColor: '#fff', width: '350px' }}>Áreas de Comercialização e Atendimento  </p>
                <Col lg={12}>
                  <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >

                    {info && info?.areacomercializacao?.map((item) => (
                      <div key={item} style={{ marginBottom: 20 }} >
                        <p style={{ fontWeight: 'bold' }} >
                          {item?.produto.join(' / ')}
                        </p>
                      

                        {item?.municipios.filter((item, index, self) => index === self?.findIndex((m) => m.municipio.idestado === item.municipio.idestado)).map((muni) => (
                          <>
                         
                            {!info?.areacomercializacao[0].descricoes?.some((element) =>( Number(element?.idestado) == muni.municipio.idestado && element.descricao)) ?

                              <>
                                {item?.municipios.map((item3, index) => (
                                  <>
                                    <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3?.municipio.nome)} -` }}  ></span>
                                  </>
                                ))}
                              </>
                              :
                              <>
                                <span key={`Comercializacao-`}  >{info?.areacomercializacao[0].descricoes.find((element) => Number(element.idestado) == muni.municipio.idestado)?.descricao}</span><br/>
                                <a style={{ textDecoration: 'underline', textAlign: 'justify' }} onClick={() => { handleMunicipios(item?.municipios) }} > Ver municípios</a>
                              </>
                            }
                          </>
                        ))

                        }




                        <br />
                        <p  >{item.descricao}</p>

                      </div>

                    ))}

                  </div>
                </Col>
              </div>
            }





          </>

        }



      </div>
      <Modal isOpen={modalMunicipios} centered={true}>
        <div className="modal-header" style={{ border: 'none' }}>

          <button
            type="button"
            onClick={() => {
              setModalMunicipios(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
          <p style={{ color: '#000', textAlign: 'center' }}>Municípios</p>

          <Row style={{ justifyContent: 'center', alignItems: 'center' }} >
            {municipios.map((item3, index) => (
              <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.municipio.nome)} -` }}  ></span>
            ))}
          </Row>
        </div>

      </Modal>
    </Modal>
  )
}