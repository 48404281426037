import axios from 'axios'
import { baseUrlApi } from './baseUrl'
const getAuthUser = localStorage.getItem('authUser')
const obj = getAuthUser == 'undefined' || getAuthUser == null ? '' : JSON.parse(getAuthUser ? getAuthUser : '')
const axiosInstance = axios.create({
    baseURL: `${baseUrlApi}/auth`,
    headers: {
        'Authorization': `Bearer ${obj.token}`,
        'Content-Type': 'application/json'
    }
})

export const Api = {
    Acessar: async () => {
        let response = await axiosInstance.post('/')
        if (obj.id &&  response?.data?.error) {
            localStorage.removeItem('authUser')
            return true
        }else{
            return false
        }
    },
}