import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Corpo/Layouts";

export default function ServiceOne() {
  const { id} = useParams();
  const [operadora,setOperadora] = useState('Operadora')
  return (
    <Layouts
      style={{ fontFamily: "Open Sans, sans-serif" ,textTransform:'none'}}
      pageTitle={operadora}
      
      breadcrumbs={[
        { name: "Operadoras", path: "/operadoras" },
        { name: `Operadora`, path: `/operadoras/${id}` },
      ]}
    >
      <Dados id={id} setOperadora={setOperadora}  />
    </Layouts>
  );
}
