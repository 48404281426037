import axios from "axios";
import { baseUrlApi } from './baseUrl'
const getAuthUser = localStorage.getItem("authUser");
const obj =
  getAuthUser == "undefined" ||
  getAuthUser == null ||
  getAuthUser == "[object Object]"
    ? ""
    : JSON.parse(getAuthUser ? getAuthUser : "");
const axiosInstance = axios.create({
  baseURL: `${baseUrlApi}/tabelaPrecos`,
  headers: {
   "Authorization": `Bearer ${obj.token}`,
    "Content-Type": "application/json"
  }
});
export const Api = {
  entidades: async () => {
    let response = await axiosInstance.post("/entidades");
    return response.data.option;
  },
  estados: async (dados) => {
    let response = await axiosInstance.post("/estados",dados);
    return response.data.option;
  },
  operadoras: async (dados) => {
    let response = await axiosInstance.post("/operadoras",dados);
    return response.data.option;
  },
  tiposOperadoras: async (dados) => {
    let response = await axiosInstance.post("/tiposoperadoras", dados);
    return response.data.option;
  },
  numeroVidas: async (dados) => {
    let response = await axiosInstance.post("/numeroVidas", dados);
    return response.data.option;
  },
  areas: async (dados) => {
    let response = await axiosInstance.post("/areas", dados);
    return response.data.option;
  },
  produtos: async (dados) => {
    let response = await axiosInstance.post("/produtos", dados);
    return response.data.option;
  },
  visualizar: async (dados) => {
    let response = await axiosInstance.post("/visualizar", dados);
    return response.data.dados;
  },
  cadastro: async (dados) => {
    let response = await axiosInstance.post("/cadastro", dados);
    return response.data;
  },
  listagem: async () => {
    let response = await axiosInstance.post("/listagem");
    return response.data.list;
  },
  delete: async (dados) => {
    let response = await axiosInstance.delete("/delete/" + dados.id);
    return response.data.list;
  },
  editar: async (dados) => {
    let response = await axiosInstance.put("/editar", dados);
    return response.data;
  },
  filtar: async (dados) => {
    let response = await axiosInstance.post("/buscar", dados);
    return response.data;
  },
  dados: async (dados) => {
    let response = await axiosInstance.post("/dados", dados);
    return response.data.dados;
  },
  visualizarBuscar: async (dados) => {
    let response = await axiosInstance.post("/visualizarBuscar", dados);
    return response.data;
  },
  compartilhar: async (dados) => {
    let response = await axiosInstance.post("/compartilhar", dados);
    return response.data;
  }
};
