import React from "react";
import Dados from "./index";
import Layouts from "../Corpo/Layouts";

export default function ServiceThree() {
  return (
    <Layouts pageTitle="Ferramentas de Vendas"   
     breadcrumbs={[
      { name: "Home", path: "/" },
      { name: "Ferramentas de Vendas", path: "/venda" },
    ]}>
      <Dados />
    </Layouts>
  );
}
