import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";

export const Calculadora = ({ modalCalculadora, setModalCalculadora }) => {
    const [birthdate, setBirthdate] = useState('');
    const [age, setAge] = useState(null);

    const calculateAge = (birthdate) => {
        const birthDate = new Date(birthdate);
        const today = new Date();
        if(birthDate.getFullYear()>1910){
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
    
            // Ajuste a idade se o aniversário não foi comemorado ainda no ano corrente
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
    
            setAge(age);
        }else{
            setAge("");
        }
      
       
    };
    useEffect(()=>{
        setAge(null)
        setBirthdate('')
    },[modalCalculadora])
    return (
        <Modal
            isOpen={modalCalculadora}
            centered={true}
        >
            <div className="modal-header"  style={{border:'none',}}>
              
                <button
                    type="button"
                    onClick={() => {
                        setModalCalculadora(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <p className="modal-title mt-0" style={{textAlign:'center'}}>Calculadora de Idade</p>
            <div className="modal-body" style={{ margin: 30 }} >

                <label htmlFor="birthdate">Data de Nascimento:</label>
                <input
                    type="date"
                    id="birthdate"
                    className="form-control"
                    value={birthdate}
                    onChange={(e) => {
                        setBirthdate(e.target.value)
                        calculateAge(e.target.value)
                    }}
                    required
                />
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:20,height:10}} >
                    
                    {(age && age !== null) && <p style={{color:'red'}} >Idade: {age} anos.</p>}
                </div>


               

            </div>
         
        </Modal>
    )
}