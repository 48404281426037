import React, { useEffect, useState } from "react";
import { ImBin } from "react-icons/im";
import { CiSettings } from "react-icons/ci";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { LiaEyeSolid } from "react-icons/lia";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const ListagemTabPre = ({ buscar, filtrar, handleDadosBuscar, list, permissoes, setBuscar, setModalExcluir, setid }) => {
  const [listagem, setListagem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const maxPageNumbers = 10;

  useEffect(() => {
    setListagem(list);
  }, [list]);

  const handlePaginacao = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    let lista = list.sort((a, b) => b.id - a.id )
    const paginatedItems = lista.slice(startIndex, startIndex + itemsPerPage);
    return paginatedItems;
  };

  const totalPages = Math.ceil(list.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pages = [];
    const halfMaxPages = Math.floor(maxPageNumbers / 2);
    let startPage = Math.max(1, currentPage - halfMaxPages);
    let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    if (totalPages > maxPageNumbers) {
      if (startPage === 1) {
        endPage = maxPageNumbers;
      } else if (endPage === totalPages) {
        startPage = totalPages - maxPageNumbers + 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return { pages, startPage, endPage };
  };

  const paginatedList = handlePaginacao();
  const { pages, startPage, endPage } = getPageNumbers();
  return (
    <div>
      <input
        placeholder="buscar..."
        className="form-control"
        name="buscar"
        value={buscar}
        onChange={(e) => {
          filtrar(e.target.value);
          setBuscar(e.target.value);
        }}
      />

      <div
        className="table-responsive"
        style={{ marginTop: 20, minHeight: 250 }}
      >
        <table className="table">
          <thead>
            <tr>
              <th>Tabela Personalizada</th>

              <th>Link</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paginatedList &&
              paginatedList.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div style={{display:'flex',alignItems:'center',width:'100%'}}>
                      <div style={{width:450}}>
                        Ramo: {item.tipooperadora?.nome} <br />
                        Estado: {item.estado?.nome} <br />
                        Operadora: {item.operadora?.nome} <br />
                      </div>
                      <div style={{width:300}}>
                        {item.numerovidas?<>Numero de Vidas: {item.numerovidas} <br /></>:``} 
                        {item.coparticipacao?<>Coparticipação: {item.coparticipacao} <br /></>:``} 
                        {item.cobertura?<>Cobertura: {item.cobertura} <br /></>:``} 
                        {item.contratacao?<>Contratação: {item.contratacao} <br /></>:``} 
                      </div>
                    </div>
                  </td>

                  <td>
                    <a href={`/tabelaprecos/${item.id}`} rel="noreferrer" target="_blank">
                      <LiaEyeSolid />
                    </a>
                  </td>
                  <td>
                    <button
                      style={{ color: "red", backgroundColor: '#fff' }}
                      onClick={() => {
                        setid(item.id);
                        setModalExcluir(true)
                      }}
                      data-toggle="modal"
                      data-target="#modal-center2"
                      to="#"
                    >
                      <ImBin />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 500 }}>

            <button style={{ paddingLeft: 8, paddingRight: 8, backgroundColor: '#2278f3', color: '#fff', borderRadius: 4 }} onClick={handlePrevPage} disabled={currentPage === 1}>
              Anterior
            </button>
            {startPage > 1 && (
              <>
                <button style={{ backgroundColor: '#fff' }} onClick={() => handlePageClick(1)}>1</button>
                {startPage > 2 && <span>...</span>}
              </>
            )}
            {pages.map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                disabled={currentPage === page}
                style={{ backgroundColor: '#fff' }}
              >
                {page}
              </button>
            ))}
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && <span>...</span>}
                <button style={{ backgroundColor: '#fff' }} onClick={() => handlePageClick(totalPages)}>{totalPages}</button>
              </>
            )}
            <button style={{ paddingLeft: 8, paddingRight: 8, backgroundColor: '#2278f3', color: '#fff', borderRadius: 4 }} onClick={handleNextPage} disabled={currentPage === totalPages}>
              Próximo
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};
