import React from "react";
import { useNavigate } from "react-router-dom";
import errorImg from "../../assets/images/404.png";

export default function FourZeroFour() {
  const navigate = useNavigate();
  return (
    <section className="error-page text-center py-120 rpy-100">
      <div className="container">
        <div className="error-content">
          <img src={errorImg} alt="404 Error" />
          <h2>Oops! pagina não encontrada.</h2>
          <p>
          Desculpe, não conseguimos encontrar a página que você está procurando. Isso pode ter ocorrido por um dos seguintes motivos: ou o link que você acessou está incorreto, e a página que você está tentando acessar não existe, ou sua sessão pode ter expirado. Para acessar esta página, é necessário estar logado com uma sessão ativa. Por favor, verifique se o URL está correto ou faça login novamente para acessar a página desejada. Você também pode voltar para a Página Inicial e navegar a partir de lá. Se o problema persistir, entre em contato com o suporte para obter assistência. Agradecemos pela sua compreensão!
          </p>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="theme-btn mt-30"
          >
            Voltar para inicio
          </a>
        </div>
      </div>
    </section>
  );
}
