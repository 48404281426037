import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../../api";
import { Login } from "../../Corpo/Header/Login";
export default function TestimonialSection({ id }) {
 
  const [escolha, setEscolha] = useState(0);
  const { plano } = useParams();
  const [ramos, setRamos] = useState([]);
  const [modalLogin, setModalLogin] = useState(false);
  const getAuthUser = localStorage.getItem("authUser");
  const obj =
    getAuthUser == "undefined" ||
      getAuthUser == null ||
      getAuthUser == "[object Object]"
      ? ""
      : JSON.parse(getAuthUser ? getAuthUser : "");
  useEffect(() => {
    const obj =
      getAuthUser == "undefined" ||
        getAuthUser == null ||
        getAuthUser == "[object Object]"
        ? ""
        : JSON.parse(getAuthUser ? getAuthUser : "");

  }, [obj])
  const handleDetalhes = async () => {

    let dados = await Api.ramosAtuacao({
      id: id,
    });
    setRamos(dados.list);
    
    
  };
  useEffect(() => {
    handleDetalhes();
  }, []);

  return (
    <section className={`testimonial-section-three `} style={{ marginTop: 30 }}>
      <div className="container">
        <div className="testimonial-three-wrap ">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div
                className="testimonial-left-image wow fadeInRight delay-0-2s"
                style={{ textAlign: "center" }}
              ></div>
            </div>
            <div className="col-md-12">
              <div className="testimonial-three-content text-center wow fadeInLeft delay-0-2s">



                <div className="row">
                  {ramos?.length > 0 && ramos.map((item,index)=>(
                       <a
                       href={`/operadora/${item.id}`}
                       key={index}
                       className="col-12 feature-item   style-three"
                       style={{ marginBottom: 15, fontFamily: "Open Sans, sans-serif", padding: 5, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7',display:'flex', color:'#454545' }}
                       onClick={() => {
                         setEscolha(escolha == 1 ? 0 : 1);
                       }} >
                     
                       <span className="menu-expand" >
                        {item.nome}
                       </span>
 
                      
                     </a>
                  ) )}

             
                 
                   
      


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
    </section>
  );
}
