import { useEffect, useRef, useState } from "react";
import { Modal, Row } from "reactstrap";
import { Api } from "../../../../api/apiUsuario";
import React from "react";
import { Col, Input, Label, UncontrolledAlert } from "reactstrap";
import { BiBook, BiBookOpen, BiCollapse, BiEdit, BiShow, BiWrench, BiHide } from "react-icons/bi";
import FileInputComponent from "../../../components/buttonFile";
import logo from "../../../../assets/images/logos/logo2.png";
import { ValidacaoEmail } from "../Validacao/email";
import { ValidacaoCelular } from "../Validacao/celular";
import { Api as ApiLogin } from "../../../../api/apiLogin";
import logoAqui from "../../../../assets/images/sualogoaqui.png";
import { FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import InputMask from 'react-input-mask';
import { baseUrlApi } from "../../../../api/baseUrl";
export const MeusDados = ({ setModalDados, modalDados }) => {
    const [cep, setCep] = useState("");
    const [cpf, setCpf] = useState("");
    const [msg, setMsg] = useState("");
    const [validaCep, setValidaCep] = useState(false)
    const [info, setInfo] = useState([]);
    const [complemento, setComplemento] = useState('')
    const [numero, setNumero] = useState("");
    const [obj, setObj] = useState({})
    const [isValid, setIsValid] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState();
    const [celular, setCelular] = useState('');
    const [passwordNova, setPasswordNova] = useState('');
    const [passwordNovaConfirm, setPasswordNovaConfirm] = useState('');
    const [modalApagar, setModalApagar] = useState();
    const [name, setName] = useState()
    const [apagar, setApagar] = useState(false)
    const [nomeFantasia, setNomeFantasia] = useState();
    const [nome, setNome] = useState();
    const [codigo1, setCodigo1] = useState("");
    const [codigo2, setCodigo2] = useState("");
    const [codigo3, setCodigo3] = useState("");
    const [codigo4, setCodigo4] = useState("");
    const [codigo5, setCodigo5] = useState("");
    const [codigo6, setCodigo6] = useState("");
    const [codigo7, setCodigo7] = useState("");
    const [codigo8, setCodigo8] = useState("");
    const [modalCelular, setModalCelular] = useState(false);
    const [modalEmail, setModalEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validaEmail, setValidaEmail] = useState(false);
    const [validarCelular, setValidaCelular] = useState(false);
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const refImagem = useRef(null);
    const [time, setTime] = useState(30);
    const [start, setStart] = useState(false);
    const tempoPrincipal = localStorage.getItem("time");
    const handleTime = () => {
        let tempo1 = 1;
        let timer = setInterval(() => {
            let tempo = 0;
            if (tempoPrincipal > 0) {
                setTime(tempoPrincipal);
                tempo = tempoPrincipal - tempo1;
                tempo1 = tempo1 + 1;
                setTime(tempo);
                localStorage.setItem("time", JSON.stringify(tempo));
            } else {
                tempo = time - tempo1;
                tempo1 = tempo1 + 1;
                setTime(tempo);
                localStorage.setItem("time", JSON.stringify(tempo));
            }
            if (tempo == 0) {
                setStart(false);
                clearInterval(timer);
            }
        }, 1000);
    };
    useEffect(() => {
        if (start) {
            handleTime();
        }
    }, [start]);
    function handleInputChange(e) {
        const maxLength = e.target.maxLength;
        const currentLength = e.target.value.length;
        if (currentLength >= maxLength) {
            e.preventDefault();
            if (e.target === input1Ref.current) {
                input2Ref.current.focus();
            } else if (e.target === input2Ref.current) {
                input3Ref.current.focus();
            } else if (e.target === input3Ref.current) {
                input4Ref.current.focus();
            } else if (e.target === input4Ref.current) {
                // aqui você pode adicionar o código para passar para o próximo campo de texto
            }
        }
    }
    function handleInputChangeEmail(e) {
        const maxLength = e.target.maxLength;
        const currentLength = e.target.value.length;
        if (currentLength >= maxLength) {
            e.preventDefault();
            if (e.target === input5Ref.current) {
                input6Ref.current.focus();
            } else if (e.target === input6Ref.current) {
                input7Ref.current.focus();
            } else if (e.target === input7Ref.current) {
                input8Ref.current.focus();
            } else if (e.target === input8Ref.current) {
                // aqui você pode adicionar o código para passar para o próximo campo de texto
            }
        }
    }

    const handleValidarCelular = async () => {
        setLoading(true);
        let valida = await ApiLogin.validarCelular({
            id: obj.id,
            codigo: `${codigo1}${codigo2}${codigo3}${codigo4}`,
            celular: celular
        });
        if (valida.error == false) {
            let info = { ...obj }
            info.celular = celular
            setObj(info)
            localStorage.setItem("authUser", JSON.stringify(info));
            setCodigo1("");
            setCodigo2("");
            setCodigo3("");
            setCodigo4("");
            setModalCelular(false);
            setValidaCelular(true);
        } else {
            alert(valida.msg);
        }
        setLoading(false);
    };
    const handleValidarEmail = async () => {
        setLoading(true);

        let valida = await ApiLogin.validarEmail({
            id: obj.id,
            codigo: `${codigo5}${codigo6}${codigo7}${codigo8}`,
            email: email
        });
        if (valida.error == false) {
            let info = { ...obj }
            info.email = email
            setObj(info)
            localStorage.setItem("authUser", JSON.stringify(info));
            setCodigo5("");
            setCodigo6("");
            setCodigo7("");
            setCodigo8("");
            setModalEmail(false);
            setValidaEmail(true);
        } else {
            alert(valida.msg);
        }
        setLoading(false);
    };
    const handleDisparoEmail = async () => {
        setLoading(true);
        if (!start) {
            setTime(59);
            localStorage.setItem("time", JSON.stringify(0));
            let valida = await ApiLogin.disparoEmail({ email: email, id: id });
            alert("Email enviado!");
            setStart(true);
        }

        setLoading(false);
    };
    const handleDisparoCelular = async () => {
        setLoading(true);
        if (!start) {
            setTime(59);
            localStorage.setItem("time", JSON.stringify(0));
            let valida = await ApiLogin.disparoCelular({ celular: celular, id: id });
            alert("Mensagem enviada!");
            setStart(true);
        }
        setLoading(false);
    };
    useEffect(() => {
        const getAuthUser = localStorage.getItem('authUser')
        let objs = getAuthUser ? JSON.parse(getAuthUser ? getAuthUser : '') : ''
        setCpf(objs.cpf)
        setEmail(objs.email)
        setCelular(objs.celular)
        setName(objs.foto)
        setNome(objs.nome)
        setNomeFantasia(objs.nomefantasia)
        setObj(objs)
        setNumero(objs?.dados?.numero)
        setComplemento(objs?.dados?.complemento1)
        setCep(objs?.dados?.cep)
        setInfo(objs.dados)
    }, [])

    useEffect(() => {

        const formatarCelular = () => {
            // Adiciona o prefixo "55" e formata o número do celular

            if (celular?.startsWith("55")) {
                return (
                    "55" +
                    celular
                        .substring(2)
                        .replace(/\D/g, "")
                        .replace(/^(\d{2})(\d)/g, "($1) $2")
                        .replace(/(\d)(\d{4})$/, "$1-$2")
                );
            } else {
                if (celular) {

                    return celular
                        .replace(/\D/g, "")
                        .replace(/^(\d{2})(\d)/g, "($1) $2")
                        .replace(/(\d)(\d{4})$/, "$1-$2");
                } else {
                    return "";
                }
            }
        };
        if (celular) {
            let validado = formatarCelular()

            setCelular(validado);
        }


    }, [email, celular]);
    const deletar = async () => {
        let excluir = await Api.deletar({ id: obj.id })
        localStorage.setItem("authUser", "{}");
        window.location.href = '/'
    }
    const formData = new FormData()
    const validatePassword = () => {
        const regex = /^(?=.*[A-Z]).{8,}$/;
        setIsValid(!regex.test(passwordNova));
    };
    const handleCelularChange = (event) => {
        const inputCelular = event.target.value;
        // Limita o número de caracteres do celular em 15
        if (inputCelular.length <= 15) {
            setCelular(inputCelular);
        }
    };
    const editar = async () => {
        let status = true
        if (password && passwordNova) {
            if (passwordNovaConfirm && (passwordNova == passwordNovaConfirm) && !isValid) {
                status = true
            } else {
                status = true
            }
        } else {
            status = true
        }


        formData.set('imagem', imagem)
        formData.set('dados', JSON.stringify({
            id: obj.id, nome: nome, nomefantasia: nomeFantasia, password: passwordNova, celular: celular.replace(/\D/g, ""), apagar, email, cep: cep, numero: numero, bairro: info?.bairro, complemento1: complemento, ddd: info?.ddd, ibge: info?.ibge, rua: info?.logradouro, cidade: info?.localidade, siafi: info?.siafi, estado: info?.uf,cpf
        }))
        if (status) {
            let editar = await Api.editar(formData)

            if (!editar.error) {
                let info2 = { ...obj }
                info2.nomefantasia = editar.nomefantasia
                if (editar.cpf) {
                    info2.cpf = editar.cpf
                }
                info2.foto = (editar.foto && editar.foto != 'Apagada') ? editar.foto : editar.foto == 'Apagada' ? '' : info2.foto

                if (editar.nome) {
                    info2.nome = editar.nome
                }
                info2.cep = cep
                info2.numero = numero
                info2.bairro = info.bairro
                info2.complemento1 = complemento
                info2.ddd = info?.ddd
                info2.ibge = info?.ibge
                info2.rua = info?.logradouro
                info2.cidade = info?.localidade
                info2.siafi = info?.siafi
                info2.estado = info?.estado

                if (editar.statusEmail) {
                    setValidaEmail(false);
                    setCodigo5("");
                    setCodigo6("");
                    setCodigo7("");
                    setCodigo8("");
                    setEmail(email);
                    setModalEmail(true);
                } else {
                    setValidaEmail(true);
                }
                if (editar.statusCelular) {
                    setCodigo1("");
                    setCodigo2("");
                    setCodigo3("");
                    setCodigo4("");
                    setCelular(editar.celular);
                    setModalCelular(true);
                    setValidaCelular(false);
                } else {
                    setValidaCelular(true);
                }

                setApagar(false)
                setObj(info2)
                localStorage.setItem("authUser", JSON.stringify(info2));
                alert('Informações alteradas')
            } else {
                alert('Informações não alteradas')
            }
        } else {
            alert('As senhas precisam ser iguais!')
        }

    }
    const formatCep = (value) => {
        const parts = [
            value.slice(0, 5),
            value.slice(5, 8),
        ];

        return parts.filter(Boolean).join('-');
    };
    const [imagem, setImagem] = useState();
    const handleCEP = async (cep) => {
        let string = cep.replace(/\D/g, "");
        const formattedCep = formatCep(string);
        setCep(formattedCep);
        if (string && string.length == 8) {
            let dados
            setInfo({})
            await fetch(`https://viacep.com.br/ws/${string}/json`)
                .then((res) => res.json())
                .then((data) => {
                    setMsg()
                    dados = data
                    setInfo(data);
                    setValidaCep(false)
                });

            if (!dados?.cep) {
                setMsg('CEP inválido.')
                setValidaCep(true)
            }
        }

    };
    const [handleHide, setHandleHide] = useState([]);
    const handleShowHide = async (type, status, index) => {
        if (status) {
            document.querySelector(`input[id="password${type}"]`).type = 'text'
        } else {
            document.querySelector(`input[id="password${type}"]`).type = 'password'
        }
        let valores = [...handleHide]
        valores[index] = !valores[index]
        setHandleHide(valores)
    }
    const handleExit = (val) => {
        setApagar(val)
    }
    useEffect(() => {
        if (apagar || imagem) {
            // console.log(imagem, apagar)
            editar()
        }
    }, [apagar, imagem])
    const [tipo, setTipo] = useState([true, false, false])
    const handleTipo = (tipo) => {
        if (tipo == 1) {
            setTipo([true, false, false, false])
        }
        if (tipo == 2) {
            setTipo([false, true, false, false])
        }
        if (tipo == 3) {
            setTipo([false, false, true, false])
        }
        if (tipo == 4) {
            setTipo([false, false, false, true])
        }
    }
    return (
        <>
            <Modal id="modal-center3" size="lg" centered={true} isOpen={modalDados} >

                <div className="modal-header" style={{ border: 'none' }} >
                    <button
                        type="button"
                        onClick={() => {
                            setModalDados(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 15, paddingTop: 8, height: 600, }} >

                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                        <button className={`btn btn${tipo[0] ? '-primary' : ''}`} onClick={() => handleTipo(1)} style={{ width: 150, border: (!tipo[0] ? '1px solid #ccc' : '') }}>Dados Gerais</button>
                        <button className={`btn btn${tipo[1] ? '-primary' : ''}`} onClick={() => handleTipo(2)} style={{ border: (!tipo[1] ? '1px solid #ccc' : ''), width: 150 }}>Endereço</button>
                        <button className={`btn btn${tipo[2] ? '-primary' : ''}`} onClick={() => handleTipo(3)} style={{ width: 150, border: (!tipo[2] ? '1px solid #ccc' : '') }}>Logomarca</button>
                        <button className={`btn btn${tipo[3] ? '-primary' : ''}`} onClick={() => handleTipo(4)} style={{ width: 150, border: (!tipo[3] ? '1px solid #ccc' : '') }}>Alterar senha</button>

                    </div>
                    <div style={{ display: 'flex',flexDirection:'column', justifyContent: 'space-around', alignItems: 'center',height:'100%'}}>
                        <Row style={{ flex:1,flexDirection:'row', justifyContent: 'center', alignItems: 'center'}}>


                            {tipo[1] &&
                                <Row style={{padding:'0px 20px 0px 20px'}} >
                                    <Col lg={3}  className="col-6">
                                        <div>
                                            <div className="mb-1">
                                                <Label
                                                    htmlFor="example-text-input"
                                                    className="form-Label"
                                                >
                                                    *CEP
                                                </Label>
                                                <InputMask mask="99999-999" className="form-control" maskPlaceholder={null} value={cep} onChange={(e) => {
                                                    setCep(e.target.value)
                                                    handleCEP(e.target.value);

                                                }} id="cep" />

                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} className="col-6">
                                        <div>
                                            <div className="mb-1">
                                                <Label htmlFor="example-text-input" className="form-Label" >
                                                    Estado
                                                </Label>
                                                <Input name="estado" autocomplete="new-password" disabled value={info?.uf ? info.uf : info.estado} className="form-control" type="text" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="col-12">
                                        <div>
                                            <div className="mb-1"  >
                                                <Label htmlFor="example-text-input" className="form-Label" >
                                                    Cidade
                                                </Label>
                                                <Input name="cidade" autocomplete="new-password" disabled value={info?.localidade ? info.localidade : info.cidade} className="form-control" type="text" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12} className="col-12">
                                        <div>
                                            <div className="mb-1">
                                                <Label htmlFor="example-text-input" className="form-Label" >
                                                    Rua
                                                </Label>
                                                <Input name="rua" autocomplete="new-password" disabled value={info?.logradouro ? info.logradouro : info.rua} className="form-control" type="text" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="col-12">
                                        <div className="mb-1">

                                            <Label
                                                htmlFor="example-text-input"
                                                className="form-Label"
                                            >
                                                Número
                                            </Label>
                                            <input name="numero" value={numero} onChange={(e) => { setNumero(e.target.value); }} className="form-control" type="number" />

                                        </div>
                                    </Col>
                                    <Col lg={6} className="col-12">
                                        <div>
                                            <Label htmlFor="example-text-input" className="form-Label" >
                                                Bairro
                                            </Label>
                                            <input name="bairro" disabled value={info?.bairro ? info.bairro : info.bairro} className="form-control" type="text" />

                                        </div>
                                    </Col>
                                    <Col lg={12} className="mb-1 col-12">
                                        <div>
                                            <div className="mb-1">
                                                <Label htmlFor="example-text-input" className="form-Label" >
                                                    Complemento
                                                </Label>
                                                <Input name="complemento" autocomplete="new-password" onChange={(e) => { setComplemento(e.target.value); }} value={complemento} className="form-control" type="text" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            }

                            {tipo[2] &&
                                <div className="col-md-12">
                                    <div style={{ display: 'flex' }} >
                                        <div style={{ justifyItems: 'center', alignItems: 'center', margin: 'auto' }} >
                                            {obj.foto ?
                                                <div >
                                                    <img src={`${baseUrlApi}/imagens/${obj.foto}`} alt="" style={{ height: '100%', borderRadius: '20%' }} />
                                                    <FaRegTrashAlt size={20} color="red" style={{ marginTop: '75', cursor: "pointer" }} onClick={() => {

                                                        document.querySelector('#imagem').value = ""
                                                        setImagem('')
                                                        setApagar(true)

                                                    }} />
                                                </div>
                                                :
                                                <div >
                                                    <img src={logoAqui} alt="" width={'150'} style={{ paddingTop: 10, padding: 10, justifyItems: 'center', alignItems: 'center' }} />
                                                    <FaEdit size={20} color="green" style={{ marginTop: '75', cursor: "pointer" }} onClick={() => {
                                                        document.querySelector("#imagem").click()
                                                    }} />
                                                </div>

                                            }


                                        </div>


                                    </div>
                                    <p style={{ textAlign: 'center', marginTop: 20 }} >Dimensões Ideais: 170px por 90px</p>
                                </div>
                            }
                            {tipo[3] &&
                                <>

                                    <div className="col-md-12 mb-5 my-5" >
                                        <Label htmlFor="example-text-input" className="form-Label">
                                            Senha atual
                                        </Label>
                                        <div style={{ display: 'flex' }} >
                                            <div style={{ flex: 8 }} >
                                                <div>

                                                    <Input id="password0" name="password" autocomplete="new-password"
                                                        onChange={(e) => { setPassword(e.target.value) }} value={password} className="form-control" type="password"
                                                    />

                                                </div>
                                            </div>
                                            <div style={{ flex: 1, margin: 'auto', width: '100%', cursor: 'pointer', borderRadius: '5px', border: '1px solid #ccc', padding: 4 }} >
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div   > {handleHide[0] ? <BiShow size={20} onClick={() => { handleShowHide('0', false, 0) }} /> : <BiHide size={20} onClick={() => { handleShowHide('0', true, 0) }} />}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-5 my-5" >
                                        <Label htmlFor="example-text-input" className="form-Label">
                                            <label>Nova senha</label>
                                        </Label>
                                        <div style={{ display: 'flex' }}  >
                                            <div style={{ flex: '4' }}  >
                                                <div >

                                                    <Input id="password1" name="password" autocomplete="new-password"
                                                        value={passwordNova} onBlur={() => {
                                                            validatePassword();
                                                        }} onChange={(e) => { setPasswordNova(e.target.value) }} className="form-control" type="password"
                                                    />

                                                </div>
                                            </div>
                                            <div style={{ flex: '1', margin: 'auto', cursor: 'pointer', borderRadius: '5px', border: '1px solid #CED4D9', padding: 4 }} >
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div   > {handleHide[1] ? <BiShow size={20} onClick={() => { handleShowHide('1', false, 1) }} /> : <BiHide size={20} onClick={() => { handleShowHide('1', true, 1) }} />}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-5 my-5" >
                                        <Label htmlFor="example-text-input" className="form-Label">
                                            <label>Confirmar senha</label>
                                        </Label>
                                        <div style={{ display: 'flex' }}  >
                                            <div style={{ flex: '4' }}  >
                                                <div >

                                                    <Input id="password2" name="password" autocomplete="new-password"
                                                        value={passwordNovaConfirm} onChange={(e) => { setPasswordNovaConfirm(e.target.value) }} className="form-control" type="password"
                                                    />

                                                </div>
                                            </div>
                                            <div style={{ flex: '1', margin: 'auto', cursor: 'pointer', borderRadius: '5px', border: '1px solid #CED4D9', padding: 4 }} >
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div   > {handleHide[2] ? <BiShow size={20} onClick={() => { handleShowHide('2', false, 2) }} /> : <BiHide size={20} onClick={() => { handleShowHide('2', true, 2) }} />}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 " >

                                        <p style={{ color: "red", textAlign: 'center', marginTop: 20 }}>
                                            Sua senha deve conter 8 dígitos com números e letras
                                        </p>
                                    </div>
                                </>

                            }


                            {tipo[0] &&
                                <>
                                    <div className="col-md-6" >
                                        <label>Nome</label>
                                        <input className="form-control" name='nome' value={nome} onChange={(e) => { setNome(e.target.value) }} />
                                    </div>
                                    <div className="col-md-6" >
                                        <label>Nome Fantasia</label>
                                        <input className="form-control" name='nomeFantasia' value={nomeFantasia} onChange={(e) => { setNomeFantasia(e.target.value) }} />
                                    </div>
                                    <div className="col-md-6" >
                                        <label>{cpf?.length==11?'CPF':'CNPJ'}</label>
                                        <input className="form-control" name='cpf' value={cpf} onChange={(e) => { setCpf(e.target.value) }} />
                                    </div>
                                 
                                    <div className="col-md-6" >
                                        <label>Celular</label>
                                        <Input name="celular" maxLength={15} value={celular} onChange={handleCelularChange} className="form-control" type="tel" />
                                    </div>
                                  
                                    <div className="col-md-12" >
                                        <label>Email</label>
                                        <input className="form-control" name='email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                    </div>



                                </>

                            }

                            <Col lg={12} style={{ display: 'none', border: 'none', borderBottom: 'none' }}>
                                <div >
                                    <div className="mb-3">
                                        <Label htmlFor="example-text-input" className="form-Label">Imagem</Label>
                                        <FileInputComponent handleExit={(e) => { handleExit(e) }} handleChange={(event) => { setImagem(event.target.files ? event.target.files[0] : '') }} name={name} setName={setName} />
                                        <input type="file" onChange={(event) => {
                                            if ('File', event.target.files.length > 0) {
                                                setImagem(event.target.files ? event.target.files[0] : '')
                                                editar()
                                            } else {
                                                setImagem('')
                                            }
                                        }} id='imagem' ref={refImagem} />
                                    </div>
                                </div>
                            </Col>
                            {/* <div className="col-md-12 mb-5 my-5" >
                            <label>Logomarca</label>
                            <input type='file' className="form-control" onChange={(event) => {
                                setImagem(event.target.files ? event.target.files[0] : '')
                            }} />
                        </div> */}
                            {/* <div className="col-md-4" style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: 20 }}>
                            <button className="btn btn-danger" onClick={() => { setModalApagar(true);}}>
                                Apagar conta
                            </button>
                        </div> */}

                        </Row>

                        <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginBottom: 60 }} >


                            <button className="btn btn-primary" style={{ float: 'left', width: 200 }} onClick={editar} >Salvar</button>
                        </div>
                    </div>
                </div>

            </Modal>
            <Modal id="modal-center3" size="md" centered={true} isOpen={modalApagar}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Apagar conta</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModalApagar(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
                    <Row>
                        <p style={{ color: 'red', marginLeft: 15 }} >Tem certeza que deseja excluir sua conta!</p>
                    </Row>
                </div>
                <div className="modal-footer" style={{ display: 'block' }} >
                    <button className="btn btn-danger" style={{ float: 'left', marginRight: 20 }} onClick={() => {
                        setModalApagar(false);
                    }} >Fechar</button>
                    <button className="btn btn-success" style={{ float: 'right' }} onClick={deletar} >Excluir</button>
                </div>
            </Modal>
            <ValidacaoEmail email={email} handleDisparoEmail={handleDisparoEmail} handleInputChangeEmail={handleInputChangeEmail} handleValidarEmail={handleValidarEmail} loading={loading} modalEmail={modalEmail} setModalEmail={setModalEmail} setCodigo5={setCodigo5} setCodigo6={setCodigo6} setCodigo7={setCodigo7} setCodigo8={setCodigo8} start={start} time={time} codigo5={codigo5} codigo6={codigo6} codigo7={codigo7} codigo8={codigo8} input5Ref={input5Ref} input6Ref={input6Ref} input7Ref={input7Ref} input8Ref={input8Ref} />

            <ValidacaoCelular codigo1={codigo1} codigo2={codigo2} codigo3={codigo3} codigo4={codigo4} handleDisparoCelular={handleDisparoCelular} handleInputChange={handleInputChange} handleValidarCelular={handleValidarCelular} modalCelular={modalCelular} setCodigo1={setCodigo1} setCodigo2={setCodigo2} setCodigo3={setCodigo3} setCodigo4={setCodigo4} setModalCelular={setModalCelular} input1Ref={input1Ref} input2Ref={input2Ref} input3Ref={input3Ref} input4Ref={input4Ref} celular={celular} start={start} loading={loading} time={time} />
        </>

    )
}