import React from "react";
import { Modal,Row,Col } from "reactstrap";
export const ValidacaoEmail = ({modalEmail,setModalEmail,email,handleInputChangeEmail,codigo5,codigo6,codigo7,codigo8,setCodigo5,setCodigo6,setCodigo7,setCodigo8,handleDisparoEmail,loading,start,time,handleValidarEmail,input5Ref,input6Ref,input7Ref,input8Ref}) =>{
    return(
        <Modal isOpen={modalEmail} centered={true}>
        <div className="modal-header" style={{border:'none'}}>

          <button type="button" onClick={() => {
              setModalEmail(false);
            }} className="close" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
     
        <p style={{ color: '#000',textAlign:'center' }}>Valide seu Email</p>
          <p style={{ fontSize: 15,textAlign:'center' }}>
            Digite o código enviado para seu email <br/>
            {email}
          </p>

       
          <Row style={{justifyContent:'center',alignItems:'center'}}>
            <Col lg={2}>
              <input className="form-control" value={codigo5} ref={input5Ref} onChange={(e) => {
                  handleInputChangeEmail(e);
                  setCodigo5(e.target.value);
                }} style={{ textAlign: "center" }} maxLength={1} />
            </Col>
            <Col lg={2}>
              <input className="form-control" value={codigo6} ref={input6Ref} onChange={(e) => {
                  handleInputChangeEmail(e);
                  setCodigo6(e.target.value);
                }} style={{ textAlign: "center" }} maxLength={1} />
            </Col>
            <Col lg={2}>
              <input className="form-control" value={codigo7} ref={input7Ref} onChange={(e) => {
                  handleInputChangeEmail(e);
                  setCodigo7(e.target.value);
                }} style={{ textAlign: "center" }} maxLength={1}
              />
            </Col>
            <Col lg={2}>
              <input className="form-control" value={codigo8} ref={input8Ref}
                onChange={(e) => {
                  handleInputChangeEmail(e);
                  setCodigo8(e.target.value);
                }} style={{ textAlign: "center" }} maxLength={1}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer" style={{border:'none',paddingRight:25,marginTop: -9}}>
          <button className="btn btn-link" style={{color:'red'}} disabled={loading ? loading : start ? true : false} onClick={() => {
              handleDisparoEmail();
            }}
          >
            {loading ? "Reenviar código" : start ? `00:${time < 10 ? "0" + time : time}`  : `Reenviar código`}
          </button>
          <button className="btn btn-primary" disabled={loading} onClick={() => { handleValidarEmail(); }}  >
          Avançar
          </button>
        </div>
      </Modal>
    )
}