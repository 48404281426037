import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import { baseUrlApi } from "../../../api/baseUrl";
export default function AboutSection({ className }) {
  const [list, setList] = useState();

  const handleDados = async () => {
    let list = await Api.sobre();
  
    if (list?.list?.id) {
      setList(list.list);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  return (
    <section className={`about-three pt-1 pb-10 rpy-50 ${className || ""}`} style={{ backgroundColor: '#f6f7f9' }} >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6" style={{   marginTop:20 }}>
            <div className="about-content pl-70 rpl-0 wow fadeInRight delay-0-2s">
          
              <div className="" style={{textAlign:'center'}}>

                <span className="sub-title"  style={{   marginTop:25, fontWeight: 100, }} >
                  {/* {list?.titulo} */}
                   <a style={{ color: '#000', textAlign: 'center',fontSize:22,  padding: 8, borderRadius: 10}}>    Quem <span style={{color:'#2278F3'}} >Somos</span></a>
                </span>
                {/* <h2 style={{  fontWeight: 100, paddingRight: 6 }}>
                {list?.descricao}
                </h2> */}
              </div>
              <div dangerouslySetInnerHTML={{ __html: list?.texto.replace(/font-family:\s*IBM\s+Plex\s+Sans(?:,\s*sans-serif)?/g, '').replace(/sans-serif;/g, "").replace(/background(-color)?:\s*[^;]+;/g, '')}}
                style={{  margin:20,marginTop:20,textAlign: "justify",lineHeight:list?.espacamentoparagrafos}} >
             

              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="rmb-60 mr-10 rmr-0  ">
              <img src={`${baseUrlApi}/imagens/${list?.imagem}`} style={{ float: 'right' }} />
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
