import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import SliderCom from "../../../components/helpers/SliderCom";

export default function TestimonialSection() {
  const [list, setList] = useState();
  const handleDados = async () => {
    let list = await Api.depoimentos();
    if (list?.list?.length > 0) {
      setList(list.list);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <section
      className="testimonial-section bg-lighter pt-30 rpt-95 pb-120 rpb-100"
      id="testimonial-section"
    >
      <div className="container">
        <div className="section-title text-center mb-65">
          <span
            className="sub-title"
            style={{ fontFamily: "Open Sans, sans-serif" }}
          >
            -Depoimentos
          </span>
        </div>

        <div className="testimonial-wrap">
          <SliderCom settings={settings}>
            {list &&
              list.map((item) => (
                <>
                <div className="testimonial-item wow fadeInLeft delay-0-2s">
                  <div className="author-description">
                    <img
                      src={`https://server.corpar.com.br/imagens/${item?.imagem}`}
                      alt="Author"
                    />
                    <div className="designation">
                      <h5 style={{ fontFamily: "Open Sans, sans-serif" }}>
                        {item?.nome}
                      </h5>
                      <span style={{ fontFamily: "Open Sans, sans-serif" }}>
                        {item?.profissao}
                      </span>
                    </div>
                    <i className="fas fa-quote-right"></i>
                  </div>
                  <p style={{ fontFamily: "Open Sans, sans-serif" }}>
                    {item?.texto}
                  </p>
                </div>
                </>
              ))}
          </SliderCom>
        </div>
      </div>
    </section>
  );
}
