import React from "react";
import { Modal } from "reactstrap";
 
export const Excluir = ({handleExcluir,modalExcluir,setModalExcluir,handleCarregamento}) =>{
    return(
        <Modal
        isOpen={modalExcluir}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Excluir</h5>
          <button
            type="button"
            onClick={() => {
              setModalExcluir(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 ,marginTop:25}}>
          <p style={{fontWeight:'bold',fontSize:15}} >
           Tem certeze que deseja excluir essa informação!
          </p>
       
        </div>
        <div className="modal-footer">
            <button className="btn btn-danger" onClick={()=>{
              handleCarregamento(true)
              handleExcluir()
            }} >Excluir</button>
        </div>
      </Modal>
    )
}